import React, { useState, useEffect } from 'react';
import DOMPurify from "dompurify";

function ImageMCScrambledPreview({ assessmentItem }) {
    const [shuffledResponses, setShuffledResponses] = useState([]);

    useEffect(() => {
        const responses = [
            assessmentItem.correct,
            assessmentItem.distractor1,
            assessmentItem.distractor2,
            assessmentItem.distractor3,
        ];
        setShuffledResponses(responses.sort(() => 0.5 - Math.random()));
    }, [assessmentItem]);

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={'mx-auto'}>
            <div className={'flex flex-col gap-2 bg-columbia_blue-400 p-1 rounded'}>
                <div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>
                    <div dangerouslySetInnerHTML={renderHtml(assessmentItem.prompt)} />
                </div>
                <div className={'grid grid-cols-2 gap-2'}>
                    {shuffledResponses.map((item, index) => (
                        <div key={index} className={'bg-dutch_white-200 rounded p-2 border-2 border-opacity-50 border-dutch_white-700 h-fit w-fit m-auto'}>
                            <img className={'max-w-[100px]'} src={item} alt={`option-${index + 1}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ImageMCScrambledPreview;
