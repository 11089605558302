import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import getWithAuth from "../../context/getWithAuth";
import Planning from "../../teacher/planning/planning";

function DesignFetchLayerCoord() {

    const [standardSets, setStandardSets] = useState(null);
    const [rosters, setRosters] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);

    const user = useContext(UserContext);

    const fetchStandardSets = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/coordinatorplanning/getStandardSets', user.userAttributes['custom:school'])
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/coordinatorplanning/getRosters', user.userAttributes['custom:school'])
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth('/coordinatorplanning/getKnowShow',  user.userAttributes['custom:school'])
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth('/coordinatorplanning/getAssessmentItems',  user.userAttributes['custom:school'])
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        fetchStandardSets();
        fetchRosters();
        fetchKnowShowCharts(user);
        fetchAssessmentItems(user);
    }, [user.userContext])

    return (
        <Planning user={user} standardSets={standardSets} setStandardSets={setStandardSets} rosters={rosters} setRosters={setRosters} knowShowCharts={knowShowCharts} setKnowShowCharts={setKnowShowCharts} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} />
    );
}

export default DesignFetchLayerCoord;