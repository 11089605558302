import React, {useEffect, useRef, useState} from 'react';
import InteractiveSearch from "../../components/interactiveSearch";
import StandardSelectionBar from "./standardSelectionBar";
import CreateItemModal from "./createItemModal";
import ViewAssessmentItemsModal from "./viewAssessmentItemsModal";
import {useNavigate} from "react-router-dom";
import SupplementalStandardModal from "./supplementalStandardModal";
import TeacherNavBar from "../teacherNavBar";
import EditKnowShowChartModal from "./editKnowShowChartModal";
import AddRecallElementsModal from "./addRecallElementsModal";
import DOMPurify from "dompurify";
import AssignRecallModal from "./assignRecallModal";
import getWithAuth from "../../context/getWithAuth";
import Loader from "../../components/loader";
import RecallEditPromptModal from "./recallEditPromptModal";
import ToSModal from "../../components/tosModal";
import CoordinatorNavBar from "../../coordinator/coordinatorNavBar";


function Planning({user, rosters, setRosters, assessmentItems, setAssessmentItems, knowShowCharts, setKnowShowCharts, standardSets, setStandardSets}) {
    // const [standardSets, setStandardSets] = useState(null);
    // const [rosters, setRosters] = useState(null);
    // const [knowShowCharts, setKnowShowCharts] = useState(null);
    // const [assessmentItems, setAssessmentItems] = useState(null);
    const navigate = useNavigate();
    // const user = useContext(UserContext);

    const [selectedStandardSet, setSelectedStandardSet] = useState(null);
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [createItemModal, setCreateItemModal] = useState(null);
    const [viewAssessmentItemsModal, setViewAssessmentItemsModal] = useState(null);
    const [supplementalStandardModal, setSupplementalStandardModal] = useState(null);
    const [editKnowShowChartModal, setEditKnowShowChartModal] = useState(null);
    const [addRecallElementsModal, setAddRecallElementsModal] = useState(null);
    const [recallEditPromptModal, setRecallEditPromptModal] = useState(null);
    const [assignRecallModal, setAssignRecallModal] = useState(null);

    const [isSidenavOpen, setSidenavOpen] = useState(false);

    const toggleSidenav = () => setSidenavOpen(prev => !prev);

    const sideNavRef = useRef(null)

    useEffect(() => {
        if (selectedStandard && !createItemModal) {
            // Ensure modalRef.current is not null before attempting to add the listener
            const modalElement = sideNavRef.current;

            // Focus the first focusable element when the modal opens
            const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement = focusableElements[focusableElements.length - 1];

            firstFocusableElement.focus();

            const trapFocus = (e) => {
                if (e.key === 'Tab') {
                    if (e.shiftKey) {
                        // Shift + Tab: Move focus backward
                        if (document.activeElement === firstFocusableElement) {
                            e.preventDefault();
                            lastFocusableElement.focus();
                        }
                    } else {
                        // Tab: Move focus forward
                        if (document.activeElement === lastFocusableElement) {
                            e.preventDefault();
                            firstFocusableElement.focus();
                        }
                    }
                } else if (e.key === 'Escape') {
                    // Close the modal when Escape is pressed
                    setSelectedStandard(null);
                    setSidenavOpen(false);
                }
            };

            modalElement.addEventListener('keydown', trapFocus);

            return () => {
                if (modalElement) {
                    modalElement.removeEventListener('keydown', trapFocus);
                }
            };
        }

    }, [selectedStandard, setSelectedStandard, editKnowShowChartModal, setEditKnowShowChartModal, createItemModal, addRecallElementsModal, recallEditPromptModal, assignRecallModal, viewAssessmentItemsModal]);

    useEffect(() => {
        if (createItemModal || viewAssessmentItemsModal || editKnowShowChartModal || addRecallElementsModal || recallEditPromptModal || assignRecallModal || selectedStandard) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';}
    }, [createItemModal, viewAssessmentItemsModal, editKnowShowChartModal, addRecallElementsModal, recallEditPromptModal, assignRecallModal, selectedStandard])

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getStandardSets`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStandardSets(r);
                        if (selectedStandardSet) {
                            let newStandardSet = r.find(item => (item.title === selectedStandardSet.title));
                            await setSelectedStandard(null);
                            await setSearchValue(null);
                            await setSelectedStandardSet(newStandardSet);
                        }
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/logistics/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getKnowShow`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const handleChangeStandardSet = async (event) => {
        let newStandardSet = standardSets.find(item => (item.title === event.target.value));
        await setSelectedStandard(null);
        await setSearchValue(null);
        await setSelectedStandardSet(newStandardSet);
    }

    const handleSearchChange = (e) => {
        if (selectedStandardSet) {
            setSearchValue(selectedStandardSet.standards.filter(standard => (standard.standardCode.toLowerCase().includes(e.target.value.toLowerCase()) || standard.title.toLowerCase().includes(e.target.value.toLowerCase()) || standard.text.toLowerCase().includes(e.target.value.toLowerCase()) || standard.supportingText.toLowerCase().includes(e.target.value.toLowerCase()))));
        } else {
            return null;
        }
    }

    const handleStandardClick = (standard) => {
        setSelectedStandard(standard);
        toggleSidenav()
    }

    // useEffect(() => {
    //     fetchStandardSets();
    //     fetchRosters();
    //     fetchKnowShowCharts(user);
    //     fetchAssessmentItems(user);
    // }, [user.userContext])

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        const plainText = new DOMParser().parseFromString(sanitizedHtml, 'text/html').body.textContent;
        return {
            __html: sanitizedHtml,
            plainText: plainText
        };
    };


    if (!user.userContext) {
        return <div className={'h-screen flex-col overflow-hidden'}>
            <Loader message={'Fetching user data'}/>
        </div>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role === 'student') {
        navigate('/home')
    } else if (!standardSets || !knowShowCharts || !assessmentItems) {
        return <div className={'h-screen flex-col overflow-hidden'}>
            {(user.userContext.role === 'teacher') ? <TeacherNavBar /> : (user.userContext.role === 'coordinator') && <CoordinatorNavBar />}
            <Loader message={'Fetching relevant standard sets'}/>
        </div>
    } else {
        return (
            <div className={'min-h-screen flex-col'}>
                {(user.userContext.role === 'teacher') ? <TeacherNavBar /> : (user.userContext.role === 'coordinator') && <CoordinatorNavBar />}
                <div className="relative min-h-screen flex flex-col">
                    {/* Backdrop Overlay */}
                    {isSidenavOpen && (
                        <div
                            className="fixed inset-0 bg-black opacity-50 z-10"
                            onClick={() => {
                                setSelectedStandard(null);
                                toggleSidenav();}}
                        />
                    )}

                    {/* Sidenav */}
                    <div ref={sideNavRef}
                        className={`fixed top-0 right-0 h-full overflow-y-auto w-[80%] bg-columbia_blue-300 z-10 shadow-xl overflow-x-clip transform transition-transform duration-300 ${
                            isSidenavOpen ? '-translate-x-0' : 'translate-x-full'
                        }`}
                    >

                        {(selectedStandardSet && knowShowCharts) && <>
                            <div className={"mx-2 w-full mt-6 mb-2"}>
                                <div className={"w-full mx-1]"}>
                                    {(selectedStandardSet && selectedStandard) ?
                                        // <StandardOverviewPanel selectedStandardSet={selectedStandardSet} standard={selectedStandard} standardCode={selectedStandard.standardCode} user={user} knowShow={knowShowCharts.filter(element => element.standard === selectedStandard.standardCode)} assessmentItems={assessmentItems.filter(element => element.standard === selectedStandard.standardCode)} setCreateItemModal={setCreateItemModal} setViewAssessmentItemsModal={setViewAssessmentItemsModal} resetFunction={fetchKnowShowCharts}/>
                                        <div
                                            className={'max-w-[90%] flex flex-col h-full bg-dutch_white-100 border-2 border-columbia_blue-700 rounded shadow mx-auto'}>
                                            <p className={"text-xl font-bold text-columbia_blue-800 my-2"}>{selectedStandard.title}</p>
                                            <p className={'text-columbia_blue-800 mb-2 text-xs px-2'}>{selectedStandard.text}</p>
                                            <div className={'grid grid-cols-6 gap-2 h-full p-2'}>
                                                <div className={'col-span-6'}>
                                                    <p title={'Recall sets are for organizing the most fundamental information in a standard. These sets are uniquely built only for matching style questions.'}
                                                       className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Recall</p>
                                                    {/*{(knowShowCharts.filter(item => item.standard === selectedStandard.standardCode).length === 0) &&*/}
                                                    {/*    <p className={'mt-4 text-columbia_blue-800 select-none'}>Looks like*/}
                                                    {/*        this is standard has not been broken down yet! Click edit below*/}
                                                    {/*        to get started. </p>}*/}

                                                    {(knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                        <p className={'mt-4 col-span-6 text-xs text-columbia_blue-800 select-none'}>There's
                                                            nothing here! Click Edit below to get to work on
                                                            this
                                                            standard's Recall section!</p> :
                                                        <div className={'flex'}>
                                                            <div className={'flex flex-col w-1/2 p-2'}>
                                                                {knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map((element, index) =>
                                                                    (index % 2 === 0) &&
                                                                    <div key={element._id.$oid}
                                                                         className={'bg-columbia_blue-300 col-span-3 rounded border-2 border-columbia_blue-700 px-2 flex flex-col shadow m-2'}>
                                                                        <div className={'flex flex-col h-full'}>
                                                                            <p className={'text-center font-bold p-1'}>{element.title}</p>
                                                                            <div
                                                                                className={'text-xs rounded-t bg-columbia_blue-500 p-1'}
                                                                                dangerouslySetInnerHTML={renderHtml(element.prompt)}/>
                                                                            <div
                                                                                className={'bg-paynes_gray h-full rounded-b flex flex-wrap p-2 mb-1'}>
                                                                                {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.map(item =>
                                                                                    <div
                                                                                        title={renderHtml(item.match.content).plainText}
                                                                                        className={'bg-columbia_blue-400 text-xs m-1 p-1 h-fit rounded grow'}
                                                                                        dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>)}
                                                                            </div>
                                                                            <div
                                                                                className={'flex gap-2 mx-auto mb-1'}>
                                                                                <button
                                                                                    className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                    onClick={() => setAddRecallElementsModal(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active'))}>
                                                                                    View / Add Items
                                                                                </button>
                                                                                <button
                                                                                    className={'text-xs text-platinum-100 rounded bg-paynes_gray-700 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-paynes_gray-800 cursor-pointer'}
                                                                                    onClick={() => setRecallEditPromptModal({
                                                                                        oldPrompt: element.prompt,
                                                                                        assessmentItemId: element._id.$oid
                                                                                    })}>
                                                                                    Edit Prompt
                                                                                </button>

                                                                                {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && (assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.length > 1) ?
                                                                                    <button
                                                                                        className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-900 cursor-pointer'}
                                                                                        onClick={() => setAssignRecallModal(assessmentItems.find(item => item.knowShowId === element._id.$oid))}>Assign
                                                                                        Items</button>
                                                                                    : <div
                                                                                        className={'text-xs text-platinum-100 rounded bg-platinum-700 p-1 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                        Items</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                            </div>

                                                            <div className={'flex flex-col w-1/2 p-2'}>
                                                                {knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map((element, index) =>
                                                                    (index % 2 === 1) &&
                                                                    <div key={element._id.$oid}
                                                                         className={'bg-columbia_blue-300 col-span-3 rounded border-2 border-columbia_blue-700 px-2 flex flex-col shadow m-2'}>
                                                                        <div className={'flex flex-col h-full'}>
                                                                            <p className={'text-center font-bold p-1'}>{element.title}</p>
                                                                            <div
                                                                                className={'text-xs rounded-t bg-columbia_blue-500 p-1'}
                                                                                dangerouslySetInnerHTML={renderHtml(element.prompt)}/>
                                                                            <div
                                                                                className={'bg-paynes_gray h-full rounded-b flex flex-wrap p-2 mb-1'}>
                                                                                {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.map(item =>
                                                                                    <div
                                                                                        title={renderHtml(item.match.content).plainText}
                                                                                        className={'bg-columbia_blue-400 text-xs m-1 p-1 h-fit rounded grow'}
                                                                                        dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>)}
                                                                            </div>
                                                                            <div
                                                                                className={'flex gap-2 mx-auto mb-1'}>
                                                                                <button
                                                                                    className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                    onClick={() => setAddRecallElementsModal(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active'))}>
                                                                                    View / Add Items
                                                                                </button>
                                                                                <button
                                                                                    className={'text-xs text-platinum-100 rounded bg-paynes_gray-700 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-paynes_gray-800 cursor-pointer'}
                                                                                    onClick={() => setRecallEditPromptModal({
                                                                                        oldPrompt: element.prompt,
                                                                                        assessmentItemId: element._id.$oid
                                                                                    })}>
                                                                                    Edit Prompt
                                                                                </button>

                                                                                {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && (assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.length > 1) ?
                                                                                    <button
                                                                                        className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-900 cursor-pointer'}
                                                                                        onClick={() => setAssignRecallModal(assessmentItems.find(item => item.knowShowId === element._id.$oid))}>Assign
                                                                                        Items</button>
                                                                                    : <div
                                                                                        className={'text-xs text-platinum-500 rounded bg-platinum-700 p-1 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                        Items</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                                <div className={'col-span-3'}>
                                                    <p title={'Understanding elements are aimed toward complex information related to categorization or relationships among parts of a standard. These are for areas of knowledge that require more deliberate assessment and should take the form of declarative statements of fact.'}
                                                       className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Understand</p>
                                                    {(knowShowCharts.filter(item => item.type === 'Know' && item.standard === selectedStandard.standardCode && item.active && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                        <p className={'mt-4 text-xs col-span-6 text-columbia_blue-800 select-none'}>There's
                                                            nothing here! Click Edit below to get to work on
                                                            this
                                                            standard's Understand section!</p> :
                                                        knowShowCharts.filter(item => item.type === 'Know' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map(element =>
                                                            <div key={element._id.$oid}
                                                                 className={'bg-columbia_blue-300 col-span-2 rounded border-2 border-columbia_blue-700 p-2 flex flex-col shadow m-2'}>
                                                                <div className={'flex flex-col h-full'}>
                                                                    <p className={'text-left text-sm p-1'}>{element.text}</p>
                                                                    <div className={'grow'}/>
                                                                    <div className={'flex gap-2 mx-auto'}>
                                                                        <button
                                                                            className={'text-xs text-platinum-500 rounded bg-burnt_sienna-600 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                            onClick={() => setCreateItemModal({
                                                                                ...element,
                                                                                questionType: 'Traditional MC'
                                                                            })}>Create
                                                                            Item
                                                                        </button>
                                                                        {(assessmentItems && assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length > 0) ?
                                                                            <button
                                                                                className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-700 cursor-pointer'}
                                                                                onClick={() => setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === element._id.$oid).filter(item => item.status === 'active'))}>View
                                                                                Items: {assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length}</button>
                                                                            : <div
                                                                                className={'text-xs text-platinum-100 rounded bg-platinum-700 p-2 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                Items</div>}
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                </div>
                                                <div className={'col-span-3'}>
                                                    <p title={'Apply elements represent the skills that students learn throughout a standard and demonstrate through assessment items. These should take the form of "I can..." statements.'}
                                                       className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Apply</p>
                                                    {(knowShowCharts.filter(item => item.type === 'Show' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                        <p className={'mt-4 text-xs col-span-6 text-columbia_blue-800 select-none'}>There's
                                                            nothing here! Click Edit below to get to work on
                                                            this
                                                            standard's Understand section!</p> :
                                                        knowShowCharts.filter(item => item.type === 'Show' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map(element =>
                                                            <div key={element._id.$oid}
                                                                 className={'bg-columbia_blue-300 col-span-2 rounded border-2 border-columbia_blue-700 p-2 flex flex-col shadow m-2'}>
                                                                <div className={'flex flex-col h-full'}>
                                                                    <p className={'text-left text-sm p-1'}>{element.text}</p>
                                                                    <div className={'grow'}/>
                                                                    <div className={'flex gap-2 mx-auto'}>
                                                                        <button
                                                                            className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                            onClick={() => setCreateItemModal({
                                                                                ...element,
                                                                                questionType: 'Traditional MC'
                                                                            })}>Create
                                                                            Item
                                                                        </button>
                                                                        {(assessmentItems && assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length > 0) ?
                                                                            <button
                                                                                className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-700 cursor-pointer'}
                                                                                onClick={() => setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === element._id.$oid).filter(item => item.status === 'active'))}>View
                                                                                Items: {assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length}</button>
                                                                            : <div
                                                                                className={'text-xs text-platinum-100 rounded bg-platinum-700 p-2 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                Items</div>}
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                </div>
                                            </div>


                                            <div className={'grow'}></div>
                                            <button onClick={() => setEditKnowShowChartModal(selectedStandard)}
                                                    className={'w-full bg-paynes_gray-500 hover:bg-paynes_gray-400 shadow-inner mt-1 py-1 text-dutch_white-200 select-none cursor-pointer'}>Edit
                                                Standard Elements
                                            </button>
                                        </div>

                                        : <></>}
                                </div>
                            </div>
                        </>}
                    </div>

                    {/* Main Content */}
                    <div className={`grow flex flex-col overflow-x-clip`}>
                        <div>
                            <div className="flex items-center bg-columbia_blue-300 justify-between p-4 border-b-2 border-columbia_blue-800">
                                <h2 className="text-xl font-bold text-columbia_blue-800">Standards</h2>
                                <select
                                    className="rounded p-4 bg-columbia_blue-800 text-dutch_white-300 font-bold"
                                    onChange={handleChangeStandardSet}
                                    defaultValue=""
                                >
                                    <option disabled value="">
                                        Select a Standard Set
                                    </option>
                                    {standardSets
                                        .sort((a, b) => a.prepTitle.toLowerCase().localeCompare(b.prepTitle.toLowerCase()))
                                        .map(standardSet => (
                                            <option key={standardSet.title} value={standardSet.title}>
                                                {standardSet.prepTitle}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            {(selectedStandardSet) && <div className="p-2">
                                <InteractiveSearch placeholder="Search Standards" handleChange={handleSearchChange}/>
                            </div>}
                            {(selectedStandardSet &&
                                <div className="grow">
                                    {searchValue ? (
                                        searchValue.map(standard => (
                                            <StandardSelectionBar
                                                key={standard.standardCode}
                                                standard={standard}
                                                standardCode={standard.standardCode}
                                                selectedStandard={selectedStandard}
                                                setSelectedStandard={setSelectedStandard}
                                                knowShow={knowShowCharts.filter(
                                                    item => item.standard === standard.standardCode && item.prep === selectedStandardSet.prepTitle
                                                )}
                                                handleStandardClick={handleStandardClick}

                                            />
                                        ))
                                    ) : (
                                        selectedStandardSet.standards.map(standard => (
                                            <StandardSelectionBar
                                                key={standard.standardCode}
                                                standard={standard}
                                                standardCode={standard.standardCode}
                                                selectedStandard={selectedStandard}
                                                setSelectedStandard={setSelectedStandard}
                                                knowShow={knowShowCharts.filter(
                                                    item => item.standard === standard.standardCode && item.prep === selectedStandardSet.prepTitle
                                                )}
                                                handleStandardClick={handleStandardClick}
                                            />
                                        ))
                                    )}
                                    <button
                                        className="flex flex-col py-2 px-4 w-[80%] border-2 border-columbia_blue-800 my-2 bg-columbia_blue-800 hover:bg-columbia_blue-700 rounded border-opacity-30 mx-auto cursor-pointer shadow text-center"
                                        onClick={() => setSupplementalStandardModal(true)}
                                    >
                                        <p className="font-bold text-dutch_white-400">Add Supplemental Standard</p>
                                    </button>
                                </div>)}

                            <div className={'h-full w-[95%] mx-auto my-4 relative'}>


                            </div>
                        </div>
                    </div>
                </div>
                {
                    (createItemModal) && <CreateItemModal user={user} createItemModal={createItemModal}
                                                          setCreateItemModal={setCreateItemModal}
                                                          assessmentItems={assessmentItems}
                                                          setAssessmentItems={setAssessmentItems}
                                                          resetFunction={fetchAssessmentItems}/>
                }
                {
                    (viewAssessmentItemsModal) && <ViewAssessmentItemsModal user={user} rosters={rosters}
                                                                            viewAssessmentItemsModal={viewAssessmentItemsModal}
                                                                            setViewAssessmentItemsModal={setViewAssessmentItemsModal}
                                                                            resetFunction={fetchAssessmentItems}/>
                }
                {
                    (assignRecallModal) && <AssignRecallModal user={user} assignRecallModal={assignRecallModal}
                                                              setAssignRecallModal={setAssignRecallModal}
                                                              rosters={rosters}/>
                }
                {
                    (supplementalStandardModal) &&
                    <SupplementalStandardModal setSupplementalStandardModal={setSupplementalStandardModal}
                                   school={user.userAttributes['custom:school']}
                                   standardSet={selectedStandardSet} resetFunction={fetchStandardSets}/>
    }
    {
        (editKnowShowChartModal) && <EditKnowShowChartModal editKnowShowChartModal={editKnowShowChartModal}
                                                            selectedStandardSet={selectedStandardSet}
                                                            setKnowShowCharts={setKnowShowCharts}
                                                            setAssessmentItems={setAssessmentItems}
                                                            setEditKnowShowChartModal={setEditKnowShowChartModal}
                                                            user={user}
                                                            standardCode={selectedStandard.standardCode}
                                                            knowShowElements={knowShowCharts.filter(item => item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle)}/>
    }
    {
        (addRecallElementsModal) &&
        <AddRecallElementsModal user={user} addRecallElementsModal={addRecallElementsModal}
                                setAddRecallElementsModal={setAddRecallElementsModal} assessmentItems={assessmentItems}
                                setAssessmentItems={setAssessmentItems} resetFunction={fetchAssessmentItems}/>
    }
    {
        (recallEditPromptModal) && <RecallEditPromptModal setRecallEditPromptModal={setRecallEditPromptModal}
                                                          recallEditPromptModal={recallEditPromptModal} user={user}
                                                          getAssessmentItems={fetchAssessmentItems}/>
    }
    </div>
    )
        ;
    }
}

export default Planning;