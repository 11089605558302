import React from "react";

function RosterAssignmentData({ item, element, isLastItem }) {

    function formatDateToMMDD(date) {
        // Extract the month and day from the date object
        const month = date.getMonth() + 1; // Months are zero-indexed
        const day = date.getDate();

        // Pad single digit months and days with leading zeros
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Return the formatted date as mm/dd
        return `${formattedMonth}/${formattedDay}`;
    }

    // Sort the assessmentHistory array once
    const sortedAssessmentHistory = item.assessmentHistory
        .slice() // Create a shallow copy to avoid mutating the original array
        .filter(item => item.knowShowId === element._id.$oid)
        .sort((a, b) => new Date(b.date.$date) - new Date(a.date.$date));

    // Check if the element ID exists in the sorted assessmentHistory
    const containsElementId = sortedAssessmentHistory.map(entry => entry.knowShowId).includes(element._id.$oid);

    // Get the most recent date if the element ID exists
    const mostRecentDate = containsElementId
        ? formatDateToMMDD(new Date(sortedAssessmentHistory[0].date.$date))
        : null;

    // Conditional rendering based on the checks
    return containsElementId ? (
        <div
            className={`bg-columbia_blue-500 text-columbia_blue-900 text-[7px] w-full border-b-[1px] border-dutch_white-500 ${isLastItem ? 'rounded-b' : ''} select-none`}>
            {item.title} - {mostRecentDate}
        </div>
    ) : (
        <div
            className={`bg-platinum-600 text-columbia_blue-900 text-[7px] w-full border-b-[1px] border-dutch_white-500 ${isLastItem ? 'rounded-b' : ''} select-none`}>
            {item.title}
        </div>
    );
}

export default RosterAssignmentData;