import React, { useState, useEffect } from 'react';
import DOMPurify from "dompurify";

function SortingScrambledPreview({ assessmentItem }) {
    const [shuffledCategories, setShuffledCategories] = useState(null);

    useEffect(() => {
        const shuffled = {categories: assessmentItem.categories.map(item => item.title).sort(() => 0.5 - Math.random()), items: assessmentItem.categories.flatMap(item => item.items).sort(() => 0.5 - Math.random())};
        setShuffledCategories(shuffled);
    }, [assessmentItem]);

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={'flex p-1 bg-dutch_white-200 shadow my-2 rounded mx-auto'}>

            <div className={'flex flex-col gap-2 bg-dutch_white-900 p-2 rounded'}>
                <div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>
                    <div dangerouslySetInnerHTML={renderHtml(assessmentItem.prompt)} />
                </div>
                {Object.keys(assessmentItem).includes('imageURL') && (
                    <img
                        className={`max-w-[150px] max-h-[150px] rounded mx-auto my-auto`}
                        src={assessmentItem.imageURL}
                        alt="Assessment"
                    />
                )}
                <div className={'flex flex-wrap gap-4 max-h-[800px] max-w-[90%] mx-auto'}>
                    {(shuffledCategories) && shuffledCategories.categories.map((item, index) => (
                        <div key={index} className={'flex'}>
                            <div className={'p-2 rounded bg-columbia_blue-400 shadow border-dutch_white-200 border-2 h-fit my-auto'}>
                                <div dangerouslySetInnerHTML={renderHtml(item)} className={'my-auto'} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'w-full h-1 bg-columbia_blue-400'}></div>
                <div className={'flex flex-wrap gap-4 max-h-[200px] max-w-[90%] mx-auto overflow-y-auto'}>
                    {(shuffledCategories) && shuffledCategories.items.map((item, index) => (
                        <div key={index} className={'flex'}>
                            <div className={'p-2 rounded bg-dutch_white-200 shadow border-columbia_blue-400 border-2 h-fit my-auto'}>
                                {item.type === 'text' ? (
                                    <div dangerouslySetInnerHTML={renderHtml(item.content)} className={'my-auto'} />
                                ) : (
                                    <img className={'max-h-[100px]'} src={item.content} alt="Match" />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SortingScrambledPreview;
