import React, {useEffect, useState} from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder';
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Underline} from "@tiptap/extension-underline";
import {Subscript} from "@tiptap/extension-subscript"
import {Superscript} from "@tiptap/extension-superscript";

const MatchTipTap = ({setHtmlOutput, index, content = '', fontSize = 'text-base', backgroundColor = 'bg-white', textColor = 'text-black', placeholder = 'Type here...'}) => {
    const [editorContent, setEditorContent] = useState(content);

    const extensions = [
        Document,
        Paragraph,
        Text,
        Placeholder.configure({
            placeholder: placeholder,
        }),
        Bold,
        Italic,
        Underline,
        Subscript,
        Superscript
    ]

    const clearFormatting = () => {
        editor.chain().focus()
            .unsetMark('bold')
            .unsetMark('italic')
            .unsetMark('underline')
            .unsetMark('subscript')
            .unsetMark('superscript')
            .run()
    }

    const editor = useEditor({
        extensions,
        content: content, // Set initial content from state
        onUpdate: ({ editor }) => {
            const html = editor.getHTML()
            setHtmlOutput(html, index)
        },
        editorProps: {
            attributes: {
                class: `${fontSize} ${backgroundColor} ${textColor} p-2 border-2 border-columbia_blue-600 rounded`,
            },
        },
    })

    useEffect(() => {
        if (editor && content !== editorContent) {
            setEditorContent(content);  // Update internal state
            editor.commands.setContent(content);  // Update Tiptap's content
        }
    }, [content, editor]);

    // Function to apply formatting
    const applyFormat = (format) => {
        editor.chain().focus()[format]().run()
    }

    return (
        <div className="relative bg-dutch_white-100 p-1 rounded shadow">
            {/* Static Button Bar */}
            <div className="flex space-x-2 mt-2 w-full overflow-x-clip">
                <div className={'grow'}/>

                <button title={'ctrl + b'} tabIndex={-1} onClick={() => applyFormat('toggleBold')}
                        className="px-2 bg-gray-200 hover:bg-gray-300 rounded shadow"><strong>B</strong>
                </button>
                <button title={'ctrl + i'} tabIndex={-1} onClick={() => applyFormat('toggleItalic')}
                        className="px-2 bg-gray-200 hover:bg-gray-300 rounded shadow"><em>I</em></button>
                <button title={'ctrl + u'} tabIndex={-1} onClick={() => applyFormat('toggleUnderline')}
                        className="px-2 bg-gray-200 hover:bg-gray-300 rounded shadow"><u>U</u>
                </button>
                <button title={'ctrl + ,'} tabIndex={-1} onClick={() => applyFormat('toggleSubscript')}
                        className="px-2 bg-gray-200 hover:bg-gray-300 rounded shadow">X<sub>2</sub>
                </button>
                <button title={'ctrl + .'} tabIndex={-1} onClick={() => applyFormat('toggleSuperscript')}
                        className="px-2 bg-gray-200 hover:bg-gray-300 rounded shadow">X<sup>2</sup>
                </button>
                <div className={'grow'}/>
                <button title={'Remove all formatting from selected text'} tabIndex={-1} onClick={clearFormatting}
                        className="px-2 bg-burnt_sienna-200 hover:bg-burnt_sienna-300 rounded shadow">Clear
                    Formatting
                </button>
                <div className={'grow'}/>


            </div>

            {/* Editor Content */}
            <EditorContent editor={editor} className="mt-2 w-full text-left"/>

        </div>
    )
}

export default MatchTipTap