import React, {useContext, useEffect, useState} from 'react';
import AddStudentModal from "./addStudentModal";
import UserContext from "../../context/userContext";
import {useNavigate} from "react-router-dom";
import BulkStudentModal from "./bulkStudentModal";
import AddTeacherModal from "./addTeacherModal";
import ScheduleChangeModal from "./scheduleChangeModal";
import DeployStandardSetsModal from "./deployStandardSetsModal";
import BulkScheduleChangeModal from "./bulkScheduleChangeModal";
import Loader from "../../components/loader";
import ModifyTeacherPrepsModal from "./modifyTeacherPrepsModal";
import ReviewAndEditStandardSetsModal from "./reviewAndEditStandardSetsModal";
import getWithAuth from "../../context/getWithAuth";
import ToSModal from "../../components/tosModal";

function AccountManagement() {
    const [addStudentModal, setAddStudentModal] = useState(false)
    const [addTeacherModal, setAddTeacherModal] = useState(false)
    const [modifyTeacherPrepsModal, setModifyTeacherPrepsModal] = useState(false)
    const [standardSets, setStandardSets] = useState(null);
    const [standardSetTemplates, setStandardSetTemplates] = useState(null);
    const [studentContext, setStudentContext] = useState(null);
    const [teacherContext, setTeacherContext] = useState(null);
    const [deployStandardSetsModal, setDeployStandardSetsModal] = useState(false);
    const [reviewAndEditStandardSetsModal, setReviewAndEditStandardSetsModal] = useState(false)
    const [bulkStudentModal, setBulkStudentModal] = useState(false);
    const [scheduleChangeModal, setScheduleChangeModal] = useState(false);
    const [bulkScheduleChangeModal, setBulkScheduleChangeModal] = useState(false);
    const user = useContext(UserContext);
    const navigate = useNavigate();

    const fetchStandardSets = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/accountmanagement/getStandardSets', user.userAttributes['custom:school'])
                    .then(async r => {
                        setStandardSetTemplates(r.filter(item => item.school === 'brainmetertemplate'))
                        setStandardSets(r.filter(item => item.school !== 'brainmetertemplate'))
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStudentContext = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth('/accountmanagement/getStudentContext', user.userAttributes['custom:school'])
                    .then(async r => {
                        setStudentContext(r.filter(item => item.role === 'student'));
                        setTeacherContext(r.filter(item => item.role !== 'student'));
                    })
            }catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        fetchStandardSets();
        fetchStudentContext();
    }, [user.userContext])

    if (!user.userContext) {
        return <Loader message={'Fetching User Data'}/>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role !== 'coordinator') {
        navigate('/')
    } else if (!standardSets || !studentContext) {
        return <Loader message={`Fetching: ${(!standardSets) ? "StandardSets - " : ""}${(!studentContext) ? "Student Data" : ""}`}/>
    } else {
        return (
            <>
                <div className={'grid grid-cols-2 gap-4 py-2 mx-8 min-h-screen'}>
                    <div>
                        <p>Student Accounts</p>
                        <div onClick={() => {
                            setAddStudentModal(true)
                        }}
                             className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>
                            Add a Student
                        </div>
                        <div onClick={() => {
                            setBulkStudentModal(true)
                        }}
                             className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>
                            Bulk Add Students
                        </div>
                        {/*<div onClick={() => {*/}
                        {/*    setScheduleChangeModal(true)*/}
                        {/*}}*/}
                        {/*     className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>*/}
                        {/*    Modify Student Schedule*/}
                        {/*</div>*/}
                        {/*<div onClick={() => {*/}
                        {/*    setBulkScheduleChangeModal(true)*/}
                        {/*}}*/}
                        {/*     className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>*/}
                        {/*    Bulk Modify Student Schedules*/}
                        {/*</div>*/}
                    </div>
                    <div>
                        <p>Teacher Accounts</p>
                        <div onClick={() => {
                            setAddTeacherModal(true)
                        }}
                             className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>
                            Add a Teacher
                        </div>
                        <div onClick={() => {
                            setModifyTeacherPrepsModal(true)
                        }}
                             className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>
                            Modify Teacher Preps
                        </div>
                    </div>
                    <div>
                        <p>Standard Sets</p>
                        <div onClick={() => {
                            setDeployStandardSetsModal(true)
                        }}
                             className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>
                            Deploy a Standard Set
                        </div>
                        <div onClick={() => {
                            setReviewAndEditStandardSetsModal(true)
                        }}
                             className={'px-4 py-2 bg-columbia_blue-700 text-dutch_white-200 hover:bg-columbia_blue cursor-pointer rounded font-bold m-2 select-none'}>
                            Review and Edit Standard Sets
                        </div>
                    </div>
                </div>
                {(addStudentModal) && (standardSets) && <AddStudentModal setAddStudentModal={setAddStudentModal}
                                                                         preps={standardSets.map(item => item.prepTitle)}
                                                                         school={user.userAttributes['custom:school']}/>}
                {(bulkStudentModal) && (standardSets) && <BulkStudentModal setBulkStudentModal={setBulkStudentModal}
                                                                           preps={standardSets.map(item => item.prepTitle)}
                                                                           school={user.userAttributes['custom:school']}/>}
                {(addTeacherModal) && (standardSets) && <AddTeacherModal setAddTeacherModal={setAddTeacherModal}
                                                                         preps={standardSets.map(item => item.prepTitle)}
                                                                         school={user.userAttributes['custom:school']}/>}
                {(modifyTeacherPrepsModal) && (standardSets) && <ModifyTeacherPrepsModal setModifyTeacherPrepsModal={setModifyTeacherPrepsModal}
                                                                         preps={standardSets.map(item => item.prepTitle)}
                                                                         school={user.userAttributes['custom:school']} teacherContext={teacherContext}/>}
                {/*{(scheduleChangeModal) && (standardSets) && <ScheduleChangeModal setScheduleChangeModal={setScheduleChangeModal}*/}
                {/*                                                                 preps={standardSets.map(item => item.prepTitle)}*/}
                {/*                                                                 school={user.userAttributes['custom:school']} studentContext={studentContext}/>}*/}
                {/*{(bulkScheduleChangeModal) && (standardSets) && <BulkScheduleChangeModal setBulkScheduleChangeModal={setBulkScheduleChangeModal}*/}
                {/*                                                                         preps={standardSets.map(item => item.prepTitle)}*/}
                {/*                                                                         school={user.userAttributes['custom:school']} studentContext={studentContext}/>}*/}
                {(deployStandardSetsModal) && (standardSets) && (standardSetTemplates) && <DeployStandardSetsModal existingStandardSets={standardSets} standardSetTemplates={standardSetTemplates} school={user.userAttributes['custom:school']} setDeployStandardSetsModal={setDeployStandardSetsModal} />}
                {(reviewAndEditStandardSetsModal) && (standardSets) && <ReviewAndEditStandardSetsModal standardSets={standardSets} setReviewAndEditStandardSetsModal={setReviewAndEditStandardSetsModal}/>}
            </>
        );
    }
}

export default AccountManagement;