import React, {useEffect, useRef, useState} from 'react';
import InteractiveSearch from "../../components/interactiveSearch";
import postWithAuth from "../../context/postWithAuth";
import TipTap from "../../components/tipTap";

function CreateUnitModal({user, prep, setCreateUnitModal, standardSets, knowShowCharts, getUnitPlans, setUnitPlans}) {
    const [title, setTitle] = useState(null);
    const [duration, setDuration] = useState(1);
    const [instructionalSequence, setInstructionalSequence] = useState([[], [], [], [], []])
    const [selectedStandards, setSelectedStandards] = useState([])
    const [selectedStandardElement, setSelectedStandardElement] = useState(null)
    const [phase, setPhase] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('');
    const [SESearch, setSESearch] = useState('')
    const quillRef = React.createRef()

    const textReturn = (standardElement) => {
        if (standardElement.type === 'Recall') {
            return standardElement.title
        } else {
            return standardElement.text
        }
    }

    const handlePhase = (change) => {
        setPhase(change)
    }
    const handlePaste = (e) => {
        // Prevent the default paste behavior
        e.preventDefault();

        // Get the pasted text
        const text = e.clipboardData.getData('text/plain');

        // Insert the text into the editor
        const quill = quillRef.current.getEditor();
        quill.clipboard.dangerouslyPasteHTML(text);
    };

    const handleTitleChange = (content, delta, source, editor) => {
        setTitle(content);
    };

    const handleMinusClick = () => {
        if (duration > 1) {
            setDuration(duration - 1)
            setInstructionalSequence([...instructionalSequence].slice(0, -5))

        }
    }

    const handlePlusClick = () => {
        setDuration(duration + 1);
        setInstructionalSequence([...instructionalSequence, [], [], [], [], []])
    }

    const onClose = () => {
        if (window.confirm("Are you sure? Closing this window will remove all planning progress.")) {
            setCreateUnitModal(null)
        } else {
            return null
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleStandardClick = (standard) => {
        setSelectedStandards([...selectedStandards, standard])
    }

    const handleStandardUnclick = (standard) => {
        if (flattenedValues.includes(standard.standardCode)) {
            window.alert('You cannot remove standards while their elements are in your Daily Learning Target Calendar')
            return
        }
        setSelectedStandards(selectedStandards.filter(item => item.standardCode !== standard.standardCode))
    }

    const handleDayClick = (index) => {
        if (selectedStandardElement) {
            let tempWeeks = [...instructionalSequence]
            tempWeeks[index].push({...selectedStandardElement, assessed: false});
            setInstructionalSequence(tempWeeks);
            setSelectedStandardElement(null);
        }
    }

    const handleRemoveSEClick = (index, element) => {
        let tempWeeks = [...instructionalSequence];
        tempWeeks[index] = tempWeeks[index].filter(item => textReturn(item) !== textReturn(element))
        setInstructionalSequence(tempWeeks);
    }

    const flattenedValues = instructionalSequence.flat().map(item => textReturn(item))

    const handleStandardSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const handleSESearchChange = (e) => {
        setSESearch(e.target.value)
    }



    const postUnitPlan = async () => {
        if (!title) {
            window.alert('Your unit plan needs a title!')
            return;
        } else if (flattenedValues.length === 0) {
            window.alert('Your instructional plan is empty! Move some standard elements into the daily learning targets.')
        } else {
            let newUnitPlan = {
                standards: selectedStandards,
                duration: duration,
                calendar: instructionalSequence,
                title: title,
                prep: prep,
                school: user.userContext.school,
                user: user.userContext
            }
            setIsLoading(true);
            postWithAuth('/logistics/postCreateUnitPlan', user.userAttributes["custom:school"], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: newUnitPlan
            })
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    else {
                        throw new Error('Failed to post unit plan.');
                    }
                })
                .then(data => {
                    setUnitPlans(prev => [...prev, data])
                    window.alert('Unit Plan Successfully Created')
                    setCreateUnitModal(null);
                })
        }}

    const modalRef = useRef(null);

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        const focusableElementsArray = Array.from(focusableElements)

        if (!focusableElementsArray.includes(document.activeElement)) {
            focusableElements[7].focus();
        }

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setCreateUnitModal, setSelectedStandards, setPhase, setSelectedStandardElement]);

    const calendarRef = useRef(null)
    const seRef = useRef(null)


    const setCalendarFocus = () => {
        const calendar = calendarRef.current
        const focusableElements = calendar.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];

        if (Array.from(focusableElements).length > 0) {
            firstFocusableElement.focus()
        }

    }

    const returnSEFocus = () => {
        const standardElements = seRef.current
        const focusableElements = standardElements.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];

        if (Array.from(focusableElements).length > 0) {
            firstFocusableElement.focus()
        }

    }


    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"relative bg-dutch_white-500 rounded p-2 mx-auto min-h-[30%] h-[93%] w-[95%] flex"} ref={modalRef}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={"p-2 bg-columbia_blue-800 flex h-full w-2/3 rounded overflow-auto"}>
                    <div className={'flex flex-col gap-1 w-full'}>
                        <div className={'flex gap-1'}>
                            <div className={'bg-dutch_white-300 h-fit w-1/2 flex flex-col p-1 rounded'}>
                                <p className={'select-none'}>Title</p>
                                <div className={''}>
                                    <TipTap setHtmlOutput={setTitle} placeholder={'Unit Title...'} backgroundColor={'bg-dutch_white-300'} fontSize={'text-lg'} textColor={'text-columbia_blue-900'} />
                                    {/*<ReactQuill ref={quillRef} onPaste={handlePaste} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ script: 'sub' }, { script: 'super' }]] }} id={"prompt"} onChange={handleTitleChange} placeholder={""} className={'bg-columbia_blue-300'}/>*/}
                                </div>
                            </div>
                            <div className={'flex flex-col gap-1 w-1/2'}>
                                <div className={'bg-dutch_white-300 h-fit w-full flex p-1 rounded'}>
                                    <div className={'grow'}/>
                                    <p className={'my-auto select-none'}>Unit Length (weeks):</p>
                                    <div className={'grow'}/>
                                    <div className={'flex'}>
                                        <button className={'h-fit bg-columbia_blue-800 text-dutch_white-400 hover:bg-columbia_blue-700 rounded px-2 my-auto select-none cursor-pointer'} onClick={handleMinusClick}>-</button>
                                        <p className={'font-bold text-3xl mx-3 select-none'}>{duration}</p>
                                        <button className={'h-fit bg-columbia_blue-800 text-dutch_white-400 hover:bg-columbia_blue-700 rounded px-2 my-auto select-none cursor-pointer'} onClick={handlePlusClick}>+</button>
                                    </div>
                                    <div className={'grow'}/>
                                </div>
                                <div className={'bg-dutch_white-300 h-fit w-full flex flex-wrap p-1 rounded'}>
                                    <p className={'my-auto select-none'}>Standards:</p>
                                    {selectedStandards.map(item => <button title={item.title} onClick={() => handleStandardUnclick(item)} className={'rounded bg-columbia_blue-700 text-dutch_white-200 border-4 border-columbia_blue-500 p-1 w-fit mx-1 select-none hover:bg-columbia_blue-600 hover:border-burnt_sienna cursor-pointer text-xs'}>{item.code}</button>)}
                                </div>
                            </div>
                        </div>
                        <p className={'col-span-5 font-bold text-dutch_white-200'}>Daily Learning Targets</p>

                        <div className={'w-full bg-columbia_blue-700 rounded h-full overflow-auto p-1'}>
                            <div className={'grid grid-cols-5 gap-1'} ref={calendarRef}>
                                {instructionalSequence.map((day, index) => <>
                                        {(selectedStandardElement) ?
                                            <button onClick={() => {handleDayClick(index); returnSEFocus()}} className={'min-h-[98px] h-full bg-dutch_white-300 mx-1 rounded border-4 border-burnt_sienna-400 flex cursor-pointer hover:bg-dutch_white-400 flex-col gap-1'}>
                                                <p className={'text-xs text-columbia_blue-900 text-start ml-1 select-none'}>Day {index + 1}</p>
                                                {day.map(element => <div className={'text-[8px] w-full rounded p-1 select-none bg-platinum-700 text-dutch_white-400'}>{textReturn(element)}</div>)}
                                            </button>
                                            : <div className={'min-h-[98px] h-full bg-dutch_white-300 mx-1 rounded border-4 border-dutch_white-300 flex flex-col gap-1'}>
                                                <p className={'text-xs text-columbia_blue-900 text-start ml-1 select-none'}>Day {index + 1}</p>
                                                {day.map(element => <button onClick={() => handleRemoveSEClick(index, element)} className={'text-[8px] rounded p-1 select-none cursor-pointer bg-columbia_blue-700 hover:bg-burnt_sienna-600 text-dutch_white-400 shadow-lg'}>{textReturn(element)}</button>)}
                                            </div>}

                                    </>
                                )}</div>
                        </div>
                    </div>

                </div>
                <div className={'flex flex-col mx-1 bg-dutch_white-100 rounded w-1/3 gap-1 h-full'}>
                    {(phase === 1) ? <div className={'bg-dutch_white-100 mx-1 flex flex-col gap-1 p-1 h-full'}>
                            <p className={'select-none'}>Standards:</p>
                            <InteractiveSearch handleChange={handleStandardSearchChange}/>
                            <div className={'h-80% overflow-auto text-left flex flex-col gap-1'}>
                                {standardSets.find(item => item.prepTitle === prep)
                                    .standards
                                    .filter(item => (!selectedStandards.includes(item))).filter(item => item.text.toLowerCase().includes(search.toLowerCase()) || item.title.toLowerCase().includes(search.toLowerCase()) || item.supportingText.toLowerCase().includes(search.toLowerCase()) || item.standardCode.toLowerCase().includes(search.toLowerCase()))
                                    .map(item => <button title={item.text} onClick={() => handleStandardClick(item)} className={'rounded w-full bg-dutch_white p-1 w-fit mx-1 select-none hover:bg-dutch_white-400 cursor-pointer'}>{item.code} {item.title}</button>)}

                            </div>
                            <div className={'grow'} />
                            {(selectedStandards.length > 0) && <button onClick={() => handlePhase(2)} className={'py-2 bg-burnt_sienna-600 text-dutch_white-200 hover:bg-burnt_sienna-700 cursor-pointer select-none rounded'}>Next Step: Standard Elements</button>}
                        </div>
                        :<div className={'bg-columbia_blue-200 mx-1 flex flex-col gap-1 p-1 h-full'}>
                            <p className={'select-none'}>Standard Elements:</p>
                            <InteractiveSearch handleChange={handleSESearchChange}/>
                            <div className={'h-full overflow-auto'} ref={seRef}>
                                {selectedStandards.map(standard => (
                                    <>
                                        <p>{standard.code}: {standard.title}</p>
                                        <div className={'h-80% overflow-auto text-left grid grid-cols-2 gap-2 border-b-2 pb-1 border-columbia_blue-700'}>
                                            {knowShowCharts.filter(item => item.type !== 'Vocab' && item.active).filter(item => item.prep === prep).filter(item => item.standard === standard.standardCode).filter(item => textReturn(item).toLowerCase().includes(SESearch.toLowerCase()))
                                                .map(item => (selectedStandardElement !== item) ? (flattenedValues.includes(textReturn(item))) ? <button onClick={async () => {await setSelectedStandardElement(item); setCalendarFocus();}} className={'rounded text-center bg-columbia_blue-500 px-2 py-1 border-2 border-columbia_blue-600 select-none hover:bg-dutch_white-400 cursor-pointer items-center text-columbia_blue-800 text-xs shadow'}>{textReturn(item)}</button>
                                                        : <button onClick={async () => {await setSelectedStandardElement(item); setCalendarFocus();}} className={'rounded bg-dutch_white px-2 py-1 border-2 text-center border-dutch_white-600 select-none hover:bg-dutch_white-400 cursor-pointer items-center text-columbia_blue-800 text-xs shadow'}>{textReturn(item)}</button>
                                                    : <button onClick={() => setSelectedStandardElement(null)} className={'rounded bg-columbia_blue-800 border-2 text-center border-burnt_sienna-400 px-2 py-1 select-none hover:bg-columbia_blue-700 cursor-pointer items-center text-xs shadow text-dutch_white-200'}>{textReturn(item)}</button>)}
                                        </div>

                                    </>))}
                            </div>
                            <div className={'grow'}/>
                            <button onClick={() => handlePhase(1)} className={'py-2 bg-burnt_sienna-600 text-dutch_white-200 hover:bg-burnt_sienna-700 cursor-pointer select-none rounded'}>Back to Standard Select</button>

                        </div>}
                </div>
                {(flattenedValues.length > 0) && <button onClick={postUnitPlan} className={'absolute -bottom-5 left-[40%] rounded bg-burnt_sienna-600 hover:bg-burnt_sienna-700 text-dutch_white-400 font-bold cursor-pointer mx-auto w-[20%] py-1 px-2 select-none '}>Upload Unit Plan</button>}

            </div>
            {(isLoading) && <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
            </div>}
        </div>
    );
}

export default CreateUnitModal;