import React, {useRef, useState} from 'react';
import postWithAuth from "../../context/postWithAuth";
import Editor from "../../components/editor";
import TipTap from "../../components/tipTap";

function ImageMcInput({user, createItemModal, setCreateItemModal, setIsLoading, assessmentItems, setAssessmentItems}) {
    const hiddenCorrectInput = React.useRef(null);
    const hiddenDistractor1Input = React.useRef(null);
    const hiddenDistractor2Input = React.useRef(null);
    const hiddenDistractor3Input = React.useRef(null);
    const [imageMCFiles, setImageMCFiles] = useState({correct: {url: null, file: null}, distractor1: {url: null, file: null}, distractor2: {url: null, file: null}, distractor3: {url: null, file: null}})
    const [questionTextContent, setQuestionTextContent] = useState(null);

    const hiddenFileInput = React.useRef(null);
    let [stimulusImage, setStimulusImage] = useState(null)
    let [pageImage, setPageImage] = useState(null)

    const publishImageMCItem = async () => {
        if (!questionTextContent || Object.keys(imageMCFiles).map(item => imageMCFiles[item].file).includes(null)) {
            window.alert("You must include a prompt, a correct answer, and all three distractors to submit.")
            return null;
        } else if (!window.confirm("Are you sure? Submitting now will store all question content permanently in the database.")) {
            return null;
        } else {
            setIsLoading(true);

            let promptImageUrl = ''

            if (stimulusImage) {
                let response = await uploadImage(stimulusImage);
                promptImageUrl = `https://ucarecdn.com/${response}/`
            }

            let formData = new FormData();
            formData.set("UPLOADCARE_PUB_KEY", process.env.REACT_APP_UPLOADCARE_PUB_KEY);
            formData.set("UPLOADCARE_STORE", 1);

// Create and append fields for each image
            for (const imageType of Object.keys(imageMCFiles)) {
                const file = imageMCFiles[imageType].file;

                if (file) {
                    // Append the file with a unique field name
                    formData.append(`${imageType}_file`, file);
                    // Append other fields if needed (e.g., filename)
                    formData.set(`${imageType}_filename`, file.name);
                }
            }

// Send the FormData object in a POST request
            fetch('https://upload.uploadcare.com/base/', {
                method: 'POST',
                body: formData,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to upload images.');
                    }
                })
                .then((data) => {
                    const imageUrls = {};

                    // Extract the URLs for each image type from the response data
                    for (const imageType of Object.keys(imageMCFiles)) {
                        const url = data[`${imageType}_file`];

                        if (url) {
                            imageUrls[imageType] = `https://ucarecdn.com/${url}/`;
                        }
                    }
                    let url = new URL(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}/planning/postImageMCItem`)
                    url.searchParams.append('school', user.userAttributes["custom:school"]);

                    // Now, you can use imageUrls to send your request with the URLs
                    // to your own database, similar to your existing code
                    postWithAuth('/planning/postImageMCItem', user.userAttributes['custom:school'], {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: {
                            // Include your data and the image URLs
                            knowShowId: createItemModal._id.$oid,
                            knowShowText: createItemModal.text,
                            standard: createItemModal.standard,
                            prep: createItemModal.prep,
                            school: user.userAttributes['custom:school'],
                            questionTextContent: questionTextContent,
                            imageMCUrls: imageUrls,
                            imageURL: promptImageUrl,
                        },
                    })
                        .then(async (response) => {
                            if (!response.ok) {
                                throw new Error('Failed to make the upload.');
                            } else {
                                return response.json();
                            }
                        })
                        .then(async data => {
                            await setAssessmentItems(prev => [...prev, data]);
                            setIsLoading(false);
                            setCreateItemModal(null);
                        })
                })
                .catch((error) => {
                    console.error(error);
                })}};

    let handleCorrectImage = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            let currentImages = {...imageMCFiles};
            currentImages.correct.file = file;
            currentImages.correct.url = URL.createObjectURL(file);
            setImageMCFiles(currentImages);
        }
    }

    let handleDistractor1Change = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            let currentImages = {...imageMCFiles};
            currentImages.distractor1.file = file;
            currentImages.distractor1.url = URL.createObjectURL(file);
            setImageMCFiles(currentImages);
        }
    }

    let handleDistractor2Change = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            let currentImages = {...imageMCFiles};
            currentImages.distractor2.file = file;
            currentImages.distractor2.url = URL.createObjectURL(file);
            setImageMCFiles(currentImages);
        }
    }

    let handleDistractor3Change = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            let currentImages = {...imageMCFiles};
            currentImages.distractor3.file = file;
            currentImages.distractor3.url = URL.createObjectURL(file);
            setImageMCFiles(currentImages);
        }
    }

    let handleImageChange = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            setStimulusImage(file)
            setPageImage(URL.createObjectURL(file))
        }
    }

    const handleClearImage = () => {
        setPageImage(null);
        setStimulusImage(null);
    }

    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.set("UPLOADCARE_PUB_KEY", process.env.REACT_APP_UPLOADCARE_PUB_KEY);
            formData.set("UPLOADCARE_STORE", 1);
            formData.append('file', file)
            const response = await fetch('https://upload.uploadcare.com/base/', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload image.');
            }
            const data = await response.json();
            return data.file; // Assuming the response contains the URL of the uploaded image
        } catch (error) {
            console.error(error);
            return null;
        }
    };


    const handleClearCorrect = () => {
        let currentImages = {...imageMCFiles};
        currentImages.correct.file = null;
        currentImages.correct.url = null;
        setImageMCFiles(currentImages);
    }

    const handleClearDistractor1 = () => {
        let currentImages = {...imageMCFiles};
        currentImages.distractor1.file = null;
        currentImages.distractor1.url = null;
        setImageMCFiles(currentImages);
    }

    const handleClearDistractor2 = () => {
        let currentImages = {...imageMCFiles};
        currentImages.distractor2.file = null;
        currentImages.distractor2.url = null;
        setImageMCFiles(currentImages);
    }

    const handleClearDistractor3 = () => {
        let currentImages = {...imageMCFiles};
        currentImages.distractor3.file = null;
        currentImages.distractor3.url = null;
        setImageMCFiles(currentImages);
    }

    const handleQuestionTextChange = (content, delta, source, editor) => {
        setQuestionTextContent(content);
    }

    const newQuillRef = useRef(null);

    return (
        <>
            <div className={"bg-burnt_sienna-300 p-4 text-black cursor-black rounded-b rounded-tr overflow-y-auto"}>
                <div className={"bg-dutch_white-300 text-lg p-4 rounded shadow mb-2"}>{createItemModal.text}</div>
                <div className={'flex'}>
                    <div className={'w-[70%]'}>
                        <TipTap setHtmlOutput={setQuestionTextContent} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Question...'} />

                        {/*<Editor id={'prompt'}*/}
                        {/*        ref={newQuillRef}*/}
                        {/*        readOnly={false}*/}
                        {/*        placeholder={'Question:'}*/}
                        {/*        backgroundColor={'#faf7ef'}*/}
                        {/*        onTextChange={handleQuestionTextChange}/>*/}
                    </div>
                    <div className={'w-[30%]'}>
                        <input type={"file"} ref={hiddenFileInput} accept="image/jpeg, image/png, image/jpg"
                               className={"hidden"} onChange={handleImageChange}/>
                        {(pageImage) ? <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"} onClick={() => hiddenFileInput.current.click()}>Change Image</button> : <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none mx-auto"} onClick={() => hiddenFileInput.current.click()}>Add an Image</button> }
                        {(pageImage) && <button onClick={handleClearImage} className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove Image</button>}
                    </div>

                </div>
                {(pageImage) && <div className={'p-2 bg-dutch_white-300 border-2 border-white w-fit mx-auto rounded mt-2'}><p className={'font-bold'}>Image Preview</p><img className={'h-40 mx-auto rounded-xl'} draggable={"false"} src={pageImage}/></div>}

                <div className={"my-2 gap-2 grid grid-cols-2"}>
                    <div className={'bg-columbia_blue-300 rounded p-2 border-2 border-columbia_blue-700'}>
                        <p className={'font-bold text-columbia_blue-800'}>Correct Response</p>
                        <input type={"file"} ref={hiddenCorrectInput} accept="image/jpeg, image/png, image/jpg" className={"hidden"} onChange={handleCorrectImage}/>
                        {(imageMCFiles.correct.url) ?
                            <div className={'flex flex-col'}>
                                <div className={'flex gap-4 mx-auto'}>
                                    <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none w-fit"} onClick={() => hiddenCorrectInput.current.click()}>Change Image</button>
                                    <button onClick={handleClearCorrect} className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove Image</button>
                                </div>
                                <img className={'w-80 mx-auto rounded-xl'} draggable={"false"} src={imageMCFiles.correct.url}/>
                            </div> :
                            <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none w-fit mx-auto"} onClick={() => hiddenCorrectInput.current.click()}>Add an Image</button> }
                    </div>

                    <div className={'bg-burnt_sienna-200 border-2 border-burnt_sienna-600 rounded p-2'}>
                        <p className={'font-bold text-columbia_blue-800'}>Distractor 1</p>
                        <input type={"file"} ref={hiddenDistractor1Input} accept="image/jpeg, image/png, image/jpg" className={"hidden"} onChange={handleDistractor1Change}/>
                        {(imageMCFiles.distractor1.url) ?
                            <div className={'flex flex-col'}>
                                <div className={'flex gap-4 mx-auto'}>
                                    <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"} onClick={() => hiddenDistractor1Input.current.click()}>Change Image</button>
                                    <button onClick={handleClearDistractor1} className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove Image</button>
                                </div>
                                <img className={'w-80 mx-auto rounded-xl'} draggable={"false"} src={imageMCFiles.distractor1.url}/>
                            </div>
                            :
                            <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none w-fit mx-auto"} onClick={() => hiddenDistractor1Input.current.click()}>Add an Image</button> }
                    </div>

                    <div className={'bg-burnt_sienna-200 border-2 border-burnt_sienna-600 rounded p-2'}>
                        <p className={'font-bold text-columbia_blue-800'}>Distractor 2</p>
                        <input type={"file"} ref={hiddenDistractor2Input} accept="image/jpeg, image/png, image/jpg" className={"hidden"} onChange={handleDistractor2Change}/>
                        {(imageMCFiles.distractor2.url) ?
                            <div className={'flex flex-col'}>
                                <div className={'flex gap-4 mx-auto'}>
                                    <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"} onClick={() => hiddenDistractor2Input.current.click()}>Change Image</button>
                                    <button onClick={handleClearDistractor2} className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove Image</button>
                                </div>
                                <img className={'w-80 mx-auto rounded-xl'} draggable={"false"} src={imageMCFiles.distractor2.url}/></div> : <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none w-fit mx-auto"} onClick={() => hiddenDistractor2Input.current.click()}>Add an Image</button> }
                    </div>
                    <div className={'bg-burnt_sienna-200 border-2 border-burnt_sienna-600 rounded p-2'}>
                        <p className={'font-bold text-columbia_blue-800'}>Distractor 3</p>
                        <input type={"file"} ref={hiddenDistractor3Input} accept="image/jpeg, image/png, image/jpg" className={"hidden"} onChange={handleDistractor3Change}/>
                        {(imageMCFiles.distractor3.url) ? <div className={'flex flex-col'}>
                            <div className={'flex gap-4 mx-auto'}>
                                <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"} onClick={() => hiddenDistractor3Input.current.click()}>Change Image</button>
                                <button onClick={handleClearDistractor3} className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove Image</button>
                            </div>
                            <img className={'w-80 mx-auto rounded-xl'} draggable={"false"} src={imageMCFiles.distractor3.url}/></div> : <button className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none w-fit mx-auto"} onClick={() => hiddenDistractor3Input.current.click()}>Add an Image</button> }
                    </div>
                </div>
            </div>
            <button onClick={publishImageMCItem} className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-columbia_blue-700 text-white font-bold px-10 text-xl hover:bg-columbia_blue-600">
                <p>Publish Item</p>
            </button>
        </>
    );
}

export default ImageMcInput;