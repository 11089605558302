import React, {useState} from 'react';
import Papa from 'papaparse';
import postWithAuth from "../../context/postWithAuth";

function AddStudentModal({setBulkStudentModal, preps, school}) {
    const [isLoading, setIsLoading] = useState(null);
    const [activePreps, setActivePreps] = useState([]);
    const [csvData, setCsvData] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Define the expected headers
            const expectedHeaders = ['First Name', 'Last Name', 'Grade', 'Email', 'Student Password'];

            // Use Papa Parse to parse the CSV file
            Papa.parse(file, {
                header: true, // Treat the first row as headers
                complete: (result) => {
                    try {
                        // Get the actual headers from the parsed result
                        const actualHeaders = result.meta.fields;

                        // Check if all expected headers are present
                        const missingHeaders = expectedHeaders.filter(header => !actualHeaders.includes(header));

                        if (missingHeaders.length > 0) {
                            throw new Error(`Missing headers: ${missingHeaders.join(', ')}`);
                        }

                        // Update the state with the parsed data
                        let dataArr = result.data.map(item => ({
                            firstName: item['First Name'],
                            lastName: item['Last Name'],
                            grade: item['Grade'],
                            email: item['Email'],
                            password: item['Student Password']
                        }));

                        setCsvData(dataArr);
                    } catch (error) {
                        window.alert(`There was an error processing your file. ${error.message}`);
                        // Clear the input file on error
                        document.getElementById('csvFileInput').value = '';
                    }
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error.message);
                    // Clear the input file on error
                    document.getElementById('csvFileInput').value = '';
                },
            });
        }


    };

    const onClose = () => {
        if (window.confirm('Are you sure? All progress will be lost')) {
            setBulkStudentModal(null)
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleCourseAdd = (e) => {
        setActivePreps([...activePreps, e.target.id])
    }

    const handleCourseRemove = (e) => {
        setActivePreps(activePreps.filter(item => item !== e.target.id))
    }

    const postCreateStudents = async () => {
        if (window.confirm('Are you sure? Student account data cannot be modified once added to the user database.')) {
            let body = {studentData: csvData, preps: [], school: school}
            setIsLoading(true);
            postWithAuth('/accountmanagement/postBulkUploadStudents', school,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body})
                .then((response) => {
                    if (response.ok) {
                        window.alert('Students successfully added to database. Teachers can now add them to rosters.')
                        setIsLoading(false)
                        setBulkStudentModal(null)
                    } else {
                        console.log(response.error)
                        setIsLoading(false);
                    }
                })
        } else {
            return null;
        }
    }


    return (
        <>
            <div>
                <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 m-4 rounded p-2 h-[90%]"}>
                        <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>
                        <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                            <p className={'w-[70%] text-dutch_white-300 m-2 mx-auto'}>Use the link below to download a template file. Using your student information platform, download a csv report of the necessary information, paste it into the template file, then reupload with the dialogue below. Select the applicable courses for the group of students to be uploaded, then create student accounts.</p>
                            <div className={'flex gap-4 mx-auto rounded'}>
                                <div className={'p-2 bg-columbia_blue-300 rounded hover:bg-columbia_blue-500 font-bold border-2 border-dutch_white-400 cursor-pointer'}>
                                    <a href={'https://ucarecdn.com/02140e6e-a35a-4cfe-878c-d4ad8044bb1b/StudentImportTemplate.csv'} download={'https://ucarecdn.com/02140e6e-a35a-4cfe-878c-d4ad8044bb1b/StudentImportTemplate.csv'}>Download Template File</a>
                                </div>
                                <input id='csvFileInput' className={'rounded bg-dutch_white p-2'} type={'file'} onChange={handleFileChange}/>

                            </div>
                            {(csvData) &&
                                <div className={'grid grid-cols-3 gap-0.5 my-4 max-h-[400px] bg-dutch_white overflow-y-auto'}>
                                    <p className={'bg-columbia_blue-500 font-bold border-b-4 border-columbia_blue-800'}>Last Name</p>
                                    <p className={'bg-columbia_blue-500 font-bold border-b-4 border-columbia_blue-800'}>First Name</p>
                                    <p className={'bg-columbia_blue-500 font-bold border-b-4 border-columbia_blue-800'}>Email</p>
                                    {csvData.map(student => (<><p className={'bg-dutch_white-200'}>{student.lastName}</p><p className={'bg-dutch_white-200'}>{student.firstName}</p><p className={'bg-dutch_white-200'}>{student.email}</p></>))}
                                </div>}
                            {/*<div className={'grid grid-cols-2 gap-2 mb-4'}>*/}
                            {/*    <div className={'flex-col gap-2'}>*/}
                            {/*        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Courses Offered:</p>*/}
                            {/*        <div className={'grid grid-cols-2 gap-2'}>*/}
                            {/*            {preps.filter(item => (!activePreps.includes(item))).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map(item => <div id={item}*/}
                            {/*                                                                                                                                                 onClick={handleCourseAdd}*/}
                            {/*                                                                                                                                                 className={'rounded text-dutch_white-200 font-bold text-lg bg-columbia_blue-700 hover:bg-columbia_blue-600 cursor-pointer select-none px-2'}>{item}</div>)}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'flex-col gap-2'}>*/}
                            {/*        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Selected*/}
                            {/*            Courses:</p>*/}
                            {/*        {(activePreps) && activePreps.map(item => <div id={item}*/}
                            {/*                                                       onClick={handleCourseRemove}*/}
                            {/*                                                       className={'rounded text-dutch_white-200 font-bold text-lg bg-burnt_sienna-700 hover:bg-burnt_sienna-600 cursor-pointer select-none mb-2 px-2'}>{item}</div>)}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {(csvData && school) ?
                                <button onClick={postCreateStudents}
                                        className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-burnt_sienna text-white font-bold px-10 text-xl hover:bg-burnt_sienna-600 cursor-pointer">
                                    <p>Create Student Accounts</p>
                                </button>
                                :
                                <div
                                    className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-platinum-500 text-white font-bold px-10 text-xl">
                                    <p>Create Student Accounts</p>
                                </div>}
                        </div>
                    </div>
                </div>

            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </>
    );
}

export default AddStudentModal;