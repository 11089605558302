import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

const TraditionalMCScrambledPreview = ({ assessmentItem }) => {
    const sanitizeContent = (content) => DOMPurify.sanitize(content);

    // Shuffle responses function
    const shuffleArray = (array) => array.sort(() => 0.5 - Math.random());

    const [shuffledResponses, setShuffledResponses] = useState([]);

    useEffect(() => {
        // Sanitize and shuffle responses on initial mount
        const responses = [
            sanitizeContent(assessmentItem.correct),
            sanitizeContent(assessmentItem.distractor1),
            sanitizeContent(assessmentItem.distractor2),
            sanitizeContent(assessmentItem.distractor3),
        ];
        setShuffledResponses(shuffleArray(responses));
    }, [assessmentItem]);

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={'w-full bg-dutch_white-100 p-1 rounded'}>
            <div className={'flex flex-col p-1 bg-dutch_white-200 my-2'}>
                {Object.keys(assessmentItem).includes('imageURL') && (
                    <img
                        className={`max-h-[100px] rounded mx-auto`}
                        src={assessmentItem.imageURL}
                        alt="Assessment"
                    />
                )}
                <div className={'flex flex-col gap-2 bg-paynes_gray-300 p-1 rounded w-full'}>
                    <div className={'bg-platinum rounded p-1 border-2 border-columbia_blue'}>
                        <div dangerouslySetInnerHTML={renderHtml(sanitizeContent(assessmentItem.prompt))} />
                    </div>
                    {shuffledResponses.map((item, index) => (
                        <div key={index} className={'bg-dutch_white-200 text-sm rounded p-1 border-2 border-opacity-50 border-dutch_white-700'}>
                            <div dangerouslySetInnerHTML={renderHtml(item)} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TraditionalMCScrambledPreview;
