import React from 'react';

function StandardSelectionBar({standard, standardCode, selectedStandard, setSelectedStandard, knowShow, handleStandardClick}) {

    const order = ['Recall', 'Know', 'Show']

    return (
        <>
            {(selectedStandard && selectedStandard.standardCode === standardCode) ?

                <button
                    className={'flex gap-1 pl-8 p-6 w-[80%] border-2 border-columbia_blue-800 my-2 bg-dutch_white-100 rounded border-opacity-60 mx-auto shadow'}>
                    <div className={'flex flex-col mb-1 w-full'}>
                        <p className={'truncate text-left font-bold text-xl ml-2 mb-2 text-columbia_blue-800'}>{standard.code}: {standard.title}</p>
                        <div className={'rounded bg-columbia_blue-300 mb-2 w-[80%] mx-auto shadow'}>
                            <p className={'text-left ml-4 text-sm my-2'}>{standard.text}</p>

                        </div>
                    </div>
                    <div className={'grow'}/>
                    <div className={'grid grid-cols-4 gap-2 w-fit h-fit'}>
                        {knowShow.filter(item => (item.type !== "Vocab" && item.active)).sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map(element => (element.type === "Recall") ?
                            <div key={element._id.$oid}
                                 className={"bg-columbia_blue-600 rounded border-2 border-opacity-50 border-slate-700 p-2"}/> : (element.type === 'Know') ?
                                <div key={element._id.$oid}
                                     className={"bg-dutch_white-600 rounded border-2 border-opacity-50 border-slate-700 p-2"}/> :
                                <div key={element._id.$oid}
                                     className={"bg-paynes_gray-500 rounded border-2 border-opacity-50 border-slate-700 p-2"}/>)}
                    </div>
                </button>
                : (standard.classification !== 'base') ?
                    <button className={'flex gap-1 flex-col p-6 w-[76%] border-2 border-columbia_blue-800 my-2 bg-columbia_blue-300 hover:bg-columbia_blue-400 rounded border-opacity-30 mx-auto cursor-pointer shadow'} onClick={() => {handleStandardClick(standard)}}>
                        <div className={'flex w-full'}>
                                <div className={'pb-1 truncate font-bold text-xl ml-2 text-columbia_blue-800'}>{standard.code}: {standard.title}</div>
                                <div className={'grow'}/>
                                <div className={'text-xs text-right text-columbia_blue-800'}>{standard.classification}</div>
                        </div>
                        <div className={'flex w-[90%] ml-auto'}>
                            <div className={'rounded w-[80%] mx-auto shadow bg-columbia_blue-100 mb-2'}>
                                <p className={'text-left ml-4 text-sm my-2'}>{standard.text}</p>
                            </div>
                            <div className={'grow'} />
                            <div className={'grid grid-cols-4 gap-2 w-fit h-fit'}>
                                {knowShow.filter(item => (item.type !== "Vocab" && item.active)).sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map(element => (element.type === "Recall") ?
                                    <div key={element._id.$oid}
                                         className={"bg-columbia_blue-600 rounded border-2 border-opacity-50 border-slate-700 p-2 min-h-max"}/> : (element.type === 'Know') ?
                                        <div key={element._id.$oid}
                                             className={"bg-dutch_white-600 rounded border-2 border-opacity-50 border-slate-700 p-2 min-h-max"}/> :
                                        <div key={element._id.$oid}
                                             className={"bg-paynes_gray-500 rounded border-2 border-opacity-50 border-slate-700 p-2 min-h-max"}/>)}
                            </div>
                        </div>

                    </button>
                    :
                    <button
                        className={'flex p-6 w-[80%] border-2 border-columbia_blue-800 my-2 bg-dutch_white-200 hover:bg-dutch_white-300 rounded border-opacity-30 mx-auto cursor-pointer shadow'}
                        onClick={() => {
                            handleStandardClick(standard)
                        }}>
                        <div className={'flex flex-col mb-1 w-[80%]'}>
                            <p className={'truncate text-left font-bold text-2xl ml-2 mb-2 text-columbia_blue-800'}>{standard.code}: {standard.title}</p>
                            <div className={'rounded bg-columbia_blue-400 p-2 mb-2 w-[80%] mx-auto shadow'}>
                                <p className={'text-left ml-4 my-2'}>{standard.text}</p>

                            </div>
                        </div>
                        <div className={'grow'} />
                        <div className={'grid grid-cols-4 gap-2 w-fit h-fit'}>
                            {knowShow.filter(item => (item.type !== "Vocab" && item.active)).sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map(element => (element.type === "Recall") ?
                                <div key={element._id.$oid}
                                     className={"bg-columbia_blue-600 rounded border-2 border-opacity-50 border-slate-700 p-2"}/> : (element.type === 'Know') ?
                                    <div key={element._id.$oid}
                                         className={"bg-dutch_white-600 rounded border-2 border-opacity-50 border-slate-700 p-2"}/> :
                                    <div key={element._id.$oid}
                                         className={"bg-paynes_gray-500 rounded border-2 border-opacity-50 border-slate-700 p-2"}/>)}
                        </div>
                    </button>
            }

        </>
    );
}

export default StandardSelectionBar;