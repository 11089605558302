import React from 'react';
import {useNavigate} from "react-router-dom";

function AcknowledgmentsPage() {

    const navigate = useNavigate()
    return (
        <div className={'bg-columbia_blue-800 h-screen pt-2'}>
            <button className={"px-4"} onClick={() => navigate('/')} title={'Home Button'}>
                <img className={'h-[70px] cursor-pointer mx-auto pb-2'}
                     src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'}
                      alt={'Brainmeter'}/>
            </button>
            <div className={'text-left text-columbia_blue-800 w-[50%]  bg-dutch_white mx-auto p-4 rounded'}>
                <h1 className={'text-3xl font-bold text-center mb-2'}>Acknowledgments</h1>
                <p className={'mb-4'}>
                    Brainmeter, in its design, derives tremendous value from the use of existing educational research
                    and
                    development. Our standard element system’s dichotomy (Recall, Understand, and Apply) is based on the
                    transformational work of Bloom’s Taxonomy (and its revision by Anderson and Krathwohl).
                </p>
                <p className={'mb-4'}>
                    Templated standard sets are entirely based on the work of national initiatives for the
                    standardization
                    of public education. These sets allow for a unified approach to the instruction of each content area
                    and
                    form the foundation of Brainmeter’s Planning page.
                </p>
                <p className={'mb-2'}>Citations for these works are provided below:</p>
                <p className={'text-xs my-2'}>
                    Anderson, L. W., & Krathwohl, D. R. (2001). <em>A Taxonomy for Learning, Teaching, and Assessing: A
                    Revision of Bloom's Taxonomy of Educational Objectives</em>. New York: Longman.
                </p>
                <p className={'text-xs my-2'}>
                    National Governors Association Center for Best Practices, Council of Chief State School Officers.
                    (2010). <em>Common Core State Standards</em>. Washington D.C.
                </p>
                <p className={'text-xs my-2'}>
                    NGSS Lead States. (2013). <em>Next Generation Science Standards: For States, By States</em>.
                    Washington, DC: The National Academies Press.
                </p>
                <p className={'text-xs my-2'}>
                    Colorado Department of Education. (2018). <em>Colorado Academic Standards</em>. Retrieved from <a
                    href="https://www.cde.state.co.us/standardsandinstruction">https://www.cde.state.co.us/standardsandinstruction</a>.
                </p>
            </div>
        </div>
    );
}

export default AcknowledgmentsPage;