import React, {useEffect, useRef, useState} from 'react';
import TraditionalMultipleChoiceEntry from "./traditionalMultipleChoiceEntry";
import ImageMultipleChoiceEntry from "./imageMultipleChoiceEntry";
import TraditionalMultipleChoiceResultView from "./traditionalMultipleChoiceResultView";
import ImageMultipleChoiceResultView from "./imageMultipleChoiceResultView";
import MatchingEntry from "./matchingEntry";
import MatchingResultView from "./matchingResultView";
import RecallEntry from "./recallEntry";
import SortingEntry from "./sortingEntry";
import SortingResultView from "./sortingResultView";
import postWithAuth from "../../context/postWithAuth";
import RecallResultView from "./recallResultView";

function QuestionModal({ questionModalContent, setQuestionModalContent, fetchStudentData, fetchOutstandingAssessments, user}) {
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [answerView, setAnswerView] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const modalRef = useRef(null);

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setQuestionModalContent]);


    const onClose = (event) => {
        if (!answerView && selectedAnswer && window.confirm("Are you sure? Closing will clear any selected answers.")) {
            setQuestionModalContent(null)
        } else if (answerView || !selectedAnswer) {
            setQuestionModalContent(null)
        } else {
            return null
        }
    }

    const handleSubmitAnswer = () => {
        if (!selectedAnswer) {
            window.alert("Pick an answer first!")
        } else {
            setIsLoading(true);
            postWithAuth('/student/postStudentResponse', user.userAttributes['custom:school'],
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": 'application/json'
                    },
                    body:
                        {
                            selectedAnswer: selectedAnswer,
                            questionContent: questionModalContent,
                            user: user,
                            studentId: user.userContext._id.$oid,
                            questionId: (typeof questionModalContent._id === 'string') ? questionModalContent._id : questionModalContent._id.$oid
                        }
                })
                .then(response => {
                    if (response.ok) {
                        fetchStudentData(user);
                        fetchOutstandingAssessments(user);
                        return response.json()
                    } else {
                        console.log('error')
                    }})
                .then(data => {
                    setIsLoading(false);
                    setAnswerView(data);
                })
        }
    }
    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    if (!answerView) {
        return (
            <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper" onClick={handleClose}>
                <div id={'question_modal_overlay'} role="dialog" aria-labelledby="Question Modal" aria-describedby="Assessment Item Window" aria-modal='true' ref={modalRef} tabIndex={0} className={"relative bg-dutch_white-200 m-4 rounded select-none p-2 max-h-[92%] min-w-[70%] max-w-[95%]"}>
                    <button id={'modal_close'} aria-label={'Close'} onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-300 z-10">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div id={'question'} aria-label={'Assessment Item Container'} className={"relative text-black mb-2 cursor-black max-h-[100%] overflow-y-auto focus:text-red-500"}>
                        {(questionModalContent.type === 'Traditional MC') && <TraditionalMultipleChoiceEntry questionModalContent={questionModalContent} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} />}
                        {(questionModalContent.type === 'Image MC') && <ImageMultipleChoiceEntry questionModalContent={questionModalContent} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} />}
                        {(questionModalContent.type === 'Matching') && <MatchingEntry questionModalContent={questionModalContent} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} />}
                        {(questionModalContent.type === 'Recall') && <RecallEntry questionModalContent={questionModalContent} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} />}
                        {(questionModalContent.type === 'Sorting') && <SortingEntry questionModalContent={questionModalContent} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} />}


                    </div>
                    {(selectedAnswer) ?
                        <button onClick={handleSubmitAnswer} aria-label={'Submit Selected Answer'} className="absolute rounded -bottom-6 right-[10%] left-[10%] m-2 p-2 bg-burnt_sienna-500 text-dutch_white-100 font-bold px-10 text-xl hover:bg-burnt_sienna-600 border-burnt_sienna-800 border-opacity-50 border-2">
                            <p>Submit Answer</p>
                        </button>
                        :
                        <button aria-label={'Inactive Submit Answer Button: Select an answer to submit'} className="absolute rounded -bottom-6 m-2 p-2 right-[10%] left-[10%]  bg-paynes_gray-500 text-white font-bold px-10 text-xl cursor-default">
                            <p>Submit Answer</p>
                        </button>
                    }
                    {isLoading && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50" aria-label={'Awaiting Response'}>
                            {/* Loader */}
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper">
                <div className={"relative max-h-[90%] max-w-[95%] bg-dutch_white-200 p-2 rounded select-none min-h-min"}>
                    {(answerView) ?
                        (questionModalContent.type === 'Traditional MC') ?
                            <TraditionalMultipleChoiceResultView answerView={answerView} questionModalContent={questionModalContent} />
                            : (questionModalContent.type === 'Image MC') ?
                            <ImageMultipleChoiceResultView answerView={answerView} questionModalContent={questionModalContent} />
                            : (questionModalContent.type === 'Matching')  ?
                            <MatchingResultView answerView={answerView} questionModalContent={questionModalContent}/>
                            : (questionModalContent.type === 'Recall') ?
                            <RecallResultView answerView={answerView} questionModalContent={questionModalContent} />
                            : (questionModalContent.type === 'Sorting') ?
                            <SortingResultView answerView={answerView} questionModalContent={questionModalContent} />
                            : <></>
                        : <></>
                    }
                    <button aria-label={'Ok'} onClick={() => setQuestionModalContent(null)} className="absolute rounded -bottom-6 left-1/4 m-2 p-2 bg-paynes_gray-500  text-white font-bold px-[20%] mx-auto text-xl hover:bg-paynes_gray-600">
                        <p>Ok</p>
                    </button>
                </div>
            </div>
        )
    }

}

export default QuestionModal;