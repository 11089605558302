import React from 'react';
import DOMPurify from "dompurify";

function SortingResultView({questionModalContent, answerView}) {
    let questionHtml = DOMPurify.sanitize(questionModalContent.prompt)

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return {__html: sanitizedHtml};
    };

    const colors = ['#a976da', '#5456c2', '#6b5d26', '#4ba14d']


    return (<>
        {answerView.result === 0 ?
            <div className={"bg-columbia_blue-800 max-h-full overflow-y-auto p-2 rounded"}>
                <p className={'text-dutch_white-300 font-bold text-2xl'}>Correct!</p>
                <p className={'text-dutch_white-300 mb-2'}>Nice! You've mastered this one!</p>
                <div className={"bg-columbia_blue-500 p-4 rounded"}>

                    <div className={"bg-dutch_white-300 text-lg p-4  border-dutch_white-600 border-2 rounded"}>
                        <div dangerouslySetInnerHTML={renderHtml(questionHtml)}/>
                    </div>
                    {questionModalContent.imageURL &&
                        <img src={questionModalContent.imageURL} className={"rounded max-w-80 max-h-80 mx-auto mb-2 shadow"}/>}
                    <div className={'flex gap-2 w-full p-2 overflow-x-auto'}>
                        <div className={'grow'}/>
                        {answerView.sortingOutcome.map((item, index) =>
                            <div className={'bg-paynes_gray-600  rounded shadow my-2 px-4 flex flex-col py-2'}>
                                <div
                                    className={"bg-columbia_blue-700 w-[200px] text-dutch_white-100 p-2 border-gray-500 border-1 rounded-t text-xl h-fit mx-auto"}>
                                    <div dangerouslySetInnerHTML={renderHtml(item.title)}/>
                                </div>
                                <div className={'flex flex-col'}>
                                    <div
                                        className={'flex flex-wrap gap-2 w-[200px] shadow-inner bg-dutch_white-200 border-2 border-dutch_white-200 rounded-b min-h-[50px] max-h-[250px] overflow-y-auto'}>
                                        {item.correct.map(entry => entry.type === 'text' ? <div
                                                    className={"bg-dutch_white-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer ml-2"}>
                                                    <div className={'text-black'}
                                                         dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                                </div>
                                                : <div
                                                    className={"bg-dutch_white-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer ml-2"}>
                                                    <img draggable={false} src={entry.content}
                                                         className={"rounded max-h-[100px]"}/>
                                                </div>
                                        )}
                                        {item.missing.map(entry => entry.type === 'text' ? <div
                                                    className={"bg-paynes_gray-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer ml-2"}>
                                                    <div className={'text-black'}
                                                         dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                                </div>
                                                : <div
                                                    className={"bg-paynes_gray-300-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer ml-2"}>
                                                    <img draggable={false} src={entry.content}
                                                         className={"rounded max-h-[100px]"}/>
                                                </div>
                                        )}
                                    </div>
                                    <div
                                        className={'flex flex-wrap gap-2 w-[200px] shadow-inner bg-dutch_white-200 border-2 border-dutch_white-200 rounded-b min-h-[50px] max-h-[250px] overflow-y-auto'}>
                                        {item.incorrect.map(entry => entry.type === 'text' ? <div
                                                    className={"bg-burnt_sienna-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-burnt_sienna-600 border-2 cursor-pointer ml-2"}>
                                                    <div className={'text-black'}
                                                         dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                                </div>
                                                : <div
                                                    className={"bg-burnt_sienna-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-burnt_sienna-600 border-2 cursor-pointer ml-2"}>
                                                    <img draggable={false} src={entry.content}
                                                         className={"rounded max-h-[100px]"}/>
                                                </div>
                                        )}
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
            :
            <div className={"bg-burnt_sienna-600 max-h-full overflow-y-auto p-2 rounded"}>
                <p className={'text-dutch_white-200 font-bold text-2xl'}>Incorrect</p>
                <p className={'mb-2 text-dutch_white-200'}>You've got some learning left to do: review the
                    differences below for next time!</p>
                <div className={"bg-columbia_blue-500 p-2 rounded"}>

                    <div className={"bg-dutch_white-300 text-lg p-4 border-dutch_white-600 border-2 rounded shadow"}>

                        <div dangerouslySetInnerHTML={renderHtml(questionHtml)}/>
                        {questionModalContent.imageURL && <img src={questionModalContent.imageURL}
                                                               className={"rounded max-w-[300px] max-h-[300px] mx-auto mb-2 shadow"}/>}
                        <div
                            className={"bg-burnt_sienna-300 p-2 border-dutch_white-300 border-2 mt-1 flex flex-col rounded gap-2"}>
                            <p className={'text-dutch_white-900'}>Your Response:</p>
                            <div className={'flex gap-2 w-full overflow-x-auto'}>
                                <div className={'grow'}/>
                                {answerView.sortingOutcome.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) =>
                                    <div className={'rounded shadow my-2 px-4 flex flex-col py-2'}
                                         style={{background: colors[index]}}>
                                        <div
                                            className={"bg-dutch_white-500 border-b-2 border-dutch_white-600 shadow w-[200px] font-bold text-dutch_white-900 p-2 border-1 rounded-t text-h-fit mx-auto"}>
                                            <div dangerouslySetInnerHTML={renderHtml(item.title)}/>
                                        </div>
                                        <div className={'flex flex-col'}>
                                            <div
                                                className={'flex flex-wrap gap-1 w-[200px] shadow-inner bg-dutch_white-100 rounded-b min-h-[50px] max-h-[250px] overflow-y-auto px-2'}>
                                                {item.correct.map(entry => entry.type === 'text' ? <div
                                                            className={"p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-100 border-2"}
                                                            style={{background: colors[index]}}>
                                                            <div className={'text-dutch_white-200 text-xs'}
                                                                 dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                                        </div>
                                                        : <div
                                                            className={"p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-200 border-2"}
                                                            style={{background: colors[index]}}>
                                                            <img draggable={false} src={entry.content}
                                                                 className={"rounded max-h-[100px]"}/>
                                                        </div>
                                                )}
                                                {item.incorrect.map(entry => entry.type === 'text' ? <div
                                                            className={"p-2 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-200 border-2"}
                                                            style={{background: colors[answerView.sortingOutcome.indexOf(answerView.sortingOutcome.find(item => item.missing.map(item => item.content).includes(entry.content)))]}}>
                                                            <div className={'text-dutch_white-200 text-xs'}
                                                                 dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                                        </div>
                                                        : <div
                                                            className={"p-2 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-200 border-2"}
                                                            style={{background: colors[answerView.sortingOutcome.indexOf(answerView.sortingOutcome.find(item => item.missing.map(item => item.content).includes(entry.content)))]}}>
                                                            <img draggable={false} src={entry.content}
                                                                 className={"rounded max-h-[100px]"}/>
                                                        </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className={'grow'}/>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        }


    </>);
}


export default SortingResultView;