import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import postWithAuth from "../../context/postWithAuth";
import TipTap from "../../components/tipTap";
import MatchTipTap from "../../components/matchTipTap";

function MatchingInput({user, createItemModal, setCreateItemModal, setIsLoading, assessmentItems, setAssessmentItems}) {
    const [matchingSets, setMatchingSets] = useState([{promptType: null, prompt: '', promptUrl: null, promptFile: null, matchType: null, match: '', matchUrl: null, matchFile: null}])
    const [prompt, setPrompt] = useState(null);
    const [previewUrls, setPreviewUrls] = useState([]);
    const hiddenFileInput = React.useRef(null);
    let [stimulusImage, setStimulusImage] = useState(null)
    let [pageImage, setPageImage] = useState(null)

    const publishMatchedPairs = async () => {
        if (!matchingSets || matchingSets.length < 2) {
            window.alert("You must add at least two matched pairs to submit.");
            return;
        }

        if (matchingSets.flatMap(item => item.matchType).includes(null) || matchingSets.flatMap(item => item.prompt).includes('')) {
            window.alert("Please clear any incomplete match pairs before submitting.")
            return;
        }

        if (!prompt) {
            window.alert("Your assessment item must start with an overall prompt.");
            return;
        }

        setIsLoading(true)

        // Array to store updated matched pairs with image URLs
        const updatedMatchedPairs = [];
        let promptImageUrl = ''

        if (stimulusImage) {
            let response = await uploadImage(stimulusImage);
            promptImageUrl = `https://ucarecdn.com/${response}/`
        }

        // Iterate over each matched pair
        Promise.all(
            matchingSets.map(async (pair, index) => {
                // Check prompt type
                if (pair.promptType === "image") {
                    // Upload prompt image
                    const promptUrl = await uploadImage(pair.promptFile);
                    pair.prompt = `https://ucarecdn.com/${promptUrl}/`;
                }

                // Check match type
                if (pair.matchType === "image") {
                    // Upload match image
                    const matchUrl = await uploadImage(pair.matchFile);
                    pair.match = `https://ucarecdn.com/${matchUrl}/`;
                }

                // Add updated pair to the array
                updatedMatchedPairs.push(pair);
            })
        )
            .then(() => {
                // After all images are uploaded and URLs are updated
                // Send the updated matched pairs to your API
                postWithAuth('/planning/postMatchingItem', user.userAttributes["custom:school"], {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        knowShowId: createItemModal._id.$oid,
                        knowShowText: createItemModal.text,
                        standard: createItemModal.standard,
                        prompt: prompt,
                        imageURL: promptImageUrl,
                        matchSets: updatedMatchedPairs.map(item => ({
                            prompt: {
                                type: item.promptType,
                                content: item.prompt
                            }, match: {type: item.matchType, content: item.match}
                        })),
                        school: user.userAttributes['custom:school'],
                        prep: createItemModal.prep
                    }
                })
                    .then(async (response) => {
                        if (!response.ok) {
                            throw new Error('Failed to make the second request.');
                        } else {
                            return response.json();
                        }
                    })
                    .then(async data => {
                        await setAssessmentItems(prev => [...prev, data]);
                        setIsLoading(false);
                        setCreateItemModal(null);
                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false)
                        // Handle error
                    });
            });
    };

// Function to upload image to Uploadcare API and return URL
    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.set("UPLOADCARE_PUB_KEY", process.env.REACT_APP_UPLOADCARE_PUB_KEY);
            formData.set("UPLOADCARE_STORE", 1);
            formData.append('file', file)
            const response = await fetch('https://upload.uploadcare.com/base/', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload image.');
            }
            const data = await response.json();
            return data.file; // Assuming the response contains the URL of the uploaded image
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    let handleImageChange = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return null;
        } else if (file.size > 5000000) {
            alert("Your file is too large! Downscale or select a different file. Maximum 5mb")
        } else {
            setStimulusImage(file)
            setPageImage(URL.createObjectURL(file))
        }
    }

    const handleClearImage = () => {
        setPageImage(null);
        setStimulusImage(null);
    }

    const handlePaste = (e) => {
        // Prevent the default paste behavior
        e.preventDefault();

        // Get the pasted text
        const text = e.clipboardData.getData('text/plain');

        // Insert the text into the editor
        const quill = quillRef.current.getEditor();
        quill.clipboard.dangerouslyPasteHTML(text);
    };

    const quillRef = React.createRef()

    const handleAddPair = () => {
        setMatchingSets([...matchingSets, {promptType: null, prompt: '', promptUrl: null, promptFile: null, matchType: null, match: '', matchUrl: null, matchFile: null}])
    }

    const handleSelectPromptType = (item, index, promptType) => {
        let tempMatchingSets = [...matchingSets]
        tempMatchingSets[index].promptType = promptType
        setMatchingSets(tempMatchingSets)
    }

    const handleSelectMatchType = (item, index, matchType) => {
        let tempMatchingSets = [...matchingSets]
        tempMatchingSets[index].matchType = matchType
        setMatchingSets(tempMatchingSets)
    }

    const handleEditorPromptChange = (content, index) => {
        matchingSets[index].prompt = content;
    };

    const handleEditorMatchChange = (content, index) => {
        matchingSets[index].match = content;
    };

    const handleOverallPromptChange = (content, delta, source, editor) => {
        setPrompt(content);
    }

    const handleAddImage = (index, category) => {
        // Create a new file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg, image/png, image/jpg';
        fileInput.style.display = 'none';
        fileInput.onchange = (event) => handleFileInputChange(event, index, category);

        // Trigger click on the new file input
        fileInput.click();
    };

    const handleFileInputChange = (event, index, category) => {
        const file = event.target.files[0];
        if (file.size > 5000000) {
            alert('Your file is too large. 5mb maximum image size.')
            return null;
        }
        const reader = new FileReader();
        reader.onload = () => {
            const previewUrl = URL.createObjectURL(file);
            let tempMatches = [...matchingSets]
            tempMatches[index][category + 'Type'] = 'image'
            tempMatches[index][category + 'File'] = file
            tempMatches[index][category + 'Url'] = previewUrl
            setMatchingSets(tempMatches)
        };
        reader.readAsDataURL(file);
    };

    const handleRemovePair = (index) => {
        setMatchingSets(matchingSets.filter((item, position) => position !== index))
    }

    return (
        <>
            <div className={"bg-dutch_white-500 p-4 text-black cursor-black rounded-b rounded-tr max-h-full overflow-auto border-8 border-dutch_white-500"}>
                <div className={"bg-dutch_white-300 text-lg p-4 rounded shadow"}>{createItemModal.text}</div>
                <div className={'flex'}>
                    <div className={'w-1/2 mt-1 flex flex-col p-1 h-fit'}>
                        <TipTap setHtmlOutput={setPrompt} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Overall Matching Prompt...'} />

                        {/*<ReactQuill ref={quillRef} onPaste={handlePaste}*/}
                        {/*            modules={{toolbar: [['bold', 'italic', 'underline'], [{script: 'sub'}, {script: 'super'}]]}}*/}
                        {/*            id={"overallPrompt"}*/}
                        {/*            onChange={(content, delta, source, editor) => handleOverallPromptChange(content, delta, source, editor)}*/}
                        {/*            placeholder={"Overall Matching Prompt:"} className={'bg-dutch_white-200 shadow my-auto'}/>*/}
                    </div>
                    <div className={"flex flex-col w-1/2 p-1"}>
                        <div className={'flex'}>
                            <div className={'grow'}/>
                            <input type={"file"} ref={hiddenFileInput} accept="image/jpeg, image/png, image/jpg"
                                   className={"hidden"} onChange={handleImageChange}/>
                            {(pageImage) ? <button
                                className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}
                                onClick={() => hiddenFileInput.current.click()}>Change Image</button> : <button
                                className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}
                                onClick={() => hiddenFileInput.current.click()}>Add Prompt Image (optional)</button>}
                            <div className={'grow'}/>

                            {(pageImage) && <>
                            <button onClick={handleClearImage}
                                 className={"bg-columbia_blue-700 font-bold text-lg text-white p-2 my-2 rounded hover:bg-columbia_blue-800 cursor-pointer select-none"}>Remove
                                Image
                            </button>
                            <div className={'grow'}/>
                            </>}

                            </div>

                                <div className={"mb-2 select-none"}>
                            <div className={'flex flex-col mx-auto'}>
                                {(pageImage) && <div
                                    className={'p-2 bg-dutch_white-300 border-2 border-white w-[50%] mx-auto rounded'}>
                                    <p className={'font-bold'}>Prompt Image Preview</p><img
                                    className={'w-80 mx-auto rounded-xl'} draggable={"false"} src={pageImage}/></div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"my-2"}>
                    <div className={"h-full flex flex-col mx-2 gap-4 overflow-auto"}>
                        {(matchingSets.map((item, index) =>
                            <div className={'flex w-full bg-dutch_white-100 p-2 rounded bg-opacity-25 shadow'}>
                                <button onClick={() => handleRemovePair(index)}
                                     className={'bg-burnt_sienna hover:bg-burnt_sienna-600 text-dutch_white-300 rounded p-2 h-fit my-auto select-none cursor-pointer mr-2'}>
                                    <svg className={'mx-auto'} xmlns="http://www.w3.org/2000/svg" width="12"
                                         height="12"
                                         viewBox="0 0 24 24">
                                        <path fill="#fff"
                                              d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                    </svg>
                                </button>
                                {(item.promptType) ? (item.promptType === 'text') ?
                                        <div className={'w-2/5 my-auto p-2 bg-paynes_gray-300 rounded shadow'}>
                                            <MatchTipTap setHtmlOutput={handleEditorPromptChange} index={index} content={matchingSets[index].prompt} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Prompt...'} />
                                            {/*<ReactQuill value={matchingSets[index].prompt} ref={quillRef}*/}
                                            {/*            onPaste={handlePaste}*/}
                                            {/*            modules={{toolbar: [['bold', 'italic', 'underline'], [{script: 'sub'}, {script: 'super'}]]}}*/}
                                            {/*            id={"prompt"}*/}
                                            {/*            onChange={(content, delta, source, editor) => handleEditorPromptChange(content, delta, source, editor, index)}*/}
                                            {/*            placeholder={"Prompt:"} className={'bg-burnt_sienna-100'}/>*/}
                                        </div> : (item.promptType === 'image') ? (item.promptUrl) ? <div className={'flex flex-col bg-paynes_gray-300 w-2/5 py-3 rounded shadow'}>
                                            <div className={'flex h-full'}>
                                                <img src={item.promptUrl} className={'h-32 mx-auto rounded'}/>
                                            </div>
                                        </div> : <div className={'flex flex-col bg-burnt_sienna-600 w-2/5 py-3 rounded'}>
                                            <div className={'flex h-full'}>
                                                <button onClick={() => handleAddImage(index, 'prompt')} className={'w-2/5 bg-dutch_white-300 my-auto rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 mx-auto'}>Select Prompt Image</button>
                                            </div>
                                        </div> : <></>
                                    : <div className={'flex flex-col bg-dutch_white-200 w-2/5 py-3 rounded shadow'}>
                                        <p className={'font-bold text-columbia_blue-800'}>Select Prompt Type</p>
                                        <div className={'flex'}>
                                            <button onClick={() => handleSelectPromptType(item, index, 'text')} className={'shadow w-2/5 bg-dutch_white-300 ml-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900'}>Text</button>
                                            <div className={'grow'} />
                                            <button onClick={() => handleAddImage(index, 'prompt')} className={'shadow w-2/5 bg-dutch_white-300 mr-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900'}>Image</button>
                                        </div>
                                    </div>}
                                <div className={'grow h-1/2 my-auto border-dotted border-b-8 border-columbia_blue-700'}/>
                                {(item.matchType) ? (item.matchType === 'text') ?
                                        <div className={'w-2/5 mr-1 p-2 bg-paynes_gray-300 rounded shadow'}>
                                            <MatchTipTap setHtmlOutput={handleEditorMatchChange} index={index} content={matchingSets[index].match} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Match...'} />

                                            {/*<ReactQuill value={matchingSets[index].match} ref={quillRef} onPaste={handlePaste} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ script: 'sub' }, { script: 'super' }]] }} id={"prompt"} onChange={(content, delta, source, editor) => handleEditorMatchChange(content, delta, source, editor, index)} placeholder={"Match:"} className={'bg-columbia_blue-100'}/>*/}
                                        </div> : (item.matchType === 'image') ? (item.matchUrl) ? <div className={'flex flex-col bg-paynes_gray-300 w-2/5 py-3 rounded mr-1'}>
                                            <div className={'flex h-full'}>
                                                <img src={item.matchUrl} className={'h-32 mx-auto rounded'}/>
                                            </div>
                                        </div> : <div className={'flex flex-col bg-columbia_blue-600 w-2/5 py-3 rounded mr-1'}>
                                            <div className={'flex h-full'}>
                                                <button onClick={() => handleAddImage(index, 'match')} className={'w-2/5 bg-dutch_white-300 my-auto rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 mx-auto'}>Select Match Image</button>
                                            </div>
                                        </div>  : <></>
                                    : <div className={'flex flex-col bg-columbia_blue-800 w-2/5 py-3 rounded mr-1'}>
                                        <p className={'font-bold text-dutch_white-200'}>Select Match Type</p>
                                        <div className={'flex rounded'}>
                                            <button onClick={() => handleSelectMatchType(item, index, 'text')} className={'w-2/5 bg-dutch_white-300 ml-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 shadow'}>Text</button>
                                            <div className={'grow'} />
                                            <button onClick={() => handleAddImage(index, 'match')} className={'w-2/5 bg-dutch_white-300 mr-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 shadow'}>Image</button>
                                        </div>
                                    </div>}
                            </div>))}
                        {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center">*/}
                        {/*</button>*/}
                        {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center animate-pulse hover:animate-none">*/}
                        {/*    <span className="text-xl">AI</span>*/}
                        {/*</button>*/}
                        <button onClick={handleAddPair} className={'bg-platinum-900 text-dutch_white-200 w-fit p-2 mt-4 mx-auto rounded font-bold cursor-pointer select-none hover:bg-platinum-700'}>Add Match Pair</button>
                        {previewUrls.map(item => <img src={item}/>)}
                    </div>
                </div>
            </div>
            <button onClick={publishMatchedPairs} className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-columbia_blue-700 text-white font-bold px-10 text-xl hover:bg-columbia_blue-600">
                <p>Publish Item</p>
            </button>
        </>
    );
}

export default MatchingInput;