import React, {useEffect, useRef, useState} from 'react';
import DOMPurify from "dompurify";
import putWithAuth from "../../context/putWithAuth";
import TipTap from "../../components/tipTap";

function RecallEditPromptModal({user, recallEditPromptModal, setRecallEditPromptModal, getAssessmentItems}) {
    const [newPrompt, setNewPrompt] = useState(recallEditPromptModal.oldPrompt);
    const [isLoading, setIsLoading] = useState(null);


    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const modalRef = useRef(null)

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose()
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };

    }, [recallEditPromptModal, setRecallEditPromptModal]);

    const onClose = () => {
        if (newPrompt !== recallEditPromptModal.oldPrompt) {
            if (window.confirm('Are you sure? Closing the window will delete current changes.')) {
                setRecallEditPromptModal(null)
            }
        } else {
            setRecallEditPromptModal(null)
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleNewPromptChange = (content, delta, source, editor) => {
        setNewPrompt(content);
    };

    const putEditRecallPrompt = () => {
        setIsLoading(true);
        let submission = {assessmentItemId: recallEditPromptModal.assessmentItemId, newPrompt: newPrompt}
        putWithAuth('/planning/putEditRecallPrompt', user.userAttributes["custom:school"], {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: submission
        })
            .then(async response => {
                if (response.ok) {
                    await getAssessmentItems(user);
                    setRecallEditPromptModal(null);
                } else {
                    setIsLoading(false)
                    console.log(response.error)
                }
            })}


    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"flex flex-col w-[60%]"} ref={modalRef}>
                <div className={"relative bg-dutch_white-400 p-2 m-4 rounded"}>
                    <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div className={"bg-columbia_blue-300 p-4 text-black cursor-black rounded flex gap-2"}>
                        <div className={'w-1/2'}>
                            <p className={'font-bold text-columbia_blue-800 text-2xl'}>Old Prompt:</p>
                            <div className={'line-through'}
                                 dangerouslySetInnerHTML={renderHtml(recallEditPromptModal.oldPrompt)}/>
                        </div>

                        <div className={'w-1/2'}>
                            <p className={'font-bold text-columbia_blue-800 text-2xl'}>New Prompt:</p>
                            <div className={'editor-container'}>
                                <TipTap setHtmlOutput={setNewPrompt} placeholder={'Recall Prompt...'} backgroundColor={'bg-dutch_white-300'} fontSize={'text-lg'} textColor={'text-columbia_blue-900'} />
                            </div>
                        </div>
                    </div>

                        {(newPrompt !== recallEditPromptModal.oldPrompt) ? <button onClick={putEditRecallPrompt}
                                                                                className={'absolute left-[30%] rounded bg-burnt_sienna-600 select-none hover:bg-burnt_sienna-700 -bottom-5 text-dutch_white-200 font-bold cursor-pointer mx-auto w-[40%] p-1'}>Save
                            Changes</button> : <div
                            className={'absolute left-[30%] rounded bg-platinum-600 -bottom-5 text-dutch_white-200 font-bold mx-auto w-[40%] select-none p-1'}>Save
                            Changes</div>}
                </div>
                {isLoading && <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div> }
            </div>
        </div>
    );
}

export default RecallEditPromptModal;