import React from 'react';
import DOMPurify from "dompurify";

function TraditionalMultipleChoiceEntry({ questionModalContent, selectedAnswer, setSelectedAnswer }) {
    let content = {prompt: DOMPurify.sanitize(questionModalContent.prompt), answers: questionModalContent.answers.map(item => DOMPurify.sanitize(item))}

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    function stripHtml(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Handle superscript and subscript with semantic meaning
        tempDiv.querySelectorAll('sup').forEach(sup => {
            const content = sup.textContent.trim();
            let replacement = `superscript ${content}`;
            if (content === '2') {
                replacement = 'squared';
            } else if (content === '3') {
                replacement = 'cubed';
            }
            sup.replaceWith(replacement);
        });

        return tempDiv.textContent || tempDiv.innerText || '';
    }

    return (
        <div className={'bg-columbia_blue p-2 pb-4'}>
            {(questionModalContent.imageURL) && <img alt={'Assessment prompt image without caption'} aria-label={'Assessment prompt image without caption'} tabIndex={0} src={questionModalContent.imageURL} className={"rounded w-80 mx-auto mb-2 p-2 bg-dutch_white-200 shadow border-dutch_white-600 border-2"} />}
            <div className={"bg-dutch_white-200 text-lg p-4 rounded text-dutch_white-800 border-dutch_white-600 border-2 shadow"} tabIndex={0} aria-label={`Assessment Item Prompt: ${stripHtml(content.prompt)}`}><div dangerouslySetInnerHTML={renderHtml(content.prompt)}/></div>
            <div className={'flex flex-col mt-1'}>
                {content.answers.map(item => (selectedAnswer === item)
                    ? <button aria-label={`Selected Answer: ${stripHtml(item)}`} className={"bg-columbia_blue-800 text-dutch_white-100 p-2 mb-1 border-dutch_white-100 border-2 rounded"}>
                        <div dangerouslySetInnerHTML={renderHtml(item)} />

                    </button>
                    : <button onClick={() => setSelectedAnswer(item)} aria-label={`Possible Answer: ${stripHtml(item)}`} className={"bg-columbia_blue-700 text-dutch_white-100 p-2 border-gray-500 border-2 border-opacity-50 mb-1 rounded hover:bg-columbia_blue-800 cursor-pointer"}>
                        <div dangerouslySetInnerHTML={renderHtml(item)} />
                    </button>
                )}
            </div>

        </div>
    );
}

export default TraditionalMultipleChoiceEntry;