import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";

function CustomToolTip({ active, payload, label }) {
    if (active && payload && payload.length) {

        const sum = payload.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value;
        }, 0);

        return (
            <div className={'bg-columbia_blue-400 text-dutch_white-800 p-1 max-w-[210px] overflow text-xs opacity-90 rounded'}>
                <p>{label}</p>
                <br />
                {payload.map(item => (<p>{item.dataKey}: {item.value} ({Math.round(item.value / sum * 100)}%)</p>))}
            </div>
        )
    }

    return null;
}

function CustomXTick({ active, payload, label }) {
    if (active && payload && payload.length) {
        return (
            <div className={'bg-dutch_white-400 text-dutch_white-800 p-2 max-w-[170px] overflow text-lg'}>
                <p>{label}</p>
                <br />
                {payload.map(item => (<p>{item.dataKey}: {item.value}</p>))}
            </div>
        )
    }

    return null;
}


export default function RecallBarChart({ data, handleBarClick }) {



    return (
        <div className={'w-full'}>
            <ResponsiveContainer width='100%' height={300}>
                <BarChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 20,
                        left: 20,
                        bottom: 5
                    }}
                    onClick={handleBarClick}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#eeeeee" />
                    <XAxis dataKey='name' className={'font-bold'} tick={CustomXTick}/>
                    {/*<YAxis stroke="#eeeeee" />*/}
                    <Tooltip cursor={false} content={<CustomToolTip />} />
                    <Legend />
                    <Bar dataKey="Correct" stackId="a" fill="#538ea5" label={{
                        dataKey: 'name',
                        position: "insideBottom",
                        angle: 0,
                        fill: "#E8DAB2",
                        offset: 25
                    }}/>
                    <Bar dataKey="Incorrect" stackId="a" fill="#dd6e42" />
                    <Bar dataKey="Pending" stackId="a" fill="#8b8b8b" />
                </BarChart>
            </ResponsiveContainer>
        </div>


    );
}
