import React, {useState} from 'react';
import postWithAuth from "../../context/postWithAuth";

function AddTeacherModal({setAddTeacherModal, preps, school}) {
    const [isLoading, setIsLoading] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [activePreps, setActivePreps] = useState([]);

    const onClose = () => {
        if (window.confirm('Are you sure? All progress will be lost')) {
            setAddTeacherModal(null)
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value)
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handleCourseAdd = (e) => {
        setActivePreps([...activePreps, e.target.id])
    }

    const handleCourseRemove = (e) => {
        setActivePreps(activePreps.filter(item => item !== e.target.id))
    }

    const postCreateStudent = async () => {
        if (window.confirm('Are you sure? Teacher account data cannot be modified once added to the user database.')) {
            let body = {
                userData: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: password,
                    activePreps: activePreps
                },
                school: school
            }
            setIsLoading(true);
            postWithAuth('/accountmanagement/postCreateTeacher', school, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body})
                .then((response) => {
                    if (response.ok) {
                        window.alert('Teacher successfully added to database. They will prompted to change their password once logged in with the temporary password.')
                        setIsLoading(false)
                        setAddTeacherModal(false)
                    } else {
                        console.log(response.error)
                        setIsLoading(false);
                    }
                })
        } else {
            return null;
        }
    }


    return (
        <>
            <div>
                <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 p-2 m-4 rounded p-2 h-max-[90%]"}>
                        <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>
                        <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                            <div className={'flex-col mx-auto'}>
                                <div className={'flex gap-2'}>
                                    <div>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Teacher First Name:</p>
                                        <textarea onChange={handleFirstNameChange} className={'rounded px-2 w-[500px] bg-dutch_white-100 text-columbia_blue-800'}/>
                                    </div>
                                    <div>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Teacher Last Name:</p>
                                        <textarea onChange={handleLastNameChange} className={'rounded px-2 w-[500px] bg-dutch_white-100 text-columbia_blue-800'}/>
                                    </div>
                                </div>
                                <div className={'flex gap-2'}>
                                    <div>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Teacher Email:</p>
                                        <textarea onChange={handleEmailChange} className={'rounded px-2 w-[500px] bg-dutch_white-100 text-columbia_blue-800'}/>
                                    </div>
                                    <div>
                                        <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Temporary Password:</p>
                                        <textarea onChange={handlePasswordChange} className={'rounded px-2 w-[500px] bg-dutch_white-100 text-columbia_blue-800'}/>
                                    </div>
                                </div>

                            </div>
                            <div className={'grid grid-cols-2 gap-2 mb-4'}>
                                <div className={'flex-col gap-2'}>
                                    <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Select Courses to Teach:</p>
                                    <div className={'grid grid-cols-2 gap-2'}>
                                        {preps.filter(item => (!activePreps.includes(item))).sort((a,b) => a.localeCompare(b)).map(item => <div id={item} onClick={handleCourseAdd} className={'rounded text-dutch_white-200 font-bold text-lg bg-columbia_blue-700 hover:bg-columbia_blue-600 cursor-pointer select-none px-2'}>{item}</div>)}
                                    </div>
                                </div>
                                <div className={'flex-col gap-2'}>
                                    <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Selected Courses:</p>
                                    {(activePreps) && activePreps.map(item => <div id={item} onClick={handleCourseRemove} className={'rounded text-dutch_white-200 p-1 font-bold text-lg bg-burnt_sienna-700 hover:bg-burnt_sienna-600 cursor-pointer select-none mb-2 px-2'}>{item}</div>)}
                                </div>
                            </div>
                            <button onClick={postCreateStudent} className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-burnt_sienna text-white font-bold px-10 text-xl hover:bg-burnt_sienna-600">
                                <p>Create Teacher Account</p>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </>
    );
}

export default AddTeacherModal;