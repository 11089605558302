import React from 'react';
import {useNavigate} from "react-router-dom";
var content = require('./tosContent.jsx');


function ToSPage() {
    const navigate = useNavigate()
    return (
        <>
            <div className={'flex justify-between bg-columbia_blue-800 py-4'}>
                <div className={'grow'}></div>
                <button className={"px-4"} onClick={() => navigate('/')} title={'Home Button'}>
                    <img className={'h-[70px] cursor-pointer mx-auto pb-2'}
                         src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'}
                         alt={'Brainmeter'}/>
                </button>
                <div className={'grow'}></div>
            </div>
            <div className={'bg-columbia_blue-800 pb-8'}>
                <div
                    className={'text-left max-w-[50%] bg-dutch_white-200 mx-auto text-columbia_blue-900 py-4 px-8 select-none rounded'}>
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>
        </>
)
    ;
}

export default ToSPage;