module.exports = `<!DOCTYPE html>
    <html>
    <head>
    <meta content="text/html; charset=UTF-8"
    http-equiv="content-type">
        <style type="text/css">@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

        .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1
    }

.lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

.lst-kix_list_e-7 > li {
        counter-increment: lst-ctn-kix_list_e-7
    }

    ol.lst-kix_list_d-1.start {
        counter-reset: lst-ctn-kix_list_d-1 0
    }

    ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0
    }

    ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
    }

.lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3
    }

    ol.lst-kix_list_e-4.start {
        counter-reset: lst-ctn-kix_list_e-4 0
    }

.lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

.lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

.lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0
    }

.lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4
    }

    ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
    }

    ol.lst-kix_list_d-6.start {
        counter-reset: lst-ctn-kix_list_d-6 0
    }

.lst-kix_list_d-6 > li {
        counter-increment: lst-ctn-kix_list_d-6
    }

.lst-kix_list_d-6 > li:before {
        content: "" counter(lst-ctn-kix_list_d-6, decimal) ". "
    }

.lst-kix_list_d-8 > li:before {
        content: "" counter(lst-ctn-kix_list_d-8, lower-roman) ". "
    }

.lst-kix_list_d-7 > li:before {
        content: "" counter(lst-ctn-kix_list_d-7, lower-latin) ". "
    }

.lst-kix_list_10-3 > li {
        counter-increment: lst-ctn-kix_list_10-3
    }

.lst-kix_list_d-2 > li:before {
        content: "" counter(lst-ctn-kix_list_d-2, lower-roman) ". "
    }

    ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
    }

.lst-kix_list_d-1 > li:before {
        content: "" counter(lst-ctn-kix_list_d-1, lower-latin) ". "
    }

.lst-kix_list_d-5 > li:before {
        content: "" counter(lst-ctn-kix_list_d-5, lower-roman) ". "
    }

.lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

.lst-kix_list_d-4 > li:before {
        content: "" counter(lst-ctn-kix_list_d-4, lower-latin) ". "
    }

.lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6
    }

.lst-kix_list_d-3 > li:before {
        content: "" counter(lst-ctn-kix_list_d-3, decimal) ". "
    }

    ol.lst-kix_list_d-0.start {
        counter-reset: lst-ctn-kix_list_d-0 0
    }

    ol.lst-kix_list_e-5.start {
        counter-reset: lst-ctn-kix_list_e-5 0
    }

.lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5
    }

.lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    ol.lst-kix_list_d-7.start {
        counter-reset: lst-ctn-kix_list_d-7 0
    }

.lst-kix_list_d-0 > li:before {
        content: "" counter(lst-ctn-kix_list_d-0, decimal) ". "
    }

    ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0
    }

    ol.lst-kix_list_1-3 {
        list-style-type: none
    }

    ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0
    }

    ol.lst-kix_list_1-4 {
        list-style-type: none
    }

.lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
    }

.lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7
    }

    ol.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_1-0 {
        list-style-type: none
    }

.lst-kix_list_2-5 > li:before {
        content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "
    }

    ol.lst-kix_list_1-1 {
        list-style-type: none
    }

.lst-kix_list_d-2 > li {
        counter-increment: lst-ctn-kix_list_d-2
    }

    ol.lst-kix_list_1-2 {
        list-style-type: none
    }

    ol.lst-kix_list_d-8.start {
        counter-reset: lst-ctn-kix_list_d-8 0
    }

    ol.lst-kix_list_e-3.start {
        counter-reset: lst-ctn-kix_list_e-3 0
    }

    ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

.lst-kix_list_d-3 > li {
        counter-increment: lst-ctn-kix_list_d-3
    }

.lst-kix_list_10-1 > li:before {
        content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". "
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    ol.lst-kix_list_1-7 {
        list-style-type: none
    }

.lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    ol.lst-kix_list_1-8 {
        list-style-type: none
    }

.lst-kix_list_10-7 > li:before {
        content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". "
    }

    ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0
    }

.lst-kix_list_10-5 > li:before {
        content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". "
    }

    li.li-bullet-1:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

.lst-kix_list_10-3 > li:before {
        content: "" counter(lst-ctn-kix_list_10-3, decimal) ". "
    }

.lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6
    }

.lst-kix_list_4-1 > li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
    }

.lst-kix_list_d-1 > li {
        counter-increment: lst-ctn-kix_list_d-1
    }

.lst-kix_list_4-3 > li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "
    }

.lst-kix_list_4-5 > li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
    }

.lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8
    }

    ol.lst-kix_list_e-0.start {
        counter-reset: lst-ctn-kix_list_e-0 0
    }

.lst-kix_list_10-5 > li {
        counter-increment: lst-ctn-kix_list_10-5
    }

    ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
    }

.lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
    }

.lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

.lst-kix_list_e-5 > li {
        counter-increment: lst-ctn-kix_list_e-5
    }

    ol.lst-kix_list_10-7 {
        list-style-type: none
    }

    ol.lst-kix_list_10-8 {
        list-style-type: none
    }

    ol.lst-kix_list_10-3 {
        list-style-type: none
    }

    ol.lst-kix_list_10-4 {
        list-style-type: none
    }

    ol.lst-kix_list_10-5 {
        list-style-type: none
    }

    ol.lst-kix_list_10-6 {
        list-style-type: none
    }

    ol.lst-kix_list_10-0 {
        list-style-type: none
    }

    ol.lst-kix_list_10-1 {
        list-style-type: none
    }

    ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
    }

    ol.lst-kix_list_10-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
    }

.lst-kix_list_1-1 > li:before {
        content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". "
    }

    ol.lst-kix_list_e-1.start {
        counter-reset: lst-ctn-kix_list_e-1 0
    }

.lst-kix_list_e-0 > li {
        counter-increment: lst-ctn-kix_list_e-0
    }

    ol.lst-kix_list_e-2.start {
        counter-reset: lst-ctn-kix_list_e-2 0
    }

.lst-kix_list_1-3 > li:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
    }

.lst-kix_list_10-4 > li {
        counter-increment: lst-ctn-kix_list_10-4
    }

    ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0
    }

.lst-kix_list_e-6 > li {
        counter-increment: lst-ctn-kix_list_e-6
    }

.lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8
    }

.lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "
    }

    ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0
    }

.lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3
    }

.lst-kix_list_1-5 > li:before {
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "
    }

.lst-kix_list_2-1 > li:before {
        content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "
    }

.lst-kix_list_2-3 > li:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "
    }

.lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2
    }

.lst-kix_list_d-8 > li {
        counter-increment: lst-ctn-kix_list_d-8
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

.lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    ol.lst-kix_list_d-8 {
        list-style-type: none
    }

.lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1
    }

    ol.lst-kix_list_d-5 {
        list-style-type: none
    }

    ol.lst-kix_list_d-4 {
        list-style-type: none
    }

    ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0
    }

.lst-kix_list_3-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-0, lower-roman) ") "
    }

    ol.lst-kix_list_d-7 {
        list-style-type: none
    }

    ol.lst-kix_list_d-6 {
        list-style-type: none
    }

.lst-kix_list_3-1 > li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". "
    }

.lst-kix_list_3-2 > li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". "
    }

.lst-kix_list_d-7 > li {
        counter-increment: lst-ctn-kix_list_d-7
    }

    ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
    }

.lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0
    }

.lst-kix_list_3-5 > li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". "
    }

.lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0
    }

.lst-kix_list_3-4 > li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". "
    }

.lst-kix_list_3-3 > li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

.lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
    }

    ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0
    }

.lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0
    }

    ol.lst-kix_list_e-7.start {
        counter-reset: lst-ctn-kix_list_e-7 0
    }

.lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
    }

.lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
    }

    ol.lst-kix_list_d-1 {
        list-style-type: none
    }

    ol.lst-kix_list_d-0 {
        list-style-type: none
    }

    ol.lst-kix_list_d-3 {
        list-style-type: none
    }

    ol.lst-kix_list_d-2 {
        list-style-type: none
    }

.lst-kix_list_10-2 > li {
        counter-increment: lst-ctn-kix_list_10-2
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

.lst-kix_list_e-8 > li {
        counter-increment: lst-ctn-kix_list_e-8
    }

.lst-kix_list_e-5 > li:before {
        content: "" counter(lst-ctn-kix_list_e-5, lower-roman) ". "
    }

    ol.lst-kix_list_2-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-4 {
        list-style-type: none
    }

    ol.lst-kix_list_2-5 {
        list-style-type: none
    }

.lst-kix_list_e-3 > li:before {
        content: "" counter(lst-ctn-kix_list_e-3, decimal) ". "
    }

.lst-kix_list_e-7 > li:before {
        content: "" counter(lst-ctn-kix_list_e-7, lower-latin) ". "
    }

    ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0
    }

.lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4
    }

.lst-kix_list_e-2 > li:before {
        content: "" counter(lst-ctn-kix_list_e-2, lower-roman) ". "
    }

.lst-kix_list_e-6 > li:before {
        content: "" counter(lst-ctn-kix_list_e-6, decimal) ". "
    }

    ol.lst-kix_list_2-0 {
        list-style-type: none
    }

    ol.lst-kix_list_2-1 {
        list-style-type: none
    }

.lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
    }

.lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
    }

.lst-kix_list_e-4 > li:before {
        content: "" counter(lst-ctn-kix_list_e-4, lower-latin) ". "
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

.lst-kix_list_e-1 > li:before {
        content: "" counter(lst-ctn-kix_list_e-1, lower-latin) ". "
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0
    }

    ol.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7 {
        list-style-type: none
    }

.lst-kix_list_e-0 > li:before {
        content: "" counter(lst-ctn-kix_list_e-0, upper-latin) ". "
    }

    ol.lst-kix_list_2-8 {
        list-style-type: none
    }

.lst-kix_list_d-0 > li {
        counter-increment: lst-ctn-kix_list_d-0
    }

.lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3
    }

.lst-kix_list_e-1 > li {
        counter-increment: lst-ctn-kix_list_e-1
    }

.lst-kix_list_e-4 > li {
        counter-increment: lst-ctn-kix_list_e-4
    }

    ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0
    }

.lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

.lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
    }

.lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7
    }

.lst-kix_list_2-4 > li:before {
        content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "
    }

.lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
    }

.lst-kix_list_e-2 > li {
        counter-increment: lst-ctn-kix_list_e-2
    }

.lst-kix_list_10-0 > li:before {
        content: "" counter(lst-ctn-kix_list_10-0, upper-latin) ". "
    }

.lst-kix_list_e-3 > li {
        counter-increment: lst-ctn-kix_list_e-3
    }

.lst-kix_list_10-6 > li {
        counter-increment: lst-ctn-kix_list_10-6
    }

    ol.lst-kix_list_e-6.start {
        counter-reset: lst-ctn-kix_list_e-6 0
    }

.lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0
    }

.lst-kix_list_10-4 > li:before {
        content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". "
    }

.lst-kix_list_10-8 > li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". "
    }

.lst-kix_list_4-0 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, upper-latin) ". "
    }

.lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8
    }

.lst-kix_list_10-2 > li:before {
        content: "" counter(lst-ctn-kix_list_10-2, decimal) ". "
    }

.lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6
    }

.lst-kix_list_d-4 > li {
        counter-increment: lst-ctn-kix_list_d-4
    }

    ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
    }

.lst-kix_list_4-4 > li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
    }

    ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0
    }

.lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5
    }

.lst-kix_list_4-2 > li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". "
    }

.lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
    }

    ol.lst-kix_list_d-5.start {
        counter-reset: lst-ctn-kix_list_d-5 0
    }

    ol.lst-kix_list_d-2.start {
        counter-reset: lst-ctn-kix_list_d-2 0
    }

.lst-kix_list_10-8 > li {
        counter-increment: lst-ctn-kix_list_10-8
    }

.lst-kix_list_e-8 > li:before {
        content: "" counter(lst-ctn-kix_list_e-8, lower-roman) ". "
    }

.lst-kix_list_10-6 > li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". "
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

.lst-kix_list_d-5 > li {
        counter-increment: lst-ctn-kix_list_d-5
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

.lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_d-3.start {
        counter-reset: lst-ctn-kix_list_d-3 0
    }

    ol.lst-kix_list_e-8 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    ol.lst-kix_list_e-7 {
        list-style-type: none
    }

    ol.lst-kix_list_e-4 {
        list-style-type: none
    }

    ol.lst-kix_list_e-3 {
        list-style-type: none
    }

    ol.lst-kix_list_e-6 {
        list-style-type: none
    }

    ol.lst-kix_list_e-8.start {
        counter-reset: lst-ctn-kix_list_e-8 0
    }

    ol.lst-kix_list_e-5 {
        list-style-type: none
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

.lst-kix_list_1-0 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

.lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "
    }

    ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

.lst-kix_list_1-4 > li:before {
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

.lst-kix_list_10-1 > li {
        counter-increment: lst-ctn-kix_list_10-1
    }

.lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0
    }

.lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6
    }

.lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    ol.lst-kix_list_d-4.start {
        counter-reset: lst-ctn-kix_list_d-4 0
    }

.lst-kix_list_10-7 > li {
        counter-increment: lst-ctn-kix_list_10-7
    }

    ol.lst-kix_list_e-0 {
        list-style-type: none
    }

    ol.lst-kix_list_e-2 {
        list-style-type: none
    }

    ol.lst-kix_list_e-1 {
        list-style-type: none
    }

.lst-kix_list_2-0 > li:before {
        content: "" counter(lst-ctn-kix_list_2-0, upper-latin) ". "
    }

    ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0
    }

.lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5
    }

.lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "
    }

.lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

.c26 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c22 {
        -webkit-text-decoration-skip: none;
        color: #0563c1;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c8 {
        margin-left: 54pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c5 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt
    }

.c2 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

.c7 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c14 {
        margin-left: 36pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c15 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c9 {
        margin-left: 90pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c0 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c4 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c3 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center
    }

.c34 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

.c35 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal
    }

.c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c6 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

.c10 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c29 {
        padding-top: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c17 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c18 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c23 {
        padding-top: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c16 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center
    }

.c31 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c28 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c25 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left
    }

.c24 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt
    }

.c30 {
        margin-left: 54pt;
        orphans: 2;
        widows: 2
    }

.c13 {
        padding: 0;
        margin: 0
    }

.c19 {
        color: inherit;
        text-decoration: inherit
    }

.c20 {
        margin-left: 72pt;
        padding-left: 18pt
    }

.c32 {
        border: 1px solid black;
        margin: 5px
    }

.c33 {
        orphans: 2;
        widows: 2
    }

.c12 {
        margin-left: 18pt
    }

.c21 {
        margin-left: 36pt
    }

.c36 {
        margin-left: 58.5pt
    }

.c11 {
        height: 11pt
    }

.c27 {
        padding-left: 0pt
    }

.title {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 16pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: center
    }

.subtitle {
        padding-top: 0pt;
        color: #000000;
        font-size: 11pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 6pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: left
    }</style>
</head>
    <body class="c24 doc-content"><p class="c16"><span
        class="c4">Terms and Conditions of Use/End User License Agreement</span></p>
    <p class="c11 c16"><span class="c4"></span></p>
    <p class="c17"><span class="c1">Welcome to Brainmeter! These Terms and Conditions of Use and End User License Agreement (&ldquo;Terms&rdquo;) apply and govern your use of our software platform Brainmeter.org (&ldquo;Platform&rdquo;) and are designed to support teachers in conducting their classrooms by using three major roles of planning, classroom management, and data review. Brainmeter LLC, any owners, officers, successors, and assigns, (collectively, &ldquo;We&rdquo;, &ldquo;Us&rdquo;, or &ldquo;Brainmeter&rdquo;) offers a variety of options through the Platform (collectively, the &ldquo;Services&rdquo;). By using the Services, you agree to all the Terms and state that you understand this is a binding, legal agreement. IF YOU DISAGREE WITH ANY PART OF THESE TERMS, YOU MUST NOT DOWNLOAD OR USE THESE SERVICES. CONTINUED USE OF THE SERVICES INDICATES AGREEMENT TO ALL TERMS.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <p class="c17"><span class="c1">Please feel free to contact Us at </span><span class="c22"><a class="c19"
                                                                                                  href="mailto:justinlcochran@brainmeter.org">justinlcochran@brainmeter.org</a></span><span
        class="c1">. with any questions, concerns, or issues.</span></p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0 start" start="1">
        <li class="c7 li-bullet-0"><span class="c4">Use of the Services and Your Account</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <ol class="c13 lst-kix_list_2-0 start" start="1">
        <li class="c15 li-bullet-0"><span class="c1">Who Can Use the Services. </span></li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <p class="c10"><span class="c1">School level administrators such as Principals, Vice Principals, or district level assessment coordinators, students, and teachers within the participating school. You must be a resident of the United States of America. </span>
    </p>
    <p class="c2"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_2-0" start="2">
        <li class="c15 li-bullet-0"><span class="c1">Your Account</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Your account will be created for you. It is important that you keep your account accurate and up to date. Your account will determine which Services you can access within the Platform. </span>
    </p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c10"><span class="c1">When you create an account for any of the Services, you must provide Us with accurate and complete information as prompted by the account creation and registration process and keep that information up to date. Otherwise, some of the Services may not operate correctly and Our System may not be able to provide the most accurate information to our users. We also may not be able to contact you with important notices without accurate, up-to-date information.</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">You are responsible for maintaining the confidentiality of any and all actions that take place while using your account (such as sharing your information with others, letting others log into your account, creating an account using a shared device, creating an account using a shared e-mail address, etc.). You should notify Us right away of any actual or suspected loss, theft, or unauthorized use of your account or account password. We are not responsible for any loss or damage that results from unauthorized use of your username and password.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_2-0" start="3">
        <li class="c15 li-bullet-0"><span class="c1">Service Updates, Changes, and Limitations</span></li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <p class="c10"><span class="c1">With the launch of new products, Services, user levels, and features, we need the flexibility to make changes, impose limits, and occasionally suspend or terminate certain Services. We may also update our Services, which might not work properly if you don&rsquo;t install the updates. </span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">The Services may change in form or function without prior notice to you. We may provide updates, automatic or otherwise, for certain Services as and when we see fit. This may include upgrades, modifications, bug fixes, patches, and other error corrections and/or new features (collectively, &ldquo;Updates&rdquo;). Certain portions of the Services may not properly operate if you do not install all Updates. You acknowledge and agree that the Services may not work properly if you do not allow such Updates and you expressly consent to automatic Updates.</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">You agree and understand that the Terms may be modified from time to time. These Terms and their modifications will apply to any and all Updates to the Services without notification to you. You will be notified if a material change to the Terms is made.</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">We may change, suspend, or discontinue any and all of the Services at any time, including the availability or any product, feature, database, or content. In addition, we have no obligation to provide any Updates or to continue to provide or enable any particular features or functionality of any Service. We may also impose limits on certain Services or restrict your access to part or all of the Service without notice or liability.</span>
    </p>
    <p class="c2"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_2-0" start="4">
        <li class="c15 li-bullet-0"><span class="c1">Service Monitoring and Suspension. </span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c4">We reserve the right to refuse to provide the Services to anyone at any time for any reason. We can monitor, terminate, or suspend your account or access to the Services at any time and are not required to provide advance notice to you. We will attempt to notify you by email or through your next attempt to access your account.</span>
    </p>
    <p class="c5"><span class="c4"></span></p>
    <p class="c10"><span class="c1">We reserve the right, but have no obligation, to monitor any accounts and activities conducted through or in any way related to the Services as well as any user&rsquo;s use of access to Personal Data and profiles of other users.</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">We have the right to deactivate, terminate, or suspend your account or access to Services at any time and without any refund to you or liability to Us if:</span>
    </p>
    <p class="c10"><span class="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <ol class="c13 lst-kix_list_3-0 start" start="1">
        <li class="c17 c20 li-bullet-1"><span class="c1">We, in our sole discretion, determine you are or have been in violation of these Terms in any way;</span>
        </li>
        <li class="c17 c20 li-bullet-1"><span class="c1">We, in our sole discretion, determine you have created risk or possible legal exposure to us, the general public, any third party, or any user of our services;</span>
        </li>
        <li class="c17 c20 li-bullet-1"><span class="c1">We, in our sole discretion, determine that you have infringed or attempted to infringe our or a third-party licensee&rsquo;s intellectual property, privacy, or security rights;</span>
        </li>
        <li class="c17 c20 li-bullet-1"><span class="c1">Your school&rsquo;s payment for Services has failed, bounced, or been refunded to you;</span>
        </li>
        <li class="c17 c20 li-bullet-1"><span class="c1">We, in our sole discretion, determine your school has been in violation of its agreement with Us, or failed to perform under its agreement with Us.</span>
        </li>
        <li class="c17 c20 li-bullet-1"><span class="c1">In response to request by law enforcement or other government agencies;</span>
        </li>
        <li class="c17 c20 li-bullet-1"><span
            class="c1">Upon discontinuation or material modification of any services; or</span></li>
        <li class="c17 c20 li-bullet-1"><span class="c1">Due to unexpected technical issues or problems.</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_2-0" start="5">
        <li class="c15 li-bullet-0"><span class="c1">Security</span></li>
    </ol>
    <p class="c2"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Please let us know right away if you believe your account has been hacked or compromised.</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">We care about the security of our users. While we work hard to protect the security of your personal data, disaggregated data, and account, we cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify</span><span
        class="c1">&nbsp;</span><span class="c1">your school administrator immediately of any actual or suspected breach or unauthorized access or use of your account. They will notify Brainmeter directly of any such breach or access.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="2">
        <li class="c7 li-bullet-0"><span class="c4">Ownership and Use of Content</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <ol class="c13 lst-kix_list_4-0 start" start="1">
        <li class="c15 li-bullet-0"><span class="c1">Ownership</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c1">All content of the Services, including data, reports, and information entered into the Platform, except your personally identifiable information, and your populated account base data, is owned by Us unless these Terms say otherwise. All copyright, trademarks, design rights, patents, formulas, algorithms, code, and other intellectual property rights, registered and unregistered in and on the Services belong to Brainmeter, our partners, or applicable third-parties (collectively, &ldquo;Content&rdquo;).</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Each User retains ownership, responsibility for, and other applicable rights in their personally identifiable information entered into the Platform but grants a license of that information to us as explained below. Brainmeter and its partners or third parties retain ownership, responsibility for and other applicable rights in all Content. </span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Except as expressly provided in the Terms, nothing grants you a right or license to use any Content in the Services. You agree not to duplicate, publish, display, distribute, modify, reverse engineer, publicly analyze, report, or create derivative works from the material presented through the Services unless authorized in a personalized and specific writing from us, which writing shall not create a waiver for other users or uses.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_4-0" start="2">
        <li class="c15 li-bullet-0"><span class="c1">Our License to You</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c1">You are permitted to access and use the Content and Services as designed by the Platform within the Platform. We work hard to provide a great experience for our users, so please respect our intellectual property rights and only use the Content and Services as intended. </span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Subject to your compliance with these Terms and the laws of the United States, we grant you a limited, revocable, personal, non-transferable, and non-exclusive right and license to access and use the Services and Content for your own personal purposes pursuant to the user level and Services you have purchased, provided that you do not, copy, modify, create a derivative work from, reverse engineer, sell, assign, sublicense, grant a security interest in, transfer, or otherwise commercially exploit any right in the Content or Services, or permit a third-party to do the same through your use of the Content or Services.</span>
    </p>
    <p class="c6 c21"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_4-0" start="3">
        <li class="c25 c33 c27 c36 li-bullet-0"><span class="c1">Your License to Us</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c1">When you post your personally identifiable information in connection with the Services, it belongs to you. However, by posting, you are giving us permission to use your personally identifiable information in connection with our Services and allowing us to make that content available to others and incorporating it into our own Content. We can edit or remove your personally identifiable information from our Services at any time for any reason.</span>
    </p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c25 c30"><span class="c1">The rights you grant in this license are for the limited purposes of allowing Brainmeter to operate and allow other users to use the Services in accordance with their functionality, improve the Services, and develop new services. Notwithstanding the above, we will not make use of any of your information or content in a manner that is inconsistent with the Privacy portion of these Terms.</span>
    </p>
    <p class="c6 c21"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_4-0" start="4">
        <li class="c15 li-bullet-0"><span class="c1">Third-Party Licenses</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c25 c30"><span
        class="c1">The relevant third-party licenses are disclosed and accessible within the Platform.</span></p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_4-0" start="5">
        <li class="c15 li-bullet-0"><span class="c1">Public Information; Information Retention</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Please keep in mind that when you make something publicly available on the internet, it becomes practically impossible to take down all copies of it in the future</span>
    </p>
    <p class="c2"><span class="c1"></span></p>
    <p class="c10"><span class="c1">Following the termination of your account, or if you remove any personally identifiable information from the Services, we may retain your information for a commercially-reasonable period of time for backup, archival, or audit purposes, or as otherwise required or permitted by law. We will retain data regarding your information, separated from any information that would link that information to you personally, for perpetuity in order to create and improve the Services. The license in section 2.C therefore continues in perpetuity, even if you stop using the Services.</span>
    </p>
    <p class="c5"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_4-0" start="6">
        <li class="c15 li-bullet-0"><span class="c1">Brainmeter Confidential Information</span></li>
    </ol>
    <p class="c6"><span class="c1"></span></p>
    <p class="c10"><span class="c1">You shall hold Brainmeter confidential information in strictest confidence. You agree not to utilize, exploit, or incorporate any Brainmeter confidential information for Your own or any third party&rsquo;s benefit, directly or indirectly. You shall not disclose Brainmeter confidential information to any other person unless Brainmeter provides you express permission. You shall not compete with Brainmeter by using any of their confidential information. You shall not write or record anything regarding the Brainmeter confidential information, take photos, videos, or share screenshots of the Brainmeter confidential information, or otherwise discuss or disclose the Brainmeter confidential information in any way. Notwithstanding the above, this Agreement shall not bind any user from complying with the requirements of the Family Educational Rights and Privacy Act (FERPA).</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <p class="c0"><span class="c4">We are not responsible or liable for any injury or harm to you resulting from objectionable user activities, failures of other users to comply with the Terms, or harassment of you by other users.</span>
    </p>
    <p class="c6"><span class="c4"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="3">
        <li class="c7 li-bullet-0"><span class="c4">Third-Party Links and Services</span></li>
    </ol>
    <p class="c6 c21"><span class="c4"></span></p>
    <p class="c0"><span class="c1">Our Services may link to, interact with, or be available on third-party services or products such as social media or third-party devices. If you interact with such third-party services or products, be aware that different terms and privacy policies apply to your usage of such services.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="4">
        <li class="c7 li-bullet-0"><span class="c4">Modifications to these Terms</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c1">We reserve the right to modify these Terms at any time by posting revised Terms on the Platform or providing advance notice to you of material changes to the Terms, generally via email where practicable. Modifications will not apply retroactively unless required by law.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">We may sometimes ask you to review and to explicitly agree to or reject a revised version of the Terms. In such cases, modifications will be effective at the time of your agreement to the modified version of the Terms. If you do not agree at that time, you are no longer permitted to use the Services. </span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">When we do not ask for your explicit agreement to a modified version of these Terms, the modified version of these Terms will become effective as of the date specified in the Terms. Your choice to maintain an account, access the Services, or use the Services following that date constitutes your acceptance of the terms and conditions of the Terms as modified. If you do not agree to the modifications, you are not permitted to use and should discontinue your use of the Services.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="5">
        <li class="c7 li-bullet-0"><span class="c4">No Warranties</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c1">EXCEPT WHERE PROHIBITED BY LAW, BRAINMETER EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS, AND GUARANTEES OF ANY KIND, WHETHER ORAL OR WRITTEN, EXPRESS, IMPLIED, STATUTORY OR OTEHRWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT TO THE FULLEST EXTENT PERMISSIBLE UNDER THE LAW. THE SERVICES ARE PROVIDED ON AN &lsquo;AS IS&rsquo; AND &lsquo;AS AVAILABLE&rsquo; WITH ALL FAULTS BASIS.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">Without limiting the foregoing, you understand that, to the maximum extent permitted by applicable law, we make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness, availability, or reliability of any of the Services or Content. To the maximum extent permitted by the applicable law, we do not warrant that: (i) the Services will meet your requirements or provide specific results, (ii) the operation of the Services will be uninterrupted, virus- or error-free or free from other harmful elements, or (iii) errors will be corrected. Any oral or written advice provided by our agents or us does not and will not create any warranty.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">To the maximum extent permitted by applicable law, we also make no representations or warranties of any kind with respect to any Content or rankings. You therefore expressly acknowledge and agree that use of the Services is at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy, and effort is with you.</span>
    </p>
    <p class="c6 c21"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="6">
        <li class="c7 li-bullet-0"><span class="c4">Limitation of Liability</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c1">We are building the best Services we can for you, but we can&rsquo;t promise that they will be perfect. We are not liable for various things that could go wrong as a result of your use of the Services.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">To the maximum extent permitted by applicable law, under no circumstances shall Brainmeter, its subsidiaries, partners, or any wireless carriers be liable to you or any third party for: (i) any indirect, incidental, special, reliance, exemplary, punitive, or consequential damages of any kind whatsoever; (ii) loss of profits, revenue, data, use, goodwill, or other intangible losses; (iii) damages relating to your access to, use of, or inability to access or use the Services; (iv) damages relating to any conduct or content of any third party or user of the Services; and (v) damages in any manner relating to any third-party products accessed or used via the Services. To the extent permitted by applicable law, we are not liable for any claims arising out of (a) your use of the Services, (b) the use, disclosure, display, or maintenance of user&rsquo;s personally identifiable information, (c) any other interactions with us or any other users of the Services, even if we have been advised of the possibility of such damages, or (d) other Content, information, services or goods received through or advertised on the Services or received through any links provided with the Services.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">To the maximum extent permitted by applicable law, this limitation applies to all claims, whether based on warranty, contract, tort, or any other legal theory, whether or not Brainmeter has been informed of the possibility of such damage, and further where a remedy set forth herein is found to have failed its essential purpose. </span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">To the maximum extent permitted by applicable law, the total liability of Brainmeter for any claim under these terms, including any implied warranties, is limited to the greater of five hundred dollars Brainmeter ($500.00).</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c4">If you are a resident of California:</span><span class="c1">&nbsp;You waive your rights with respect to California Civil Code Section 1542, which says &lsquo;a general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.&rsquo;</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c4">If you are a resident of New Jersey:</span><span class="c1">&nbsp;Notwithstanding anything herein to the contrary, nothing in these Terms limits or excludes our responsibility for losses or damages caused by our own fraud, recklessness, gross negligence, or willful misconduct.</span>
    </p>
    <p class="c6"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="7">
        <li class="c7 li-bullet-0"><span class="c4">Indemnification</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c4">To the maximum extent permitted by applicable law, you agree to indemnify and hold Brainmeter, its affiliates, and its partners harmless from any claim or demand, including reasonable accounting and attorneys&rsquo; fees, made by any third-party due or arising out of (i) the Content you access or share through the Services; (ii) your use of the Services; (iii) your activities in connection with the Services; (iv) your connection to the Services; (v) your violation of these Terms; (vi) your use or misuse of any user&rsquo;s personally identifiable information; or (vii) any violation of the rights of any other person or entity by you. We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us under the Terms, and you agree to cooperate with our defense of these claims.</span>
    </p>
    <p class="c0 c11"><span class="c4"></span></p>
    <p class="c0"><span class="c26">If you are a resident of New Jersey:</span><span class="c1">&nbsp;Notwithstanding anything herein to the contrary, nothing in these Terms imposes an obligation for you to indemnify us from claims arising out of Brainmeter&rsquo;s own fraud, recklessness, gross negligence, or willful misconduct.</span>
    </p>
    <p class="c6 c12"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="8">
        <li class="c7 li-bullet-0"><span class="c4">Governing Law; Disputes and Arbitration; Class Action Waiver; Jurisdiction and Venue</span>
        </li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c1">These terms shall be governed by and construed in accordance with the laws of Colorado and controlling U.S. federal law as applicable, without regard to conflict of laws principles. YOU AND BRAINMETER AGREE TO SUBMIT ANY AND ALL DISPUTES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES (&ldquo;DISPUTE&rdquo;) TO BINDING ARBITRATION WITH JAMS DENVER COLORADO. To the maximum extent permitted by applicable law, you and Brainmeter agree that any Dispute will be resolved by binding arbitration pursuant to the Federal Arbitration Act (Title 9 of the U.S. Code), which shall govern the interpretation and enforcement of this Arbitration Agreement, or in small claims courts in Denver Colorado, to the extent such claims are within its jurisdiction. In arbitration, disputes are resolved by a neutral arbitrator instead of a judge or jury, discovery is more limited than in court, and the arbitrator&rsquo;s decision is subject to limited review by courts. The arbitrator can award on an individual basis the same damages and relief as a court, including monetary damages, injunctive relief, and declaratory relief. Judgment on the arbitrator&rsquo;s award may be entered in any court having jurisdiction thereof. Such disputes resolution proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action. Except where prohibited, you and we agree to submit to the personal and exclusive arbitration of Disputes relating to your general use of the Services under the JAMS General Arbitration Rules. Please visit </span><span
        class="c22"><a class="c19"
                       href="https://www.google.com/url?q=http://www.jamsadr.com&amp;sa=D&amp;source=editors&amp;ust=1721769485693551&amp;usg=AOvVaw0KZHkg9uBxD7R_Np5LCxuk">www.jamsadr.com</a></span><span
        class="c1">&nbsp;for more information about arbitration.</span></p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">Any arbitration between you and us, to the extent feasible, will be conducted remotely as provided below. If you so choose, you may elect to have an in-person hearing within three-hours&rsquo; drive of your hometown, at your cost.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">The arbitration will be conducted in English. The parties shall agree to an arbitrator pursuant to the JAMS arbitration rules. The arbitrator will decide all issues relating to the enforceability, interpretation, scope, and application of this Arbitration Agreement and these Terms, to the extent permissible by law. All arbitration proceedings will be confidential and all records relating thereto will be sealed, except as necessary for enforcement of the arbitration award.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">You will not be responsible for any JAMS Case Management Fee or arbitrator&rsquo;s services fee, excepting a $250.00 share of fees if you are the initiator of arbitration and travel costs for the arbitration being within three-hours of your hometown. If the company is the claiming party initiating the arbitration, the company will be required to pay all costs associated with the arbitration. You will be responsible for your own costs and attorney&rsquo;s fees outside of those identified in this section, regardless.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">The Arbitrator&rsquo;s Award will consist of a written statement stating the disposition of each claim. The award will also provide a concise written statement of the essential findings and conclusions on which the award is based. Barring extraordinary circumstances, the arbitrator will issue their decision within 120 days from the date the arbitrator is appointed. The arbitrator may extend this time limit for an additional sixty (60) days in the interests of justice. </span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">You acknowledge and understand that, with respect to any Dispute with us, you are giving up your right to have a trial by jury and you are giving up your right to serve as a representative of or to participate in a class of claimants in any lawsuit involving such dispute.</span>
    </p>
    <p class="c6 c12"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="9">
        <li class="c7 li-bullet-0"><span class="c4">Survival</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c1">If our relationship or these Terms terminate, it will not limit any of our other rights or remedies, and any provision of these Terms that must survive in order to give proper effect to the intent and purpose of these Terms will survive termination.</span>
    </p>
    <p class="c6 c12"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="10">
        <li class="c7 li-bullet-0"><span class="c4">Miscellaneous</span></li>
    </ol>
    <p class="c6"><span class="c4"></span></p>
    <p class="c0"><span class="c1">You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of the Terms or your use of the Services. The Terms constitute the entire agreement between you and us with respect to your use of the Services.</span>
    </p>
    <p class="c17 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">Our failure to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties&rsquo; intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">You may not assign, delegate, or otherwise transfer your account or your obligations under these Terms without our prior written consent. We have the right, in our sole discretion, to transfer or assign all or any part of our rights under these Terms and will have the right to delegate or use the third-party contractors to fulfill our duties and obligations under these Terms and in connection with the Services.</span>
    </p>
    <p class="c0 c11"><span class="c1"></span></p>
    <p class="c0"><span class="c1">Our notice to you via email, notices, or links displayed in connection with the Services constitutes acceptable notice to you under the Terms. We are not responsible for your failure to receive notice if email is quarantined by your email security system or if you fail to update your email address. Notice will be considered received forty-eight hours after it is sent if transmitted via email or regular mail. In the event that notice is provided via links displayed in connection with the Services, then it will be considered received twenty-four hours after it is first displayed.</span>
    </p>
    <p class="c6 c12"><span class="c1"></span></p>
    <ol class="c13 lst-kix_list_1-0" start="11">
        <li class="c7 li-bullet-0"><span class="c4">Contact Us</span></li>
    </ol>
    <p class="c6 c12"><span class="c4"></span></p>
    <p class="c0"><span class="c1">If you have any feedback, questions, or comments about the Services, please contact us at justinlcochran@brainmeter.org.</span>
    </p>
    
        <p class="c18 c12 c11"><span class="c1"></span></p>
        <p class="c6 c12"><span class="c1"></span></p>
        <p class="c6"><span class="c1"></span></p>
        <div><p class="c25 c33"><span class="c35">Last Updated June 2024</span></p></div>
    </body>
</html>`