import React from 'react';
import {useNavigate} from "react-router-dom";
import { signOut } from 'aws-amplify/auth';


function TeacherNavBar() {
    const navigate = useNavigate()
    const logo = require('../assets/transparentLogo.png')
    return (
        <div id={'teacherNav'}
             className={'flex justify-between bg-columbia_blue-800 border-dutch_white-600 border-b-4 mb-1'}>
            <button onClick={() => navigate('/teach')} className={"hover:bg-columbia_blue-700 px-4"}>
                <img onClick={() => navigate('/teach')} className={'h-[60px] w-[60px] cursor-pointer my-auto'}
                     src={String(logo)} alt={'Home'}/>

            </button>
            <button
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/design')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Design</p>
            </button>
            <button
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/dashboard')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Dashboard</p>
            </button>
            <button
                className={'flex items-center py-1 px-4 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 grow border-x border-dutch_white-800 align-items-middle'}
                onClick={() => navigate('/data')}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Data</p>
            </button>
            <button
                className={'flex items-center  max-w-min py-1 px-8 bg-columbia_blue-800 cursor-pointer hover:bg-columbia_blue-700 border-x border-dutch_white-800 align-items-middle'}
                onClick={() => signOut()}>
                <p className={'mx-auto text-dutch_white-300 font-bold'}>Sign Out</p>
            </button>
        </div>
    );
}

export default TeacherNavBar;