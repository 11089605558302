import React, {useEffect, useRef, useState} from 'react';
import ViewItem from "./viewItem";
import ViewAssignmentItem from "./viewAssignmentItem";
import postWithAuth from "../../context/postWithAuth";

function ViewAssessmentItemsModal({rosters, viewAssessmentItemsModal, setViewAssessmentItemsModal, resetFunction, user}) {
    const [selectedRosters, setSelectedRosters] = useState([]);
    const [assignment, setAssignment] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const onClose = () => {
        setViewAssessmentItemsModal(null)
    }

    const modalRef = useRef(null)

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose();
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };

    }, [viewAssessmentItemsModal, setViewAssessmentItemsModal, assignment, setAssignment]);

    const handleAssignClick = () => {
        if (window.confirm('Send currently selected question to selected rosters? Assignments will be immediately available and cannot be taken back from the database.')) {
            let body = {
                question: assignment,
                questionId: (typeof assignment._id) === 'string' ? assignment._id : assignment._id.$oid,
                students: selectedRosters.flatMap(item => item.students),
                rosterIds: selectedRosters.map(item => item._id.$oid),
                prep: viewAssessmentItemsModal[0].prep
            }
            setIsLoading(true);
            postWithAuth('/planning/postAssignment', user.userAttributes['custom:school'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body})
                .then((response) => {
                    if (response.ok) {
                        window.alert('Assignment successfully posted!')
                        setViewAssessmentItemsModal(null)
                    } else {
                        console.log(response.error)
                        setIsLoading(false);
                    }
                })
        } else {
            return null;
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    return (<>
            {(!assignment) ?
                <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 p-2 m-4 rounded max-h-[80%]"} ref={modalRef}>
                        <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>
                        <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                            <div className={'grid grid-cols-2 gap-2'}>
                                {viewAssessmentItemsModal.map(item => <ViewItem key={item._id.$oid} user={user} setViewAssessmentItemsModal={setViewAssessmentItemsModal} viewAssessmentItemsModal={viewAssessmentItemsModal} assessmentItem={item} setAssignment={setAssignment} resetFunction={resetFunction}/>)}
                            </div>
                        </div>
                    </div>
                </div>
                : <div>
                    <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
                        <div className={"relative bg-platinum-500 p-2 m-4 rounded h-max-[90%]"} ref={modalRef}>
                            <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                </svg>
                            </button>
                            <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                                <div className={'flex gap-2'}>
                                    <ViewAssignmentItem key={assignment._id.$oid} assessmentItem={assignment} setAssignment={setAssignment} />
                                    <div className={'flex flex-col'}>
                                        <p className={'text-dutch_white-300 text-2xl font-bold select-none mb-4'}>Select Rosters for Assignment:</p>
                                        <div className={'flex flex-wrap mb-4 select-none'}>
                                            {rosters.filter(roster => roster.prep === assignment.prep).sort((a, b) => a.title.localeCompare(b.title)).map(roster => (selectedRosters.includes(roster)) ?
                                                <button key={roster._id.$oid} className={'bg-columbia_blue-700 hover:bg-columbia_blue-600 text-dutch_white-300 font-bold rounded w-fit p-2 text-2xl mx-auto cursor-pointer'} onClick={() => setSelectedRosters(selectedRosters.filter(item => item._id.$oid !== roster._id.$oid))}>{roster.title}</button> :
                                                <button key={roster._id.$oid} className={'bg-platinum-800 hover:bg-platinum-700 text-dutch_white-300 font-bold rounded w-fit p-2 text-2xl mx-auto cursor-pointer'} onClick={() => setSelectedRosters([...selectedRosters, roster])}>{roster.title}</button>)}
                                        </div>
                                        <div className={'grow'}/>
                                        {(selectedRosters.length > 0) ? <button className={'bg-burnt_sienna-700 hover:bg-burnt_sienna-600 text-dutch_white-300 font-bold rounded w-fit p-2 text-lg mx-auto cursor-pointer'} onClick={handleAssignClick}>Assign to Selected Rosters</button> : <div className={'bg-platinum-800 text-dutch_white-300 font-bold rounded w-fit p-2 text-lg mx-auto select-none'}>Assign to Selected Rosters</div>}
                                        <div className={'grow'}/>
                                        <button className={'bg-columbia_blue-700 hover:bg-columbia_blue-600 text-dutch_white-300 font-bold h-fit p-2 rounded mx-auto select-none cursor-pointer'} onClick={() => setAssignment(null)}>Back to View Items</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10">
                            {/* Loader */}
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                        </div>
                    )}
                </div>}
        </>
    );
}

export default ViewAssessmentItemsModal;