import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import { Quill } from "react-quill";

const Delta = Quill.import('delta')

// Define your custom toolbar options, clipboard behavior, and keyboard handling
const modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ script: 'sub' }, { script: 'super' }]
    ],
};

const Editor = forwardRef(
    ({ readOnly, onTextChange, backgroundColor, placeholder }, ref) => {
        const containerRef = useRef(null);
        const onTextChangeRef = useRef(onTextChange);

        useLayoutEffect(() => {
            onTextChangeRef.current = onTextChange;
        });

        useEffect(() => {
            ref.current?.enable(!readOnly);
        }, [ref, readOnly]);

        useEffect(() => {
            const container = containerRef.current;

            // Set background color if provided
            container.style.backgroundColor = backgroundColor || 'white';

            const editorContainer = container.appendChild(
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                theme: 'snow',
                modules,

                placeholder: placeholder || 'Start typing...', // Set dynamic placeholder
            });

            ref.current = quill;

            if (quill.keyboard) {
                //To prevent backspace event
                //To prevent tab
                quill.keyboard.bindings[9]?.unshift({
                    key: 9,
                    handler: (range, index) => {
                        const focusableElements = Array.from(container.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'));
                        const currentIndex = focusableElements.indexOf(document.activeElement);
                        console.log(currentIndex)
                        const nextIndex = currentIndex + 1;
                        focusableElements[nextIndex].focus();
                        return false;
                    },
                });
            }

            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                const plainOps = delta.ops.map(op => ({ insert: op.insert }));
                return new Delta(plainOps);
            });

            quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {
                const content = quill.root.innerHTML;  // Get the HTML content
                onTextChangeRef.current?.(content, delta, source, quill);
            });

            quill.keyboard.addBinding({ key: 'tab' }, function(range) {
                // I will normally prevent handlers of the tab key
                // Return true to let later handlers be called
                // Handle Tab key press
                const focusableElements = Array.from(container.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'));
                const currentIndex = focusableElements.indexOf(document.activeElement);
                const nextIndex = (currentIndex + 1) % focusableElements.length;
                focusableElements[nextIndex].focus();
                return false; // Prevent tab character insertion
            });

            const handleKeyDown = (e) => {
                if (e.key === 'tab') {
                    e.preventDefault(); // Prevent tab character insertion
            }};

            // Add keydown event listener to intercept Tab key
            editorContainer.addEventListener('keydown', handleKeyDown);

            return () => {
                ref.current = null;
                container.innerHTML = '';
            };
        }, [ref, backgroundColor, placeholder]);

        return <div ref={containerRef}></div>;
    },
);

Editor.displayName = 'Editor';

export default Editor;