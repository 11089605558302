import React, {useState} from 'react';
import ImageMCScrambledPreview from "../data/imageMCScrambledPreview";
import TraditionalMCScrambledPreview from "../data/traditionalMCScrambledPreview";
import MatchingScrambledPreview from "../data/matchingScrambledPreview";
import SortingScrambledPreview from "../data/sortingScrambledPreview";
import putWithAuth from "../../context/putWithAuth";

function ViewItem({user, setViewAssessmentItemsModal, viewAssessmentItemsModal, assessmentItem, setAssignment, resetFunction}) {
    const [isLoading, setIsLoading] = useState(false);

    const handleRetireClick = async () => {
        if (window.confirm('Are you sure you want to retire this assessment item? It will be removed for all teachers in your school.')) {
            setIsLoading(true)
            putWithAuth('/planning/putRetireAssessmentItem', user.userAttributes["custom:school"], {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {assessmentItemId: assessmentItem._id.$oid}})
                .then(async response => {
                    if (response.ok) {
                        await resetFunction(user);
                        setViewAssessmentItemsModal(viewAssessmentItemsModal.filter(item => item._id.$oid !== assessmentItem._id.$oid))
                        setIsLoading(false)
                    } else {
                        console.error('Failed to retire');
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.error('Error retiring assessment item:', error);
                });
        } else {
            return null;
        }
    }

    return (
        <>
            {(Object.keys(assessmentItem).includes('type')) &&  (assessmentItem.type === 'Image MC') ?
                <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                    <div className={'flex flex-col gap-2 bg-columbia_blue-400 p-2 rounded w-full'}>
                        <div className={'grow'}/>
                        <ImageMCScrambledPreview assessmentItem={assessmentItem}/>
                    </div>
                    <div className={'grow'}/>
                    <div className={'flex justify-between mt-2'}>
                        <button
                            className={'p-4 font-bold bg-burnt_sienna w-fit text-sm rounded border-2 border-dutch_white-900 text-dutch_white-900 select-none hover:bg-burnt_sienna-600 cursor-pointer'}
                            onClick={handleRetireClick}>Retire
                        </button>
                        <button
                            className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                            onClick={() => setAssignment(assessmentItem)}>Assign
                        </button>
                    </div>
                </div>
                : (assessmentItem.type === 'Sorting') ?
                    <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                        <div className={'grow'}/>
                        <SortingScrambledPreview assessmentItem={assessmentItem}/>
                        <div className={'grow'}/>
                        <div className={'flex justify-between mt-2'}>
                            <button
                                className={'p-4 font-bold bg-burnt_sienna w-fit text-sm rounded border-2 border-dutch_white-900 text-dutch_white-900 select-none hover:bg-burnt_sienna-600 cursor-pointer'}
                                onClick={handleRetireClick}>Retire
                            </button>
                            <button
                                className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                onClick={() => setAssignment(assessmentItem)}>Assign
                            </button>
                        </div>
                    </div>
                    : (assessmentItem.type === 'Matching') ?
                        <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                            <div className={'grow'}/>
                            <MatchingScrambledPreview assessmentItem={assessmentItem}/>
                            <div className={'grow'}/>
                            <div className={'flex justify-between mt-2'}>
                                <button
                                    className={'p-4 font-bold bg-burnt_sienna w-fit text-sm rounded border-2 border-dutch_white-900 text-dutch_white-900 select-none hover:bg-burnt_sienna-600 cursor-pointer'}
                                    onClick={handleRetireClick}>Retire
                                </button>
                                <button
                                    className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                    onClick={() => setAssignment(assessmentItem)}>Assign
                                </button>
                            </div>
                        </div>
                        :
                        <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                            <div className={'grow'}/>
                            <div className={'p-1 bg-dutch_white-400 rounded shadow'}>
                                <div className={'flex flex-col gap-2 bg-dutch_white-400 p-2 rounded mx-auto'}>
                                    <TraditionalMCScrambledPreview assessmentItem={assessmentItem}/>
                                </div>

                            </div>
                            <div className={'grow'}></div>
                            <div className={'flex justify-between mt-2'}>
                                <button
                                    className={'p-4 font-bold bg-burnt_sienna w-fit text-sm rounded border-2 border-dutch_white-900 text-dutch_white-900 select-none hover:bg-burnt_sienna-600 cursor-pointer'}
                                    onClick={handleRetireClick}>Retire
                                </button>
                                <button
                                    className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                    onClick={() => setAssignment(assessmentItem)}>Assign
                                </button>
                            </div>

                        </div>}
            {(isLoading) && <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
            </div>}
        </>
    );
}

export default ViewItem;