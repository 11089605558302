import React from 'react';
import {Cell, Pie, PieChart, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;
const data = [
    { name: 'Reteach', value: 80, color: '#c25022' },
    { name: 'Check-in', value: 45, color: '#538ea5' },
    { name: 'Move On', value: 25, color: '#f7f7f7' },
];
const cx = 60;
const cy = 80;
const iR = 40;
const oR = 60;
const value = 50;

const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 6;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
        <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
    ];
};

export default function OverallStandardSpeedometer({value, data}) {
    return (
            <PieChart width={140} height={100}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx={cx}
                    cy={cy}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill="#8884d8"
                    stroke="none"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))}
                </Pie>
                {needle(value, data, cx, cy, iR, oR, '#4f6d7a')}
            </PieChart>
    );
}
