import React, {useState} from 'react';
import TipTap from "../../components/tipTap";

function EditStandardSetModal({standardSet, setReviewAndEditStandardSetsModal}) {
    const onClose = () => {
        setReviewAndEditStandardSetsModal(null)
    }

    const [questionText, setQuestionText] = useState(null);

    console.log(questionText)

    const handleClose = (e) => {
        if ( e.target.id === "wrapper2" ) onClose();
    }

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex flex-col z-10"} id="wrapper2" onClick={handleClose}>
            <div className={"relative bg-dutch_white-200 m-4 rounded p-2 max-h-[92%] min-w-[70%]"}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-300 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={"relative text-black mb-2 cursor-black max-h-[100%] overflow-y-auto grid grid-cols-2 gap-2"}>
                    <p>{standardSet.prepTitle}</p>
                    <TipTap placeholder={'Question...'} backgroundColor={'bg-columbia_blue-300'} fontSize={'text-lg'} textColor={'text-columbia_blue-900'} setHtmlOutput={setQuestionText}/>
                </div>
            </div>
        </div>
            )
            }

export default EditStandardSetModal;