import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

export default function StandardElementsPieChart({ data }) {

    const sum = data.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.value;
    }, 0);

    const adjustedData = data.map(item => ({
        name: item.name,
        value: Math.round((item.value / sum) * 100), // Calculate percentage
        fill: item.fill
    }));

    // Custom tooltip content function
    const renderCustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip p-1 bg-columbia_blue-200 border-2 border-dutch_white rounded">
                    <p style={{color: payload[0].payload.fill}}>{`${payload[0].name}: ${payload[0].value}%`}</p>
                </div>
            );
        }
        return null;
    };

    function renderCustomLabelLine({ stroke, points, payload }) {
        const { value } = payload;

        // Don't render the line if the value is 0
        if (value === 0) {
            return null;
        }

        // Convert points array to a string in "x,y" format
        const pointsString = points.map(point => `${point.x},${point.y}`).join(' ');

        // Render the polyline with the correct properties
        return <polyline stroke={stroke} fill="none" points={pointsString} />;
    }


    function renderCustomLabel({cx, cy, midAngle, innerRadius, outerRadius, percent, index, name}) {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (percent === 0) {
            return null;
        }

        return (
            <text
                x={x}
                y={y}
                fill="#375e6e" // Fixed label color
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${name}: ${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }

    return (
        <ResponsiveContainer width="100%" height={250}>
            <PieChart aria-label={`Results: ${adjustedData.map(item => `${item.value} percent ${item.name},`)}`}>
                <Pie
                    data={adjustedData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={50}
                    fill="#8884d8"
                    labelLine={renderCustomLabelLine}
                    label={renderCustomLabel}
                    // Remove the label prop to avoid cluttering the chart
                >
                    {adjustedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} />
                    ))}
                </Pie>
                <Tooltip content={renderCustomTooltip} />
            </PieChart>
        </ResponsiveContainer>
    );
}