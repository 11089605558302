import React, {useState} from 'react';
import postWithAuth from "../../context/postWithAuth";

function DeployStandardSetsModal({ setDeployStandardSetsModal, standardSetTemplates, school}) {
    const [isLoading, setIsLoading] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [subject, setSubject] = useState(null);
    const [title, setTitle] = useState(null);
    const [selectedStandards, setSelectedStandards] = useState([]);
    const [titlesUsed, setTitlesUsed] = useState(null);

    const onClose = () => {
        setDeployStandardSetsModal(null)

    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const subjects = ['English', 'Math', 'Science', 'Social Studies', 'Elective / Other']

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const postDeployStandardSets = async () => {
        let body = {
            templateTitle: (selectedTemplate !== 'scratch') ? selectedTemplate.title : title,
            title: title,
            subject: subject,
            standards: (selectedTemplate !== 'scratch') ? selectedTemplate.standards.filter(item => selectedStandards.includes(item)) : [],
            school: school
        }
        setIsLoading(true);
        postWithAuth('/accountmanagement/postDeployStandardSet', school,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body})
            .then(async (response) => {
                if (response.ok) {
                    window.alert('Standard Set Deployed')
                    setIsLoading(false)
                    setDeployStandardSetsModal(false)
                } else {
                    if (response.status === 499) {
                        window.alert('This title overlaps with an existing title in the database. Please select a unique title and try again. ');
                        const data = await response.json();
                        setTitlesUsed(data.titles)
                        setIsLoading(false);
                    }
                    console.log(response.error)
                    setIsLoading(false);
                }
            })
    }

    const handleSubjectChange = (e) => {
        setSubject(e.target.value)
    }

    return (
        <>
            <div>
                <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 p-2 m-4 rounded max-h-[90%]"}>
                        <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>
                        <div className={'p-2 bg-columbia_blue-800 flex h-full'}>
                            <div className={'bg-columbia_blue-800 flex mr-2'}>
                                <div className={'w-[100%] bg-platinum-900 rounded-lg p-1'}>
                                    <p className={'font-bold text-xl text-dutch_white-300'}>Standard Set
                                        Templates:</p>
                                    <div
                                        className={'bg-platinum-900 p-4 flex flex-col overflow-y-scroll gap-1 max-h-[95%] w-full rounded'}>

                                        {(standardSetTemplates) && standardSetTemplates.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())).map(standardSet =>
                                            <div id={standardSet._id.$oid}
                                                 className={'p-2 bg-columbia_blue rounded h-fit select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                                 onClick={() => {
                                                     setSelectedTemplate(standardSet);
                                                     setSubject(standardSet.subject)
                                                     setSelectedStandards([]);
                                                 }}>
                                                <p id={standardSet._id.$oid}>{standardSet.title}</p>
                                            </div>)
                                        }
                                        <div id={'scratch'}
                                             className={'p-2 bg-columbia_blue-700 rounded h-fit select-none hover:bg-columbia_blue-800 text-dutch_white-300 cursor-pointer'}
                                             onClick={() => {
                                                 setSelectedTemplate('scratch');
                                                 setSubject('English')
                                                 setSelectedStandards([]);
                                             }}>
                                            <p id={'scratch'}>Start from Scratch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                                {(selectedTemplate) ? <>
                                        <div className={'flex-col'}>
                                            {(selectedTemplate === 'scratch') ?
                                                <>
                                                    <div className={'flex gap-2'}>
                                                        <div className={'mx-auto flex gap-4 h-fit'}>
                                                            <p className={'text-dutch_white-300 font-bold text-xl my-auto '}>Content
                                                                Type:</p>
                                                            <select onChange={handleSubjectChange}
                                                                    className={'bg-columbia_blue-300 my-auto p-2 rounded'}>
                                                                {subjects.map(content => (<option value={content}
                                                                                                  key={content}>{content}</option>))}
                                                            </select>

                                                        </div>

                                                    </div>
                                                    <div className={'flex gap-2 mb-4'}>
                                                        <div className={'mx-auto flex flex-col'}>
                                                            <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Standard
                                                                Set
                                                                Title:</p>
                                                            <p className={'text-xs text-dutch_white-300 mb-1'}>This title is student
                                                                facing!</p>

                                                            <textarea onChange={handleTitleChange}
                                                                      className={'text-xl font-bold rounded grow p-4 w-[500px] bg-dutch_white-100 text-columbia_blue-800 resize-none'}/>
                                                            {(titlesUsed) && <><p className={'text-dutch_white-300'}>Existing Standard Set Titles:</p>
                                                                <div className={'flex flex-wrap gap-1 w-[500px] my-1 select-none'}>
                                                                    {titlesUsed.map(item => <div className={'p-2 bg-burnt_sienna-300 rounded shadow'}>{item}</div>)}
                                                                </div>
                                                            </>}

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>

                                                    <div className={'flex gap-2 mb-4'}>
                                                        <div className={'mx-auto flex flex-col'}>
                                                            <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Standard
                                                                Set
                                                                Title:</p>
                                                            <p className={'text-xs text-dutch_white-300 mb-1'}>This title is student
                                                                facing!</p>

                                                            <textarea onChange={handleTitleChange}
                                                                      className={'text-xl font-bold rounded grow p-4 w-[500px] bg-dutch_white-100 text-columbia_blue-800 resize-none'}/>
                                                            {(titlesUsed) && <><p className={'text-dutch_white-300'}>Existing Standard Set Titles:</p>
                                                                <div className={'flex flex-wrap gap-1 w-[500px] my-1 select-none'}>
                                                                    {titlesUsed.map(item => <div className={'p-2 bg-burnt_sienna-300 rounded shadow'}>{item}</div>)}
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </div>

                                                    <div className={'flex-col gap-2 max-w-[900px] overflow-y-auto rounded p-2'}>
                                                        <div className={'grid grid-cols-7 mb-2'}>
                                                            <p className={'col-start-3 col-span-3 text-dutch_white-300 font-bold mt-2 text-xl'}>Select
                                                                Standards:</p>
                                                            <div onClick={() => setSelectedStandards(selectedTemplate.standards)}
                                                                 className={'col-start-7 rounded text-sm bg-columbia_blue-500 p-2 select-none cursor-pointer hover:bg-columbia_blue-600'}>
                                                                Select All
                                                            </div>

                                                        </div>
                                                        <div className={'grid grid-cols-6 gap-2'}>
                                                            {selectedTemplate.standards.map(standard => (selectedStandards.includes(standard)) ?

                                                                <div onClick={() => setSelectedStandards(selectedStandards.filter(item => item !== standard))} className={'bg-columbia_blue-700 p-1 rounded border-paynes_gray-300 border-2 cursor-pointer hover:bg-burnt_sienna-500'}>
                                                                    <div className={'h-full flex flex-col text-dutch_white-300'}>
                                                                        <div className={'grow'}/>
                                                                        <p className={'select-none font-bold border-b-1 border-paynes_gray-300'}>{standard.code}</p>
                                                                        <p className={'select-none'}>{standard.title}</p>
                                                                        <div className={'grow'}/>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div onClick={() => setSelectedStandards([...selectedStandards, standard])} className={'bg-platinum-700 p-1 rounded border-columbia_blue-600 border-2 cursor-pointer hover:bg-platinum-600'}>
                                                                    <div className={'h-full flex flex-col '}>
                                                                        <div className={'grow'}/>
                                                                        <p className={'select-none font-bold border-b-1 border-paynes_gray-300'}>{standard.code}</p>
                                                                        <p className={'select-none'}>{standard.title}</p>
                                                                        <div className={'grow'}/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>}
                                        </div>
                                    </>
                                    :
                                    <div className={"h-full flex animate-bounce text-dutch_white-300"}
                                         style={{animation: 'bounce-horizontally 3s ease-in-out infinite'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                             width="200" height="300" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-arrow-left my-auto">
                                            <line x1="19" y1="12" x2="5" y2="12"></line>
                                            <polyline points="12 19 5 12 12 5"></polyline>
                                        </svg>
                                        <p className={"my-auto text-4xl"}>Select a Template</p>
                                    </div>
                                }

                                <button onClick={postDeployStandardSets}
                                        className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-burnt_sienna text-white font-bold px-10 text-xl hover:bg-burnt_sienna-600">
                                    <p>Deploy Standard Set</p>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            {
                isLoading && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10">
                        {/* Loader */}
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                    </div>
                )
            }
        </>
    )
        ;
}

export default DeployStandardSetsModal;