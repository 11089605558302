import '@aws-amplify/ui-react/styles.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';

import {UserContextProvider} from "./context/userContext";
import Splash from "../src/splash";
import TeacherNavBar from "../src/teacher/teacherNavBar";
import Planning from "../src/teacher/planning/planning";
import Logistics from "../src/teacher/logistics/logistics";
import Data from "../src/teacher/data/data";
import Home from "../src/student/home/home";
import MyData from "../src/student/myData/myData";
import StudentNavBar from "./student/studentNavBar";
import CoordinatorNavBar from "./coordinator/coordinatorNavBar";
import AccountManagement from "./coordinator/accountManagement/accountManagement";
import AssessmentPlanning from "./coordinator/assessmentPlanning/assessmentPlanning";
import SchoolData from "./coordinator/schoolData/schoolData";
import Redirect from "./redirect";
import Footer from "./components/footer";
import ToSPage from "./components/tosPage";
import PrivacyPage from "./components/privacyPage";
import AcknowledgmentsPage from "./components/acknowledgmentsPage";
import TeacherHome from "./teacher/teacherHome";
import DesignFetchLayer from "./teacher/planning/designFetchLayer";
import DesignFetchLayerCoord from "./coordinator/assessmentPlanning/designFetchLayerCoord";
import DataFetchLayer from "./teacher/data/dataFetchLayer";
import DataFetchLayerCoord from "./coordinator/schoolData/dataFetchLayerCoord";
import DashboardFetchLayer from "./teacher/logistics/dashboardFetchLayer";
import CoordinatorHome from "./coordinator/coordinatorHome";

Amplify.configure(awsconfig)

function App() {

  return (
      <div className="App">
          <BrowserRouter>
            <UserContextProvider>
                <Routes>
                    <Route path='/' element={<Redirect />}/>
                    <Route path='/teach' element={<TeacherHome />}/>
                    <Route path='/design' element={<><DesignFetchLayer /><Footer /></>}/>
                    <Route path='/dashboard' element={<><TeacherNavBar /><DashboardFetchLayer /><Footer /></>}/>
                    <Route path='/data' element={<><TeacherNavBar /><DataFetchLayer /><Footer /></>}/>
                    <Route path='/home' element={<><StudentNavBar /><Home /><Footer /></>}/>
                    <Route path='/coordinate' element={<CoordinatorHome />}/>
                    <Route path='/accountmanagement' element={<><CoordinatorNavBar /><AccountManagement /><Footer /></>}/>
                    <Route path='/designcoord' element={<><DesignFetchLayerCoord /><Footer /></>}/>
                    <Route path='/datacoord' element={<><CoordinatorNavBar /><DataFetchLayerCoord /><Footer /></>}/>
                    <Route path='/terms' element={<ToSPage />}/>
                    <Route path='/privacy' element={<PrivacyPage />}/>
                    <Route path='/acknowledgments' element={<AcknowledgmentsPage />}/>
                </Routes>

            </UserContextProvider>
          </BrowserRouter>
      </div>
  );
}

export default withAuthenticator( App, {
    hideSignUp: true
});
