import React from 'react';
import ImageMCScrambledPreview from "../data/imageMCScrambledPreview";
import TraditionalMCScrambledPreview from "../data/traditionalMCScrambledPreview";
import MatchingScrambledPreview from "../data/matchingScrambledPreview";
import SortingScrambledPreview from "../data/sortingScrambledPreview";

function ViewReteachItem({user, setReteachModal, reteachModal, assessmentItem, assignReteachItem}) {

    return (
        <>
            {(Object.keys(assessmentItem).includes('type')) &&  (assessmentItem.type === 'Image MC') ?
                <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                    <div className={'flex flex-col gap-2 bg-columbia_blue-400 p-2 rounded w-full'}>
                        <div className={'grow'}/>
                        <ImageMCScrambledPreview assessmentItem={assessmentItem}/>
                    </div>
                    <div className={'grow'}/>
                    <div className={'flex justify-center mt-2'}>
                        <div
                            className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                            onClick={() => assignReteachItem(assessmentItem)}>Assign
                        </div>
                    </div>
                </div>
                : (assessmentItem.type === 'Sorting') ?
                    <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                        <div className={'grow'}/>
                        <SortingScrambledPreview assessmentItem={assessmentItem}/>
                        <div className={'grow'}/>
                        <div className={'flex justify-center mt-2'}>
                            <div
                                className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                onClick={() => assignReteachItem(assessmentItem)}>Assign
                            </div>
                        </div>
                    </div>
                    : (assessmentItem.type === 'Matching') ?
                        <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                            <div className={'grow'}/>
                            <MatchingScrambledPreview assessmentItem={assessmentItem}/>
                            <div className={'grow'}/>
                            <div className={'flex justify-center mt-2'}>
                                <div
                                    className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                    onClick={() => assignReteachItem(assessmentItem)}>Assign
                                </div>
                            </div>
                        </div>
                        :
                        <div className={'flex flex-col bg-paynes_gray-700 p-2 rounded max-w-[400px]'}>
                            <div className={'grow'}/>
                            <div className={'p-1 bg-dutch_white-400 rounded shadow'}>
                                <div className={'flex flex-col gap-2 bg-dutch_white-400 p-2 rounded mx-auto'}>
                                    <TraditionalMCScrambledPreview assessmentItem={assessmentItem}/>
                                </div>

                            </div>
                            <div className={'grow'}></div>
                            <div className={'flex justify-center mt-2'}>
                                <div
                                    className={'p-4 font-bold bg-columbia_blue w-fit text-sm rounded border-2 border-dutch_white-200 text-columbia_blue-900 select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                    onClick={() => assignReteachItem(assessmentItem)}>Assign
                                </div>
                            </div>

                        </div>}
        </>
    );
}

export default ViewReteachItem;