import React from 'react';

function Loader({message}) {
    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
            {/* Loader */}
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
            <p className={'font-bold mt-8 text-dutch_white-300'}>{message}</p>
        </div>
    );
}

export default Loader;