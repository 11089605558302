import React from 'react';
import DOMPurify from "dompurify";

function SortingHoverStudentTooltip({data}) {

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const colors = ['#a976da', '#6668ee', '#6b5d56', '#4ba14d']

    return (
        <div className={"bg-columbia_blue-500 p-1 rounded text-xs"}>
            <p className={'text-dutch_white-900'}>{data.name}'s Response:</p>
            <div className={'flex gap-2 w-full overflow-x-auto'}>
                <div className={'grow'}/>
                {data.response.sortingOutcome.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) =>
                    <div className={'rounded shadow my-2 px-4 flex flex-col py-2'} style={{background: colors[index]}}>
                        <div
                            className={"bg-dutch_white-500 border-b-2 border-dutch_white-600 shadow w-[200px] font-bold text-dutch_white-900 p-2 border-1 rounded-t text-h-fit mx-auto"}>
                            <div dangerouslySetInnerHTML={renderHtml(item.title)}/>
                        </div>
                        <div className={'flex flex-col'}>
                            <div
                                className={'flex flex-wrap gap-1 w-[200px] shadow-inner bg-dutch_white-100 rounded-b min-h-[50px] max-h-[250px] overflow-y-auto px-2'}>
                                {item.correct.map(entry => entry.type === 'text' ? <div
                                            className={"p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-100 border-2"} style={{background: colors[index]}}>
                                            <div className={'text-dutch_white-200'}
                                                 dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                        </div>
                                        : <div
                                            className={"p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-200 border-2"} style={{background: colors[index]}}>
                                            <img draggable={false} src={entry.content}
                                                 className={"rounded max-h-[100px]"}/>
                                        </div>
                                )}
                                {item.incorrect.map(entry => entry.type === 'text' ? <div
                                            className={"p-2 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-200 border-2"} style={{background: colors[data.response.sortingOutcome.indexOf(data.response.sortingOutcome.find(item => item.missing.map(item => item.content).includes(entry.content)))]}}>
                                            <div className={'text-dutch_white-200'}
                                                 dangerouslySetInnerHTML={renderHtml(entry.content)}/>
                                        </div>
                                        : <div
                                            className={"p-2 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-dutch_white-200 border-2"} style={{background: colors[data.response.sortingOutcome.indexOf(data.response.sortingOutcome.find(item => item.missing.map(item => item.content).includes(entry.content)))]}}>
                                            <img draggable={false} src={entry.content}
                                                 className={"rounded max-h-[100px]"}/>
                                        </div>
                                )}

                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}

export default SortingHoverStudentTooltip;