import React from 'react';

function ImageMCAnswerDistribution({ question, responses, studentData }) {

    return (
        <div className={'grid grid-cols-4 gap-2'}>
            <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold mb-2 border-b-2 border-dutch_white'}>Correct</p>
                <img className={'max-w-[95%] p-2 rounded bg-dutch_white mx-auto mb-2'} src={question.correct}  alt={'correct'}/>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {responses.filter(questionResponse => questionResponse.response === question.correct)
                        .map(item => (
                            <div className={'p-1 bg-dutch_white-400 text-burnt_sienna-900 rounded'}>
                                <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>
                            </div>))}
                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold mb-2 border-b-2 border-dutch_white'}>Distractor 1</p>
                <img className={'max-w-[95%] p-2 rounded bg-dutch_white mx-auto mb-2'} src={question.distractor1} alt={'distractor1'}/>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {responses.filter(questionResponse => questionResponse.response === question.distractor1)
                        .map(item => (
                            <div className={'p-1 bg-burnt_sienna-200 text-burnt_sienna-900 rounded'}>
                                <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>
                            </div>))}
                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold mb-2 border-b-2 border-dutch_white'}>Distractor 2</p>
                <img className={'max-w-[95%] p-2 rounded bg-dutch_white mx-auto mb-2'} src={question.distractor2} alt={'distractor2'}/>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                {responses.filter(questionResponse => questionResponse.response === question.distractor2)
                        .map(item => (
                            <div className={'p-1 bg-burnt_sienna-200 text-burnt_sienna-900 rounded'}>
                                <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>
                            </div>))}
                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold mb-2 border-b-2 border-dutch_white'}>Distractor 3</p>
                <img className={'max-w-[95%] p-2 rounded bg-dutch_white mx-auto mb-2'} src={question.distractor3} alt={'distractor3'}/>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {responses.filter(questionResponse => questionResponse.response === question.distractor3)
                        .map(item => (
                            <div className={'p-1 bg-burnt_sienna-200 text-burnt_sienna-900 rounded'}>
                                <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>
                            </div>))}
                </div>
            </div>
        </div>
    );
}

export default ImageMCAnswerDistribution;