import React, {useState} from 'react';
import InteractiveSearch from "../../components/interactiveSearch";
import putWithAuth from "../../context/putWithAuth";

function ModifyTeacherPrepsModal({setModifyTeacherPrepsModal, preps, school, teacherContext}) {
    const [isLoading, setIsLoading] = useState(null);
    const [activePreps, setActivePreps] = useState([]);
    const [teacherFilterText, setTeacherFilterText] = useState(null);
    const [selectedTeacher, setSelectedTeacher] = useState(null);

    const handleFilterChange = (e) => {
        setTeacherFilterText(e.target.value);
    }

    const onClose = () => {
        if (activePreps.length > 0) {

            if (window.confirm('Are you sure? All progress will be lost')) {
                setModifyTeacherPrepsModal(null);
            }
        } else {
            setModifyTeacherPrepsModal(null);
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleCourseAdd = (e) => {
        setActivePreps([...activePreps, e.target.id])
    }

    const handleCourseRemove = (e) => {
        setActivePreps(activePreps.filter(item => item !== e.target.id))
    }

    const putChangeTeacherPreps = async () => {
        if (window.confirm('Check to make sure all necessary preps have been added to the teacher account')) {
            let body = {
                userData: {
                    teacherId: selectedTeacher._id.$oid,
                    activePreps: activePreps
                },
                school: school
            }
            setIsLoading(true);
            putWithAuth("/accountmanagement/putModifyTeacherPreps", school, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body},
                school)
                .then((response) => {
                    console.log(response)
                    if (response.ok) {
                        window.alert('Teacher preps successfully modified.')
                        setIsLoading(false)
                        setModifyTeacherPrepsModal(false)
                    } else {
                        console.log(response.error)
                        setIsLoading(false);
                    }
                })
        } else {
            return null;
        }
    }


    return (
        <>
            <div>
                <div
                    className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"}
                    id="wrapper" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 p-2 m-4 rounded max-h-[85%] w-[90%]"}>
                        <button onClick={onClose}
                                className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff"
                                      d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>

                        <div className={'p-2 bg-columbia_blue-700 flex flex-col h-full overflow-auto rounded'}>
                            <div className={'flex p-2'}>
                                <div className={'bg-columbia_blue-800 flex mr-2 min-w-[200px]'}>
                                    <div className={'w-[100%] bg-platinum-900 rounded-lg max-h-screen p-1'}>
                                        <InteractiveSearch placeholder={'Search for Teachers'}
                                                           handleChange={handleFilterChange}/>
                                        <div
                                            className={'bg-platinum-900 p-4 flex flex-col gap-1 w-full rounded'}>

                                            {(teacherFilterText) ? <>
                                                    {teacherContext.filter(teacher => teacher.active).filter(item => item.firstName.toLowerCase().includes(teacherFilterText.toLowerCase()) || item.lastName.toLowerCase().includes(teacherFilterText.toLowerCase())).sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())).map(teacher =>
                                                        <div id={teacher._id.$oid}
                                                             className={'p-2 bg-columbia_blue rounded h-fit select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                                             onClick={() => setSelectedTeacher(teacher)}>
                                                            <p id={teacher._id.$oid}>{teacher.firstName} {teacher.lastName}</p>
                                                        </div>)}
                                                </>
                                                : <>
                                                    {teacherContext.filter(teacher => teacher.active).sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase())).map(teacher =>
                                                        <div id={teacher._id.$oid}
                                                             className={'p-2 bg-columbia_blue rounded h-fit select-none hover:bg-columbia_blue-600 cursor-pointer'}
                                                             onClick={() => setSelectedTeacher(teacher)}>
                                                            <p id={teacher._id.$oid}>{teacher.firstName} {teacher.lastName}</p>
                                                        </div>)}
                                                </>}
                                        </div>
                                    </div>
                                </div>
                                <div className={'flex flex-col w-full'}>
                                    {(selectedTeacher) ? <div className={'bg-dutch_white-400 rounded'}>
                                            <p className={'text-4xl font-bold text-columbia_blue-900'}>{selectedTeacher.firstName} {selectedTeacher.lastName}</p>
                                            <div className={'grid grid-cols-2 gap-2 m-2 bg-columbia_blue-800 rounded p-2'}>
                                                <div className={'flex-col gap-2'}>
                                                    <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Current Course List</p>
                                                    <div className={'flex flex-col gap-2'}>
                                                        {selectedTeacher.preps.map(item =>
                                                            <div
                                                                id={item} onClick={handleCourseAdd}
                                                                className={'rounded text-dutch_white-200 font-bold text-lg bg-columbia_blue-600 select-none p-1'}>{item}</div>)}
                                                    </div>
                                                </div>
                                                <div className={'flex-col gap-2'}>
                                                    <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Revised Course List</p>
                                                    {(activePreps) && activePreps.map(item => <div id={item}
                                                                                                   onClick={handleCourseRemove}
                                                                                                   className={'rounded text-dutch_white-200 p-1 font-bold text-lg bg-columbia_blue-700 hover:bg-burnt_sienna-600 cursor-pointer select-none mb-2 px-2'}>{item}</div>)}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={"h-fit flex animate-bounce text-dutch_white-300"}
                                             style={{animation: 'bounce-horizontally 3s ease-in-out infinite'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 width="200" height="300" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-arrow-left my-auto">
                                                <line x1="19" y1="12" x2="5" y2="12"></line>
                                                <polyline points="12 19 5 12 12 5"></polyline>
                                            </svg>
                                            <p className={"my-auto text-4xl"}>Select a Teacher</p>
                                        </div>}
                                    <div className={'flex gap-2 mb-4 w-full '}>
                                        <div className={'flex-col gap-2 mx-auto'}>
                                            <p className={'text-dutch_white-300 font-bold mt-2 text-xl'}>Preps Available:</p>
                                            <div className={'flex flex-wrap gap-2'}>
                                                {preps.filter(item => (!activePreps.includes(item))).sort((a, b) => a.localeCompare(b)).map(item => <div
                                                    id={item} onClick={handleCourseAdd}
                                                    className={'rounded text-dutch_white-200 font-bold text-lg bg-columbia_blue-600 hover:bg-columbia_blue-500 cursor-pointer select-none px-2'}>{item}</div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                    <button onClick={putChangeTeacherPreps}
                            className="relative self-center rounded bottom-12 align-middle m-2 p-2 bg-burnt_sienna text-white font-bold px-10 text-xl hover:bg-burnt_sienna-600">
                        <p>Modify Teacher Preps</p>
                    </button>
                </div>

            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </>
    );
}

export default ModifyTeacherPrepsModal;