import React from 'react';
import DOMPurify from "dompurify";

function RecallResultView({ questionModalContent, answerView}) {

    let questionHtml = DOMPurify.sanitize(questionModalContent.recallTitle)

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (<>
            {(answerView.result === 0) ?
                <div className={"bg-columbia_blue-800 max-h-full overflow-y-auto p-2 rounded"}>
                    <p className={'text-dutch_white-300 font-bold text-2xl'}>Correct!</p>
                    <p className={'text-dutch_white-300 mb-2'}>Nice! You've mastered this one!</p>
                    <div className={"bg-columbia_blue-500 p-4 rounded"}>
                        <div className={"bg-dutch_white-300 text-lg p-4  border-dutch_white-600 border-2 rounded"}>{<div dangerouslySetInnerHTML={renderHtml(questionModalContent.prompt)} />}</div>
                        <div className={"bg-columbia_blue-700 p-2 my-3 flex flex-col w-3/4 mx-auto rounded border-columbia_blue-800 border-2 gap-4"}>
                            {answerView.correctResponse.map(item =>
                                <div className={'flex'}>
                                    <div className={'border-dutch_white-400 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded font-bold w-fit h-fit my-auto'}>
                                        {(item.prompt.type === 'image') ? <img src={item.prompt.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />}
                                    </div>
                                    <div className={'grow border-b-4 border-dotted border-columbia_blue-800 my-auto min-w-[20px]'} />
                                    <div className={'border-dutch_white-700 border-2 shadow bg-dutch_white-400 text-dutch_white-800 p-1 rounded w-fit h-fit my-auto'}>
                                        {(item.match.type === 'image') ? <img src={item.match.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.match.content)} />}
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
                :
                <div className={"bg-burnt_sienna-600 max-h-full overflow-y-auto p-2 rounded"}>
                    <p className={'text-dutch_white-200 font-bold text-2xl'}>Incorrect</p>
                    <p className={'mb-2 text-dutch_white-200'}>You've got some learning left to do: review the differences below for next time!</p>
                    <div className={"bg-columbia_blue-500 p-4 rounded"}>
                        <div className={"bg-dutch_white-300 text-lg p-4 border-dutch_white-600 border-2 rounded shadow"}>{<div dangerouslySetInnerHTML={renderHtml(questionHtml)} />}</div>
                        <div className={'flex'}>
                            <div className={"bg-burnt_sienna-300 p-2 border-dutch_white-300 border-2 mt-3 flex flex-col rounded w-[45%] gap-2 text-xs"}>
                                <p className={'text-lg text-dutch_white-900'}>Your Response:</p>
                                {answerView.studentResponse.sort((a, b) => a.prompt.content.toLowerCase().localeCompare(b.prompt.content.toLowerCase())).map((item, index) =>
                                    <div className={'flex'}>
                                        <div className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded font-bold h-fit my-auto'}>
                                            {(item.prompt.type === 'image') ? <img src={item.prompt.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />}
                                        </div>
                                        <div className={'grow border-b-4 border-dotted border-columbia_blue-800 h-1/2 min-w-[20px]'} />
                                        {(item.response.content === answerView.correctResponse[index].match.content) ?
                                            <div className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded h-fit my-auto'}>
                                                {(item.response.type === 'image') ? <img src={item.response.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.response.content)} />}
                                            </div>
                                            :
                                            <div className={'border-dutch_white-800 border-2 shadow bg-burnt_sienna-500 text-dutch_white-200 p-1 rounded h-fit my-auto'}>
                                                {(item.response.type === 'image') ? <img src={item.response.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.response.content)} />}
                                            </div>}
                                    </div>)}
                            </div>
                            <div className={'grow'}/>
                            <div className={"bg-columbia_blue-700 p-2 mt-3 flex flex-col border-dutch_white-300 border-2 rounded w-[45%] gap-2 text-xs"}>
                                <p className={'text-lg text-dutch_white-200'}>Correct Response:</p>
                                {answerView.correctResponse.sort((a, b) => a.prompt.content.toLowerCase().localeCompare(b.prompt.content.toLowerCase())).map(item =>
                                    <div className={'flex'}>
                                        <div className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded font-bold h-fit my-auto'}>
                                            {(item.prompt.type === 'image') ? <img src={item.prompt.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} />}
                                        </div>
                                        <div className={'grow border-b-4 border-dotted border-columbia_blue-800 h-1/2 min-w-[20px]'} />
                                        <div className={'border-dutch_white-800 border-2 shadow bg-dutch_white-400 text-dutch_white-800 p-1 rounded h-fit my-auto'}>
                                            {(item.match.type === 'image') ? <img src={item.match.content} className={"rounded max-h-[100px] mx-auto mb-2 shadow"} /> : <div dangerouslySetInnerHTML={renderHtml(item.match.content)} />}
                                        </div>
                                    </div>)}
                            </div>
                        </div>

                    </div>

                </div>}
        </>
    );
}

export default RecallResultView;