import React from 'react';
import DOMPurify from "dompurify";

function TraditionalMultipleChoiceResultView({ questionModalContent, answerView}) {

    let answerViewSan = {result: answerView.result, studentResponse: DOMPurify.sanitize(answerView.studentResponse), correctResponse: DOMPurify.sanitize(answerView.correctResponse)}
    let questionHtml = DOMPurify.sanitize(questionModalContent.prompt)

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (<>
            {(answerViewSan.result) ?
                <div className={"bg-columbia_blue-800 max-h-full overflow-y-auto p-2 rounded"}>
                    <p className={'text-dutch_white-300 font-bold text-2xl'}>Correct!</p>
                    <p className={'text-dutch_white-300 mb-2'}>Nice! You've mastered this one: record your process in your Brainmeter Reflection!</p>
                    <div className={"bg-columbia_blue-500 p-4 rounded"}>
                        {(questionModalContent.imageURL) && <img src={questionModalContent.imageURL} className={"rounded w-80 mx-auto mb-2 shadow"} />}
                        <div className={"bg-dutch_white-300 text-lg p-4  border-dutch_white-600 border-2 rounded"}>{<div dangerouslySetInnerHTML={renderHtml(questionHtml)} />}</div>
                        <div className={"bg-columbia_blue-700 p-2 my-3 flex w-fit mx-auto rounded border-burnt_sienna-700 border-2"}>
                            <p className={'font-bold text-dutch_white-200 text-lg '}>{<div dangerouslySetInnerHTML={renderHtml(answerViewSan.correctResponse)} />}</p>
                        </div>
                    </div>
                </div>
                :
                <div className={"bg-burnt_sienna-600 max-h-full overflow-y-auto p-2 rounded"}>
                    <p className={'text-dutch_white-200 font-bold text-2xl'}>Incorrect</p>
                    <p className={'mb-2 text-dutch_white-200'}>You've got some learning left to do: review the differences below for next time!</p>
                    <div className={"bg-columbia_blue-500 p-4 rounded"}>
                        {(questionModalContent.imageURL) && <img src={questionModalContent.imageURL} className={"rounded max-h-[150px] mx-auto mb-2 shadow"} />}
                        <div className={"bg-dutch_white-300 text-lg p-4 border-dutch_white-600 border-2 rounded shadow"}>{<div dangerouslySetInnerHTML={renderHtml(questionHtml)} />}</div>
                        <div className={"bg-burnt_sienna-300 p-2 border-dutch_white-300 border-2 mt-3 flex flex-col rounded"}>
                            <p className={'text-columbia_blue-800 font-bold'}>Your Response:</p>{<div className={'text-columbia_blue-800 text-center mx-auto'} dangerouslySetInnerHTML={renderHtml(answerViewSan.studentResponse)} />}
                        </div>
                        <div className={"bg-columbia_blue-700 p-2 mt-3 flex flex-col border-dutch_white-300 border-2 rounded"}>
                            <p className={'text-dutch_white-300 font-bold'}>Correct Response:</p>{<div className={'text-dutch_white-300 text-center mx-auto'} dangerouslySetInnerHTML={renderHtml(answerViewSan.correctResponse)} />}
                        </div>
                    </div>

                </div>}
        </>
    );
}

export default TraditionalMultipleChoiceResultView;