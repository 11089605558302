export function stripHtml(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Handle superscript and subscript with semantic meaning
    tempDiv.querySelectorAll('sup').forEach(sup => {
        const content = sup.textContent.trim();
        let replacement = `superscript ${content}`;
        if (content === '2') {
            replacement = 'squared';
        } else if (content === '3') {
            replacement = 'cubed';
        }
        sup.replaceWith(replacement);
    });

    // Handle common mathematical symbols
    const mathSymbols = {
        '±': 'plus or minus',
        '×': 'times',
        '÷': 'divided by',
        '√': 'square-root',
        '∑': 'sum',
        '∏': 'product',
        '∞': 'infinity',
        '≠': 'not-equal',
        '≈': 'approximately-equal',
        '≡': 'identical-to',
        '≤': 'less-than-or-equal-to',
        '≥': 'greater-than-or-equal-to',
    };

    Object.keys(mathSymbols).forEach(symbol => {
        tempDiv.innerHTML = tempDiv.innerHTML.replace(new RegExp(symbol, 'g'), mathSymbols[symbol]);
    });

    // Handle common chemistry symbols
    const chemistrySymbols = {
        '→': 'reaction-arrow',
        '⇌': 'reversible-reaction-arrow',
        '°': 'degrees',
        '±': 'plus or minus',
        '‰': 'per-mille',
        'Δ': 'delta',
        '∅': 'empty-set'
    };

    Object.keys(chemistrySymbols).forEach(symbol => {
        tempDiv.innerHTML = tempDiv.innerHTML.replace(new RegExp(symbol, 'g'), chemistrySymbols[symbol]);
    });

    return tempDiv.textContent || tempDiv.innerText || '';
}