import React, { useState, useEffect } from 'react';
import DOMPurify from "dompurify";

function MatchingScrambledPreview({ assessmentItem }) {
    const [shuffledMatchSets, setShuffledMatchSets] = useState(null);

    useEffect(() => {
        const shuffled = {prompts: assessmentItem.matchSets.map(item => item.prompt).sort(() => 0.5 - Math.random()), matches: assessmentItem.matchSets.map(item => item.match).sort(() => 0.5 - Math.random())};
        setShuffledMatchSets(shuffled);
    }, [assessmentItem]);

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={'flex flex-col p-1 bg-dutch_white-200 shadow my-2 rounded mx-auto'}>
            {Object.keys(assessmentItem).includes('imageURL') && (
                <img
                    className={`max-h-[100px] rounded mx-auto`}
                    src={assessmentItem.imageURL}
                    alt="Assessment"
                />
            )}
            <div className={'flex flex-col gap-2 bg-paynes_gray-300 p-2 rounded'}>
                <div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>
                    <div dangerouslySetInnerHTML={renderHtml(assessmentItem.prompt)} />
                </div>
                <div className={'flex flex-wrap gap-4 max-h-[800px] max-w-[90%] mx-auto'}>
                    {(shuffledMatchSets) && shuffledMatchSets.prompts.map((item, index) => (
                        <div key={index} className={'flex'}>
                            <div className={'p-2 rounded bg-columbia_blue-400 shadow border-dutch_white-200 border-2 h-fit my-auto'}>
                                {item.type === 'text' ? (
                                    <div dangerouslySetInnerHTML={renderHtml(item.content)} className={'my-auto'} />
                                ) : (
                                    <img className={'max-h-[100px]'} src={item.content} alt="Prompt" />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'w-full h-1 bg-columbia_blue-900'}></div>
                <div className={'flex flex-wrap gap-4 max-h-[800px] max-w-[90%] mx-auto'}>
                    {(shuffledMatchSets) && shuffledMatchSets.matches.map((item, index) => (
                        <div key={index} className={'flex'}>
                            <div className={'p-2 rounded bg-dutch_white-200 shadow border-columbia_blue-400 border-2 h-fit my-auto'}>
                                {item.type === 'text' ? (
                                    <div dangerouslySetInnerHTML={renderHtml(item.content)} className={'my-auto'} />
                                ) : (
                                    <img className={'max-h-[100px]'} src={item.content} alt="Match" />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MatchingScrambledPreview;
