import React, {useContext, useEffect, useState} from 'react';
import getWithAuth from "../../context/getWithAuth";
import UserContext from "../../context/userContext";
import Logistics from "./logistics";

function DashboardFetchLayer() {

    const [rosters, setRosters] = useState(null);
    const [studentContext, setStudentContext] = useState(null);
    const [standardSets, setStandardSets] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [unitPlans, setUnitPlans] = useState([]);

    const user = useContext(UserContext);

    const fetchRosters = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStandardSets = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getStandardSets`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStudentContext = async () => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getStudentContext`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStudentContext(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchGroupData = async (idArr) => {
        try {
            getWithAuth(`/logistics/getGroupData`, user.userAttributes['custom:school'], {students: encodeURIComponent(JSON.stringify(idArr))})
                .then(async r => {
                    setGroupData(r)
                })
        } catch (error) {
            console.error('Error fetching standards data:', error)
        }

    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/planning/getKnowShow`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const getUnitPlans = async (user) => {
        if (user.userAttributes) {
            try {
                getWithAuth(`/logistics/getUnitPlans`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setUnitPlans(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    useEffect(() => {
        fetchRosters();
        fetchStudentContext();
        fetchStandardSets();
        fetchAssessmentItems(user);
        fetchKnowShowCharts(user);
        getUnitPlans(user);
    }, [user.userContext]);

    useEffect( () => {
        if (rosters) {
            let idArr = rosters.flatMap(item => item.students)
            fetchGroupData(idArr)
        }
    }, [rosters])

    return (
        <Logistics user={user} rosters={rosters} setRosters={setRosters} studentContext={studentContext} setStudentContext={setStudentContext} standardSets={standardSets} setStandardSets={setStandardSets} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} knowShowCharts={knowShowCharts} setKnowShowCharts={setKnowShowCharts} unitPlans={unitPlans} setUnitPlans={setUnitPlans} groupData={groupData} setGroupData={setGroupData} fetchRosters={fetchRosters} getUnitPlans={getUnitPlans}/>
    );
}

export default DashboardFetchLayer;