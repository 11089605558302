import React, {useState} from 'react';
import DOMPurify from "dompurify";
import {signOut} from "aws-amplify/auth";
import putWithAuth from "../context/putWithAuth";
import {useNavigate} from "react-router-dom";
import Loader from "./loader";

var terms = require('./tosContent.jsx');
var privacy = require('./privacyContent.jsx');

function ToSModal({user}) {
    const [isLoading, setIsLoading] = useState(false)
    const [termsAccept, setTermsAccept] = useState(false);
    const [privacyAccept, setPrivacyAccept] = useState(false)
    const navigate = useNavigate()

    const submitToS = async () => {
        setIsLoading(true);
        let body = {userId: user.userContext._id.$oid}
        putWithAuth('/putTermsOfService', user.userAttributes['custom:school'], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        })
            .then((response) => {
                if (response.ok) {
                    window.alert('Terms Accepted!')
                    user.userContext.termsAccepted = true
                    if (user.userContext.role === 'student') {
                        navigate('/home');
                    } else if (user.userContext.role === 'teacher') {
                        navigate('/planning');
                    } else if (user.userContext.role === 'coordinator') {
                        navigate('/schoolplanning');
                    }
                } else {
                    console.log(response.error)
                    setIsLoading(false);
                }
            })
    }

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div
            className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"}
            id="wrapper">
            <div className={'bg-columbia_blue-800 m-4 rounded select-none max-h-[92%] min-w-[70%] max-w-[95%] pt-4'}>
                <p className={'text-2xl font-bold text-dutch_white-300'}>Please Review and Agree to the Terms of Service and Privacy Policy Below</p>
                <div
                    className={"relative flex m-4 rounded select-none max-h-[90%] min-w-[70%] max-w-[95%] pb-4"}>
                    <div className={'grow'}/>

                    {(termsAccept) ?
                        <div className={'text-left w-[40%] max-h-full overflow-auto p-4 bg-columbia_blue-400 rounded'}>
                            <div dangerouslySetInnerHTML={{__html: terms}}/>
                            <div
                                className={'mx-auto w-fit mt-4 p-4 bg-columbia_blue-800 text-dutch_white-300 hover:bg-columbia_blue-900 cursor-pointer rounded'}>Accept
                                Terms of Service
                            </div>
                        </div> :
                        <div className={'text-left w-[40%] max-h-full overflow-auto p-4 bg-dutch_white-200 rounded'}>
                            <div dangerouslySetInnerHTML={{__html: terms}}/>
                            <div onClick={() => setTermsAccept(!termsAccept)}
                                 className={'mx-auto w-fit mt-4 p-4 bg-columbia_blue-800 text-dutch_white-300 hover:bg-columbia_blue-900 cursor-pointer rounded'}>Accept
                                Terms of Service
                            </div>
                        </div>}
                    <div className={'grow'}/>
                    {(privacyAccept) ?
                        <div className={'text-left w-[40%] max-h-full overflow-auto p-4 bg-columbia_blue-400 rounded'}>
                            <div dangerouslySetInnerHTML={{__html: privacy}}/>
                            <div
                                className={'mx-auto w-fit mt-4 p-4 bg-columbia_blue-800 text-dutch_white-300 hover:bg-columbia_blue-900 cursor-pointer rounded'}>Accept
                                Privacy Policy
                            </div>
                        </div> :
                        <div className={'text-left w-[40%] max-h-full overflow-auto p-4 bg-dutch_white-200 rounded'}>
                            <div dangerouslySetInnerHTML={{__html: privacy}}/>
                            <div onClick={() => setPrivacyAccept(!privacyAccept)}
                                 className={'mx-auto mt-4 w-fit p-4 bg-columbia_blue-800 text-dutch_white-300 hover:bg-columbia_blue-900 cursor-pointer rounded'}>Accept
                                Privacy Policy
                            </div>
                        </div>}
                    <div className={'grow'}/>

                </div>
                <div className={'absolute bottom-3 flex w-[95%] h-fit gap-8 items-center justify-center'}>
                    {(termsAccept && privacyAccept) ? <div onClick={submitToS}
                                                           className="rounded m-2 p-1 bg-columbia_blue-700 cursor-pointer text-dutch_white-200 font-bold px-10 hover:bg-columbia_blue-600">
                            <p className={'text-dutch_white-200 text-2xl select-none'}>Agree</p>
                        </div>
                        :
                        <div className="rounded m-2 p-1 bg-platinum-700 text-dutch_white-200 font-bold px-10">
                            <p className={'text-dutch_white-200 text-2xl select-none'}>Accept Both Policies Above</p>
                        </div>}
                        <div onClick={() => signOut()}
                    className="rounded m-2 p-1 bg-burnt_sienna font-bold px-10 cursor-pointer hover:bg-burnt_sienna-600">
                    <p className={'text-dutch_white-200 font-bold text-2xl select-none'}>Decline</p>
                </div>
                </div>
            </div>

            {(isLoading) && <Loader message={''} />}
        </div>
    )
        ;
}

export default ToSModal;