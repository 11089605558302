import React, {useEffect, useState} from 'react';
import putWithAuth from "../../context/putWithAuth";

function FullRandomGroups({ school, students, groupModal, setSeatingChart, setGroupModal}) {

    const [groups, setGroups] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [groupSize, setGroupSize] = useState(4);

    const handleMinusClick = () => {
        if (groupSize > 2) {
            setGroupSize(prev => prev - 1)
        }
    }

    const handlePlusClick = () => {
        setGroupSize(prev => prev + 1);
    }



    const putSaveSeatingChart = () => {
        let seatingChart = {
            groups: groups,
            rosterId: groupModal.roster._id.$oid
        };
        setIsLoading(true);
        putWithAuth('/logistics/putSaveSeatingChart', school, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: seatingChart
        })
            .then(async response => {
                if (response.ok) {
                    setSeatingChart(seatingChart.groups);
                    setIsLoading(false)
                    setGroupModal(prev => {
                        prev.chartType = 'Seating Chart';
                        return prev;
                    })
                } else {
                    console.error('Failed to save Seating Chart');
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.error('Error publishing seating chart:', error);
                setIsLoading(false)
            });
    };

    function handleStudentClick(e) {
        if (selectedStudent) {
            let firstStudentCoords = [...selectedStudent]
            let firstStudentInfo = groups[firstStudentCoords[0]][firstStudentCoords[1]]
            let secondStudentCoords = e.target.id.split('-').map(value => parseInt(value, 10))
            let secondStudentInfo = groups[secondStudentCoords[0]][secondStudentCoords[1]]
            let tempGroups = [...groups]
            tempGroups[firstStudentCoords[0]][firstStudentCoords[1]] = secondStudentInfo
            tempGroups[secondStudentCoords[0]][secondStudentCoords[1]] = firstStudentInfo
            setGroups(tempGroups);
            setSelectedStudent(null)
        } else {
            const coord = e.target.id.split('-').map(value => parseInt(value, 10));
            setSelectedStudent(coord)
        }
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    const colors = ['#e48a66', '#538ea5', '#ece0c0', '#1c2f37']
    const textColors = ['#1c2f37', '#ece0c0', '#1c2f37', '#ece0c0']

    function generateGroups(students) {
        let shuffledStudents = shuffleArray([...students]);
        const groupsConstructor = []
        while (shuffledStudents.length > groupSize - 1) {
            var group = []
            while (group.length < groupSize) {
                group.push(shuffledStudents.pop())
            }
            shuffleArray(group)
            groupsConstructor.push(group)
        }
        if (shuffledStudents.length > 0) {
            var group = []
            while (shuffledStudents.length > 0) {
                group.push(shuffledStudents.pop())
            }
            while (group.length < groupSize) {
                group.push({firstName: "---", lastName: ''});
            }
            groupsConstructor.push(group)
        }
        setGroups(groupsConstructor);
    }

    useEffect(() => {
        generateGroups(students)
    }, [students, groupSize]);

    return (
        <>
            <div
                className={"bg-columbia_blue p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto shadow-xl"}>
                <div className={"bg-dutch_white-300 text-lg text-columbia_blue-900 p-2 rounded shadow flex"}>
                    <div className={'grow'}/>
                    <div>
                        <p className={'font-bold'}>{groupModal.roster.title}</p>
                        <p className={'text-xs'}>{groupModal.roster.prep}</p>
                    </div>
                    <div className={'grow'}/>
                    <div className={'flex'}>
                        <button
                            aria-label={'Decrease Group Size'}
                            className={'h-fit bg-columbia_blue-800 text-dutch_white-400 hover:bg-columbia_blue-700 rounded px-2 my-auto select-none cursor-pointer'}
                            onClick={handleMinusClick}>-
                        </button>
                        <p className={'font-bold text-3xl mx-3 select-none'}>{groupSize}</p>
                        <button
                            aria-label={'Increase Group Size'}
                            className={'h-fit bg-columbia_blue-800 text-dutch_white-400 hover:bg-columbia_blue-700 rounded px-2 my-auto select-none cursor-pointer'}
                            onClick={handlePlusClick}>+
                        </button>
                    </div>
                    <div className={'grow'}/>
                    <button onClick={() => generateGroups(students)}
                            className={'bg-platinum-300 border-[1px] rounded p-2 border-columbia_blue-600 h-fit my-auto hover:bg-platinum'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill={'#538ea5'}>
                            <path
                                d="M2 7h-2v-2h2c3.49 0 5.48 1.221 6.822 2.854-.41.654-.754 1.312-1.055 1.939-1.087-1.643-2.633-2.793-5.767-2.793zm16 10c-3.084 0-4.604-1.147-5.679-2.786-.302.627-.647 1.284-1.06 1.937 1.327 1.629 3.291 2.849 6.739 2.849v3l6-4-6-4v3zm0-10v3l6-4-6-4v3c-5.834 0-7.436 3.482-8.85 6.556-1.343 2.921-2.504 5.444-7.15 5.444h-2v2h2c5.928 0 7.543-3.511 8.968-6.609 1.331-2.893 2.479-5.391 7.032-5.391z"/>
                        </svg>
                    </button>
                </div>

                <div className={"grid grid-cols-4 my-2 gap-2"}>
                    {groups && groups.map((group, groupIndex) =>
                        <div className={"h-full flex flex-col bg-platinum-300 rounded p-1"}>
                            <p className={'font-bold'}>Group {groupIndex + 1}</p>
                            {group.map((student, index) =>
                                (selectedStudent && selectedStudent[0] === groupIndex && selectedStudent[1] === index) ?
                                    <button
                                        onClick={handleStudentClick}
                                        id={`${groupIndex}-${index}`}
                                        className={'text-center p-1 hover:opacity-[85%] animate-pulse rounded mb-1 bg-burnt_sienna text-dutch_white-700 shadow grow flex flex-col cursor-pointer'}
                                        style={{background: colors[index % 4], color: textColors[index % 4]}}>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>
                                        <p id={`${groupIndex}-${index}`}
                                           className={'mx-auto'}>{student.firstName} {student.lastName}</p>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>

                                    </button>
                                    :
                                    <button
                                        onClick={handleStudentClick}
                                        id={`${groupIndex}-${index}`}
                                        className={'text-center p-1 hover:opacity-[85%] rounded mb-1 bg-burnt_sienna text-dutch_white-700 shadow grow flex flex-col cursor-pointer'}
                                        style={{background: colors[index % 4], color: textColors[index % 4]}}>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>
                                        <p id={`${groupIndex}-${index}`}
                                           className={'mx-auto'}>{student.firstName} {student.lastName}</p>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>

                                    </button>)}
                        </div>
                    )}
                </div>
            </div>
            {(!isLoading) ?
                <button onClick={putSaveSeatingChart}
                        className="absolute self-center rounded -bottom-5 left-[50%] -translate-x-1/2 align-middle p-2 bg-columbia_blue-700 text-dutch_white-200 font-bold px-10 hover:bg-columbia_blue-600">
                    <p>Save Seating Chart</p>
                </button> :
                <button className="absolute self-center rounded -bottom-5 left-[50%] -translate-x-1/2 align-middle p-2 bg-columbia_blue-700 text-dutch_white-200 font-bold px-10 hover:bg-columbia_blue-600">
                    <div className="border-t-2 border-b-2 border-burnt_sienna h-6 w-6 rounded-full animate-spin mx-auto"></div>
                </button>
            }
        </>
    );
}

export default FullRandomGroups;