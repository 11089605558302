import { fetchAuthSession } from 'aws-amplify/auth';

const getWithAuth = async (endpoint, schoolId, options = {}) => {
    try {
        const session = await fetchAuthSession();
        const url = new URL(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}${endpoint}`);

        url.searchParams.append('schoolId', schoolId);

        for (const key in options) {
            url.searchParams.append(key, options[key]);
        }

        // Add Authorization header to options
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${session.tokens.idToken}`,
        };

        const response = await fetch(url.toString(), options);

        // Handle uncompressed response
        return await response.json()
    } catch (error) {
        console.error('Error during getWithAuth:', error);
        return null;
    }
};

export default getWithAuth;