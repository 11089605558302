import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";
import TraditionalMCPreview from "./traditionalMCPreview";

function CustomToolTip({ active, payload }) {
    if (active && payload && payload.length) {
        return (
            <div className={'bg-columbia_blue-400 text-dutch_white-800 p-1 max-w-[300px] overflow text-sm rounded bg-paynes_gray'}>
                <p className={'text-dutch_white-200'}>{new Date(payload.map(item => item.payload.name)[0]).toDateString()}</p>
                <TraditionalMCPreview assessmentItem={payload.map(item => item.payload.question)[0]} />
            </div>
        )
    }

    return null;
}

// function CustomXTick({ active, payload, label }) {
//     if (active && payload && payload.length) {
//         console.log(payload)
//         return (
//             <div className={'bg-dutch_white-400 text-dutch_white-800 p-2 max-w-[170px] overflow text-lg'}>
//                 <p>{label}</p>
//                 <br />
//                 {payload.map(item => (<p>{item.dataKey}: {item.value}</p>))}
//             </div>
//         )
//     }
//
//     return null;
// }


export default function HistoryBarChart({ data, assessmentItems }) {

    let uniqueDates = new Set();
    let historyDataDates = data.filter(item => {
        if (Object.keys(item).includes('dateAssigned')) {
            const date = (item.dateAssigned.$date) ? new Date(item.dateAssigned.$date) : new Date(item.dateAssigned);
            uniqueDates.add(date.toISOString());
            return true;
        }
        return false;
    });
    let historyBarChartDataConstructor = [...uniqueDates].map(item => ({
        name: item,
        Correct: data.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => (typeof entry.result === 'number' && entry.result === 0 || typeof entry.result === 'boolean' && entry.result)).length,
        Incorrect: data.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => (typeof entry.result === 'number' && entry.result > 0 || typeof entry.result === 'boolean' && !entry.result)).length,
        Pending: data.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => entry.result === 'pending').length,
        // question: assessmentItems.find(question => data.filter(entry => (entry.dateAssigned.$date === item)).filter(entry => entry.result === true || entry.result === false)[0].questionTemplateId === question._id.$oid),
    }));
    historyBarChartDataConstructor.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className={'w-[95%] mx-auto'}>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={historyBarChartDataConstructor}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#eeeeee"/>
                    <XAxis dataKey='name' className={'font-bold'} tick={false}/>
                    <Legend/>
                    <Bar dataKey="Correct" stackId="a" fill="#538ea5"/>
                    <Bar dataKey="Incorrect" stackId="a" fill="#dd6e42"/>
                    <Bar dataKey="Pending" stackId="a" fill="#8b8b8b"/>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
