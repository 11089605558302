import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from "../../context/userContext";
import QuestionModal from "./questionModal";
import MyDataSummary from "../myData/myDataSummary";
import getWithAuth from "../../context/getWithAuth";
import ToSModal from "../../components/tosModal";
import Loader from "../../components/loader";
import {useNavigate} from "react-router-dom";
import MobileDataSummary from "../myData/mobileDataSummary";

function Home() {
    const [questionModalContent, setQuestionModalContent] = useState(null);
    const [outstandingAssessments, setOutstandingAssessments] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [standardSets, setStandardSets] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // 768px is a common mobile breakpoint

    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const carousel = carouselRef.current;
            if (carousel) {
                const index = Math.round(carousel.scrollLeft / carousel.clientWidth);
                setCurrentIndex(index);
            }
        };

        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const navigate = useNavigate()

    useEffect(() => {
        if (questionModalContent) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';}
    }, [questionModalContent])

    let user = useContext(UserContext);
    const fetchOutstandingAssessments = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth('/student/getCurrentAssessments', user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setOutstandingAssessments(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStudentData = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth('/student/getStudentData', user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setStudentData(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth('/student/getStandardSets', user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth('/student/getKnowShowCharts', user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    function generateHistoryLabel(history) {
        if (history.length > 0) {
            let historyString = history.map((item, index) => {
                if (item.result === 'pending') {
                    return `Attempt ${index + 1}: No response.`;
                } else if (item.result === 0 || item.result === true) {
                    return `Attempt ${index + 1}: Correct.`;
                } else {
                    return `Attempt ${index + 1}: Incorrect.`;
                }
            }).join(' ');
            return 'History: ' + historyString
        } else {
            return ''
        }
    }


    useEffect( () => {
        fetchOutstandingAssessments(user);
        fetchStudentData(user);
        fetchStandardSets(user);
        fetchKnowShowCharts(user);
    }, [user.userContext]);

    const refreshAssessments = async (user) => {
        if (user.userContext) {
            try {
                setRefreshLoading(true);
                getWithAuth('/student/getCurrentAssessments', user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setOutstandingAssessments(r)
                        setRefreshLoading(false)
                    });
                getWithAuth('/student/getStudentData', user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setStudentData(r)
                    });
            } catch (error) {
                setRefreshLoading(false)
                console.error('Error fetching standards data:', error)
            }
        }
    }

    if (!user.userContext) {
        return <Loader message={'Fetching user context'} />
    } else if (user.userContext.role !== 'student') {
        navigate('/');
    } else if (!outstandingAssessments || !studentData || !standardSets || !knowShowCharts) {
        return (
            <Loader message={'Checking for assessments'} />
        )
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (isMobile) {
        return (
        <div className={'min-h-screen'}>
            <div className={'bg-paynes_gray pb-8'}>
                <div ref={carouselRef} className={'snap-x flex overflow-x-scroll bg-paynes_gray'}>
                    {Object.keys(outstandingAssessments).map(item => outstandingAssessments[item]).flatMap(item => item).filter(item => (item.type !== 'Recall') || (item.type === 'Recall' && item.matchData[0].length > 0)).map((assessmentItem, index) => (
                        <div className={'min-w-full my-4 snap-center'}>
                            <p className={'mb-4 font-bold text-dutch_white-100'}>{assessmentItem.prep}</p>

                            {(assessmentItem.type === 'Recall') ?
                                <button key={index}
                                        onClick={() => setQuestionModalContent(assessmentItem)}
                                        className={"bg-columbia_blue-900 mx-4 text-black p-4 rounded border-dutch_white-300 border-4 h-fit hover:bg-columbia_blue-800 cursor-pointer"}
                                        aria-label={`Assessment Item: ${assessmentItem.recallTitle} from ${assessmentItem.standardTitle}`}>
                                    <p className={"select-none text-dutch_white-300 font-bold hyphens-auto overflow-hidden text-ellipsis"}>{assessmentItem.recallTitle}</p>
                                    <p className={'select-none text-dutch_white-300 text-xs hyphens-auto overflow-hidden text-ellipsis'}>{assessmentItem.standardTitle}</p>
                                </button>
                                : (studentData.archive[assessmentItem.prep].map(item => item.knowShowId).includes(assessmentItem.knowShowId)) ?
                                    <button key={index}
                                            onClick={() => setQuestionModalContent(assessmentItem)}
                                            className={"relative mx-4 bg-dutch_white-400 text-black p-2 pb-3 rounded border-burnt_sienna-400 border-4 max-h-min mb-4 hover:bg-dutch_white cursor-pointer"}
                                            aria-label={`Assessment Item: ${assessmentItem.knowShowText}. ${generateHistoryLabel(studentData.archive[assessmentItem.prep].filter(entry => assessmentItem.knowShowId === entry.knowShowId))}`}>
                                        <p className={"select-none text-columbia_blue-900 mb-2 hyphens-auto overflow-hidden text-ellipsis"}>{assessmentItem.knowShowText}</p>
                                        <div
                                            className={'absolute flex w-[80%] -bottom-6 -right-4 p-2 bg-paynes_gray-600 border-dutch_white-200 border-2 border-opacity-70 rounded'}
                                            aria-label={'History'}>
                                            <p className={'text-xs text-dutch_white-300 select-none'}>History:</p>
                                            {studentData.archive[assessmentItem.prep].filter(entry => assessmentItem.knowShowId === entry.knowShowId).map((entry, index) => (entry.result === 'pending')
                                                ? <div
                                                    className={'grow ml-[2px] bg-platinum-800 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                    aria-label={`Attempt ${index + 1}: No Response`}/> : (entry.result === false) ?
                                                    <div
                                                        className={'grow ml-[2px] bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                        aria-label={`Attempt ${index + 1}: Incorrect`}/> : (entry.result === true) ?
                                                        <div
                                                            className={'grow ml-[2px] bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                            aria-label={`Attempt ${index + 1}: Correct`}/> : (entry.result > 0) ?
                                                            <div
                                                                className={'grow ml-[2px] bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                                aria-label={`Attempt ${index + 1}: Incorrect`}/> :
                                                            <div
                                                                className={'grow ml-[2px] bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                                aria-label={`Attempt ${index + 1}: Correct`}/>)}
                                        </div>
                                    </button>
                                    : <button key={index}
                                              onClick={() => setQuestionModalContent(assessmentItem)}
                                              className={"bg-columbia_blue-500 w-fit text-black p-4 rounded border-dutch_white-300 border-4 h-fit hover:bg-columbia_blue-600 cursor-pointer"}
                                              aria-label={`Assessment Item: ${assessmentItem.knowShowText}`}>
                                        <p className={"select-none hyphens-auto overflow-hidden text-ellipsis"}>{assessmentItem.knowShowText}</p>
                                    </button>}
                        </div>
                    ))}

                </div>
                <div className="flex justify-center mt-4">
                    {Array.from({length: Object.keys(outstandingAssessments).map(item => outstandingAssessments[item]).flatMap(item => item).filter(item => (item.type !== 'Recall') || (item.type === 'Recall' && item.matchData[0].length > 0)).length}).map((_, index) => (
                        <button
                            key={index}
                            className={`w-3 h-3 rounded-full mx-2 ${currentIndex === index ? 'bg-dutch_white-200' : 'bg-gray-300'}`}
                            onClick={() => {
                                const carousel = carouselRef.current;
                                if (carousel) {
                                    carousel.scrollTo({
                                        left: carousel.clientWidth * index,
                                        behavior: 'smooth',
                                    });
                                    setCurrentIndex(index)
                                }
                            }}
                        />
                    ))}
                </div>
            </div>

            {(user.userContext) && <MobileDataSummary user={user} studentData={studentData} standardSets={standardSets} knowShowCharts={knowShowCharts}/>}
            {(questionModalContent) && <QuestionModal id={'question_modal'} user={user}
                                                      fetchOutstandingAssessments={fetchOutstandingAssessments}
                                                      fetchStudentData={fetchStudentData}
                                                      questionModalContent={questionModalContent}
                                                      setQuestionModalContent={setQuestionModalContent}/>}
        </div>);

    } else {
        return (
            <div className={'min-h-screen'}>
                <div className={'bg-columbia_blue-700 py-1 flex flex-col'}>
                    <div className={'flex'}>
                        <div className={'grow'}/>
                        <div className={'p-2 rounded bg-dutch_white-300 border-[1px] border-columbia_blue-900 shadow'}
                             aria-label={'Enrolled classes with no outstanding assignments'}>
                            {(user.userContext.preps.filter(item => outstandingAssessments[item].length === 0 || (outstandingAssessments[item].every(item => item.type === 'Recall') && outstandingAssessments[item].flatMap(item => item.matchData[0]).length === 0)).length > 0) ?
                                <p className={'text-columbia_blue-800 my-auto'}>All Caught
                                    Up: {user.userContext.preps.filter(item => outstandingAssessments[item].length === 0 || (outstandingAssessments[item].every(item => item.type === 'Recall') && outstandingAssessments[item].flatMap(item => item.matchData[0]).length === 0)).join(' - ')}</p> :
                                <p>Click an assessment item below to get started:</p>}
                        </div>
                        <div className={'grow'}/>
                        <button onClick={() => refreshAssessments(user)}
                                className={'bg-platinum-300 border-[1px] rounded p-2 m-2 border-columbia_blue-600 h-fit my-auto hover:bg-platinum'}
                                aria-label={'Refresh current assessments'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill={'#538ea5'}>
                                <path
                                    d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/>
                            </svg>
                        </button>
                    </div>
                    {(refreshLoading) && <div>
                        <p className={'text-lg font-bold text-dutch_white-100'}>Fetching Assessment Items</p>
                        <div
                            className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100 mx-auto"></div>
                    </div>}

                    <div className={'flex'}>
                        <div className={'flex flex-col w-[50%]'}>
                            {user.userContext.preps.filter(item => (outstandingAssessments[item].filter(entry => (entry.type === 'Recall' && entry.matchData[0].length > 0)).length > 0 || outstandingAssessments[item].filter(entry => (entry.type !== 'Recall')).length > 0)).filter((item, index) => (index % 2 === 0)).map(item => (
                                <div className={'grow border border-dutch_white-200 m-2 p-2'}
                                     aria-label={`Assessment Items for ${item}`}>
                                    <p className={'font-bold text-dutch_white-200 text-2xl mb-2'}>{item}</p>
                                    <div className={'flex flex-wrap gap-4'}>
                                        {outstandingAssessments[item].filter(entry => entry.type === 'Recall').filter(item => item.matchData[0].length > 0).map((recallSet) =>
                                            <button onClick={() => setQuestionModalContent(recallSet)}
                                                    className={"bg-columbia_blue-900 max-w-[30%] text-black p-4 rounded border-dutch_white-300 border-4 h-fit hover:bg-columbia_blue-800 cursor-pointer"}
                                                    aria-label={`Assessment Item: ${recallSet.recallTitle} from ${recallSet.standardTitle}`}>
                                                <p className={"select-none text-dutch_white-300 font-bold hyphens-auto overflow-hidden text-ellipsis"}>{recallSet.recallTitle}</p>
                                                <p className={'select-none text-dutch_white-300 text-xs hyphens-auto overflow-hidden text-ellipsis'}>{recallSet.standardTitle}</p>
                                            </button>)
                                        }
                                        {outstandingAssessments[item].filter(entry => entry.type !== 'Recall').map((question) => {

                                            if (studentData.archive[item].map(item => item.knowShowId).includes(question.knowShowId)) {
                                                return (
                                                    <button onClick={() => setQuestionModalContent(question)}
                                                            className={"relative max-w-[30%] bg-dutch_white-400 text-black p-2 pb-3 w-fit rounded border-burnt_sienna-400 border-4 max-h-min mb-4 hover:bg-dutch_white cursor-pointer"}
                                                            aria-label={`Assessment Item: ${question.knowShowText}. ${generateHistoryLabel(studentData.archive[item].filter(entry => question.knowShowId === entry.knowShowId))}`}>
                                                        <p className={"select-none text-columbia_blue-900 mb-2 hyphens-auto overflow-hidden text-ellipsis"}>{question.knowShowText}</p>
                                                        <div
                                                            className={'absolute flex w-[80%] -bottom-6 -right-4 p-2 bg-paynes_gray-600 border-dutch_white-200 border-2 border-opacity-70 rounded'}
                                                            aria-label={'History'}>
                                                            <p className={'text-xs text-dutch_white-300 select-none'}>History:</p>
                                                            {studentData.archive[item].filter(entry => question.knowShowId === entry.knowShowId).map((entry, index) => (entry.result === 'pending')
                                                                    ? <div
                                                                        className={'grow ml-[2px] bg-platinum-800 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                                        aria-label={`Attempt ${index + 1}: No Response`}/> : (entry.result === false) ?
                                                                        <div
                                                                            className={'grow ml-[2px] bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                                            aria-label={`Attempt ${index + 1}: Incorrect`}/> : (entry.result === true) ?
                                                                            <div
                                                                                className={'grow ml-[2px] bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'} aria-label={`Attempt ${index + 1}: Correct`}/> : (entry.result > 0) ?
                                                                                <div
                                                                                    className={'grow ml-[2px] bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'} aria-label={`Attempt ${index + 1}: Incorrect`}/> :
                                                                                <div
                                                                                    className={'grow ml-[2px] bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'} aria-label={`Attempt ${index + 1}: Correct`}/>)}
                                                            </div>
                                                        </button>)
                                                } else {
                                                    return (
                                                        <button onClick={() => setQuestionModalContent(question)}
                                                             className={"bg-columbia_blue-500 max-w-[30%] text-black p-4 rounded border-dutch_white-300 border-4 h-fit hover:bg-columbia_blue-600 cursor-pointer"}
                                                            aria-label={`Assessment Item: ${question.knowShowText}`}>
                                                            <p className={"select-none hyphens-auto overflow-hidden text-ellipsis"}>{question.knowShowText}</p>
                                                        </button>)
                                                }

                                            })}

                                        </div>


                                </div>))}
                        </div>
                        <div className={'flex flex-col w-[50%]'}>
                            {user.userContext.preps.filter(item => (outstandingAssessments[item].filter(entry => (entry.type === 'Recall' && entry.matchData[0].length > 0)).length > 0 || outstandingAssessments[item].filter(entry => (entry.type !== 'Recall')).length > 0)).filter((item, index) => (index % 2 === 1)).map(item => (
                                <div className={'grow border border-dutch_white-200 m-2 p-2'} aria-label={`Assessment Items for ${item}`}>
                                    <p className={'font-bold text-dutch_white-200 text-2xl mb-2'}>{item}</p>
                                    <div className={'flex flex-wrap gap-4'}>
                                        {outstandingAssessments[item].filter(entry => entry.type === 'Recall').filter(item => item.matchData[0].length > 0).map((recallSet) =>
                                            <button onClick={() => setQuestionModalContent(recallSet)}
                                                    className={"bg-columbia_blue-900 max-w-[30%] text-black p-4 rounded border-dutch_white-300 border-4 h-fit hover:bg-columbia_blue-800 cursor-pointer"}
                                                    aria-label={`Assessment Item: ${recallSet.recallTitle} from ${recallSet.standardTitle}`}>
                                                <p className={"select-none text-dutch_white-300 font-bold hyphens-auto overflow-hidden text-ellipsis"}>{recallSet.recallTitle}</p>
                                                <p className={'select-none text-dutch_white-300 text-xs hyphens-auto overflow-hidden text-ellipsis'}>{recallSet.standardTitle}</p>
                                            </button>)
                                        }
                                        {outstandingAssessments[item].filter(entry => entry.type !== 'Recall').map((question) => {

                                            if (studentData.archive[item].map(item => item.knowShowId).includes(question.knowShowId)) {
                                                return (
                                                    <button onClick={() => setQuestionModalContent(question)}
                                                            className={"relative max-w-[30%] bg-dutch_white-400 text-black p-2 pb-3 w-fit rounded border-burnt_sienna-400 border-4 max-h-min mb-4 hover:bg-dutch_white cursor-pointer"}
                                                            aria-label={`Assessment Item: ${question.knowShowText}. ${generateHistoryLabel(studentData.archive[item].filter(entry => question.knowShowId === entry.knowShowId))}`}>
                                                        <p className={"select-none text-columbia_blue-900 mb-2 hyphens-auto overflow-hidden text-ellipsis"}>{question.knowShowText}</p>
                                                        <div
                                                            className={'absolute flex w-[80%] -bottom-6 -right-4 p-2 bg-paynes_gray-600 border-dutch_white-200 border-2 border-opacity-70 rounded'}
                                                            aria-label={'History'}>
                                                            <p className={'text-xs text-dutch_white-300 select-none'}>History:</p>
                                                            {studentData.archive[item].filter(entry => question.knowShowId === entry.knowShowId).map((entry, index) => (entry.result === 'pending')
                                                                ? <div
                                                                    className={'grow ml-[2px] bg-platinum-800 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                                    aria-label={`Attempt ${index + 1}: No Response`}/> : (entry.result === false) ?
                                                                    <div
                                                                        className={'grow ml-[2px] bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'}
                                                                        aria-label={`Attempt ${index + 1}: Incorrect`}/> : (entry.result === true) ?
                                                                        <div
                                                                            className={'grow ml-[2px] bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'} aria-label={`Attempt ${index + 1}: Correct`}/> : (entry.result > 0) ?
                                                                            <div
                                                                                className={'grow ml-[2px] bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'} aria-label={`Attempt ${index + 1}: Incorrect`}/> :
                                                                            <div
                                                                                className={'grow ml-[2px] bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-[1px] h-3 my-auto rounded'} aria-label={`Attempt ${index + 1}: Correct`}/>)}
                                                        </div>
                                                    </button>)
                                            } else {
                                                return (
                                                    <button onClick={() => setQuestionModalContent(question)}
                                                            className={"bg-columbia_blue-500 max-w-[30%] text-black p-4 rounded border-dutch_white-300 border-4 h-fit hover:bg-columbia_blue-600 cursor-pointer"}
                                                            aria-label={`Assessment Item: ${question.knowShowText}`}>
                                                        <p className={"select-none hyphens-auto overflow-hidden text-ellipsis"}>{question.knowShowText}</p>
                                                    </button>)
                                            }

                                        })}

                                    </div>


                                </div>))}
                        </div>
                    </div>


                </div>
                {/*<p className={'text-dutch_white-800 font-bold select-none'}>Click a class below to get started!</p>*/}
                {/*<div className={"flex flex-col gap-12"}>*/}
                {/*    {(user.userContext) && user.userContext.preps.map(item => (*/}
                {/*        (displayGrid && displayGrid.prep === item) ?*/}
                {/*            <div className={"py-2 px-4 bg-columbia_blue-800 flex flex-col text-black mx-1 rounded shadow-xl border-dutch_white-300 border-2"}>*/}
                {/*                <p className={"font-bold text-3xl select-none text-dutch_white-300"}>{item}</p>*/}
                {/*                <p className={"text-xs mb-2 text-dutch_white-300"}>Click a topic below to answer the question your instructor has assigned for that topic. (Orange outlines are topics you've seen before!)</p>*/}
                {/*                <div id={item} className={"mx-auto grid grid-cols-2 md:grid-cols-4 gap-4 text-sm mb-4"}>*/}
                {/*                    {displayGrid.assessmentItems.map((question) => {*/}

                {/*                            if (studentData.archive[item].map(item => item.knowShowId).includes(question.knowShowId)) {*/}
                {/*                                return (*/}
                {/*                                    <div onClick={() => setQuestionModalContent(question)}*/}
                {/*                                         className={"relative bg-columbia_blue-500 text-black p-4 rounded border-burnt_sienna-400 border-4 max-h-min"}>*/}
                {/*                                        <p className={"select-none"}>{question.knowShowText}</p>*/}
                {/*                                        <div className={'absolute flex w-[80%] -bottom-6 -right-4 p-2 bg-paynes_gray-600 border-dutch_white-200 border-2 border-opacity-70 rounded'}>*/}
                {/*                                            <p className={'text-xs text-dutch_white-300 select-none'}>History:</p>*/}
                {/*                                            {studentData.archive[item].filter(entry => question.knowShowId === entry.knowShowId).map(entry => (entry.result === 'pending')*/}
                {/*                                                ? <div className={'grow mx-1 bg-platinum-800 border-dutch_white-200 border-opacity-50 border-2 rounded'}/> : (entry.result === false) ? <div className={'grow mx-1 bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-2 rounded'} /> : (entry.result === true) ? <div className={'grow mx-1 bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-2 rounded'} /> : (entry.result > 0) ? <div className={'grow mx-1 bg-burnt_sienna-600 border-dutch_white-200 border-opacity-50 border-2 rounded'} /> : <div className={'grow mx-1 bg-columbia_blue-700 border-dutch_white-200 border-opacity-50 border-2 rounded'} /> )}*/}
                {/*                                        </div>*/}
                {/*                                    </div>)*/}
                {/*                            } else {*/}
                {/*                                return (*/}
                {/*                                    <div onClick={() => setQuestionModalContent(question)}*/}
                {/*                                         className={"bg-columbia_blue-500 text-black p-4 rounded border-dutch_white-300 border-4 h-fit"}>*/}
                {/*                                        <p className={"select-none"}>{question.knowShowText}</p>*/}
                {/*                                    </div>)*/}
                {/*                            }*/}

                {/*                            })}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            :<>*/}
                {/*                {(outstandingAssessments[item].length === 0)*/}
                {/*                    ? <div className={"p-4 bg-columbia_blue-700 select-none text-dutch_white-300 flex gap-2 text-black mx-1 rounded shadow border-dutch_white-300 border-2"}>*/}
                {/*                        <div id={item} className={"mx-auto"}>*/}
                {/*                            <p id={item} className={"font-bold text-2xl"}>{item}</p>*/}
                {/*                            <p id={item}>All caught up!</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    : (outstandingAssessments[item].length === 1)*/}
                {/*                        ? <div id={item} onClick={handlePrepClick} className={"p-4 bg-columbia_blue-700 select-none text-dutch_white-300 flex gap-2 text-black mx-1 rounded shadow border-dutch_white-300 border-2"}>*/}
                {/*                            <div id={item} className={"mx-auto"}>*/}
                {/*                                <p id={item} className={"font-bold text-2xl"}>{item}</p>*/}
                {/*                                <p id={item}>{outstandingAssessments[item].length} item to answer</p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        : (outstandingAssessments[item].length > 1)*/}
                {/*                            ? <div id={item} onClick={handlePrepClick} className={"p-4 bg-columbia_blue-700 select-none text-dutch_white-300 flex gap-2 text-black mx-1 rounded shadow border-dutch_white-300 border-2"}>*/}
                {/*                                <div id={item} className={"mx-auto"}>*/}
                {/*                                    <p id={item} className={"font-bold text-2xl"}>{item}</p>*/}
                {/*                                    <p id={item}>{outstandingAssessments[item].length} items to answer</p>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            : <p>How is this possible?</p>}*/}
                {/*            </>*/}
                {/*    ))}*/}
                {/*</div>*/}
                {(user.userContext) && <MyDataSummary user={user} studentData={studentData} standardSets={standardSets} knowShowCharts={knowShowCharts}/>}
                {(questionModalContent) && <QuestionModal id={'question_modal'} user={user} fetchOutstandingAssessments={fetchOutstandingAssessments} fetchStudentData={fetchStudentData} questionModalContent={questionModalContent} setQuestionModalContent={setQuestionModalContent} />}
            </div>
        );
    }
}

export default Home;