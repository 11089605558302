import React, {useEffect, useRef, useState} from 'react';
import FullRandomGroups from "./fullRandomGroups";
import StandardBasedGroups from "./standardBasedGroups";
import SkillBasedGroups from "./skillBasedGroups";
import SeatingChart from "./seatingChart";

function GroupModal({ user, groupData, standardSets, groupModal, setGroupModal, standardElements}) {
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [seatingChart, setSeatingChart] = useState(groupModal.roster.seatingChart);

    const onClose = () => {
        setGroupModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper2" ) onClose();
    }

    let standards = standardSets.find(item => item.prepTitle === groupModal.roster.prep).standards.filter(standard => groupData.filter(item => groupModal.roster.students.includes(item.studentId)).flatMap(entry => entry.active[groupModal.roster.prep]).flatMap(entry => entry.standard).includes(standard.standardCode))
    let studentDataArray = groupData.filter(item => groupModal.roster.students.includes(item.studentId)).map(entry => ({firstName: entry.firstName, lastName: entry.lastName, active: entry.active[groupModal.roster.prep]}))
    const sortedStudents = groupData.filter(item => groupModal.roster.students.includes(item.studentId))
        .map((item) => {

            let correctCount = 0;
            let totalCount = 0;

            if (selectedStandard !== null) {
                const activeDataEntries = item.active[groupModal.roster.prep];

                // Count items with result true or result 0
                const resultCount = activeDataEntries.filter(entry => entry.standard === selectedStandard && (entry.result === true || entry.result === 0)).length;

                // Check for Recall type entries and calculate correct recall items
                const recallEntries = activeDataEntries.filter(entry => entry.standard === selectedStandard && entry.type === 'Recall');
                if (recallEntries.length > 0) {
                    const recallItems = recallEntries.flatMap(entry => entry.recallItems);
                    const correctRecallCount = recallItems.map(entry => entry.active).filter(active => active.correct === true).length;
                    const totalRecallItems = recallItems.map(entry => entry.active).length;
                    if (totalRecallItems > 0) {
                        correctCount = resultCount + (correctRecallCount / totalRecallItems);
                    } else {
                        correctCount = resultCount;
                    }
                } else {
                    correctCount = resultCount;
                }
                totalCount = item
                    ? item.active[groupModal.roster.prep].filter(
                        (item) =>
                            item.standard === selectedStandard
                    ).length
                    : 0;
            } else {
                const activeDataEntries = item.active[groupModal.roster.prep];

                // Count items with result true or result 0
                const resultCount = activeDataEntries.filter(entry => entry.result === true || entry.result === 0).length;

                // Check for Recall type entries and calculate correct recall items
                const recallEntries = activeDataEntries.filter(entry => entry.type === 'Recall');
                if (recallEntries.length > 0) {
                    const recallItems = recallEntries.flatMap(entry => entry.recallItems);
                    const correctRecallCount = recallItems.map(entry => entry.active).filter(active => active.correct === true).length;
                    const totalRecallItems = recallItems.map(entry => entry.active).length;

                    if (totalRecallItems > 0) {
                        correctCount = resultCount + (correctRecallCount / totalRecallItems);
                    } else {
                        correctCount = resultCount;
                    }
                } else {
                    correctCount = resultCount;
                }
                totalCount = item
                    ? item.active[groupModal.roster.prep].length
                    : 0;
            }
            return {
                studentId: item._id.$oid,
                firstName: item.firstName,
                lastName: item.lastName,
                correctCount: correctCount,
                totalCount: totalCount
            };
        })
        .sort((a, b) => b.correctCount - a.correctCount);

    const handleStandardChange = (e) => {
        setSelectedStandard(e.target.value)
    }
    const firstHalf = sortedStudents.slice(0, Math.ceil(sortedStudents.length / 2));
    const secondHalf = sortedStudents.slice(
        Math.ceil(sortedStudents.length / 2)
    );


    const modalRef = useRef(null);

    useEffect(() => {
        // Ensure modalRef.current is not null before attempting to add the listener
        const modalElement = modalRef.current;

        // Focus the first focusable element when the modal opens
        const focusableElements = modalElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();

        const trapFocus = (e) => {
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    // Shift + Tab: Move focus backward
                    if (document.activeElement === firstFocusableElement) {
                        e.preventDefault();
                        lastFocusableElement.focus();
                    }
                } else {
                    // Tab: Move focus forward
                    if (document.activeElement === lastFocusableElement) {
                        e.preventDefault();
                        firstFocusableElement.focus();
                    }
                }
            } else if (e.key === 'Escape') {
                // Close the modal when Escape is pressed
                onClose(e);
            }
        };

        modalElement.addEventListener('keydown', trapFocus);

        return () => {
            if (modalElement) {
                modalElement.removeEventListener('keydown', trapFocus);
            }
        };
    }, [setGroupModal, groupModal]);

    return (
        <div
            className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"}
            id="wrapper2" onClick={handleClose}>
            <div className={"flex flex-col w-[90%] h-[90%]"}>
                <div className={"relative bg-dutch_white-400 rounded p-2 h-full w-full"} aria-modal='true' ref={modalRef} tabIndex={0}>
                    <button onClick={onClose}
                            className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff"
                                  d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div className={"bg-columbia_blue-800 p-4 text-black cursor-black rounded overflow-y-auto h-full"}>
                        <div className={'rounded p-4 mx-auto w-full'}>
                            <div className={'flex gap-6'}>
                                {(seatingChart && groupModal.chartType === "Seating Chart") ?
                                    <div
                                        className={'py-2 px-4 rounded-t bg-columbia_blue-900 text-dutch_white-200 select-none'}>
                                        <p>Seating Chart</p>
                                    </div>
                                    : (seatingChart) ?
                                        <button
                                            className={'py-2 px-4 rounded-t bg-columbia_blue-900 text-dutch_white-200 unselected-tab select-none cursor-pointer hover:bg-columbia_blue-700'}
                                            onClick={() => setGroupModal(prev => {
                                                return {roster: prev.roster, chartType: 'Seating Chart'}
                                                })}>
                                            <p>Seating Chart</p>
                                        </button> :
                                        <div
                                            className={'py-2 px-4 rounded-t bg-platinum-600 text-dutch_white-900 unselected-tab select-none'}>
                                            <p>Seating Chart</p>
                                        </div>
                                }
                                {(groupModal.chartType === "Random") ?
                                    <div className={'py-2 px-4 rounded-t bg-columbia_blue-500 select-none'}>
                                        <p>Full Random</p>
                                    </div>
                                    :
                                    <button
                                        className={'py-2 px-4 rounded-t bg-columbia_blue-500 unselected-tab select-none cursor-pointer hover:bg-columbia_blue-400'}
                                        onClick={() => setGroupModal(prev => {
                                            return {roster: prev.roster, chartType: 'Random'}
                                        })}>
                                        <p>Full Random</p>
                                    </button>}
                                {(groupModal.chartType === "Standard") ?
                                    <div className={'py-2 px-4 rounded-t bg-burnt_sienna-300 select-none'}>
                                        <p>Standard-based</p>
                                    </div>
                                    :
                                    <button
                                        className={'py-2 px-4 rounded-t bg-burnt_sienna-300 select-none unselected-tab cursor-pointer hover:bg-burnt_sienna-200'}
                                        onClick={() => setGroupModal(prev => {
                                            return {roster: prev.roster, chartType: 'Standard'}
                                        })}>
                                        <p>Standard-based</p>
                                    </button>}
                                {/*{(chartType === "Skills") ?*/}
                                {/*    <div className={'py-2 px-4 rounded-t bg-platinum-600 select-none'}>*/}
                                {/*        <p>Skill-based</p>*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div className={'py-2 px-4 rounded-t bg-platinum-600 select-none unselected-tab cursor-pointer hover:bg-platinum-500'} onClick={() => setChartType('Skills')}>*/}
                                {/*        <p>Skill-based</p>*/}
                                {/*    </div>}*/}
                            </div>
                            {(groupModal.chartType === 'Seating Chart') ?
                                <SeatingChart groups={seatingChart} groupModal={groupModal} />
                                : (groupModal.chartType === "Random") ?
                                <FullRandomGroups students={studentDataArray} groupModal={groupModal}
                                                  setGroupModal={setGroupModal} school={user.userAttributes["custom:school"]} setSeatingChart={setSeatingChart}/>
                                : (groupModal.chartType === "Standard") ?
                                    <StandardBasedGroups students={studentDataArray} groupModal={groupModal} setGroupModal={setGroupModal}
                                                         standards={standards} school={user.userAttributes["custom:school"]} setSeatingChart={setSeatingChart}/>
                                    : (groupModal.chartType === "Skills") ?
                                        <SkillBasedGroups/>
                                        : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupModal;