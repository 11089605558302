import React, {useState} from 'react';
import DOMPurify from "dompurify";
import RecallHoverItemTooltip from "./recallHoverItemTooltip";

function MatchingAnswerDistribution({ question, responses, studentData }) {
    const [hoveredItem, setHoveredItem] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: -15, y: 0 });

    const handleMouseMove = (event) => {
        setMousePosition({ x: event.clientX - 250, y: event.clientY - 50});
    };

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    console.log(responses)
    console.log(studentData)

    return (
        <div className={'grid grid-cols-3 gap-2 px-2'}>
            <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Perfect Match</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {responses.filter(questionResponse => questionResponse.result === 0)
                            .map( item => (
                                <div className={'p-1 bg-dutch_white-400 text-burnt_sienna-900 rounded text-sm'}>
                                    <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>
                                </div>))}
                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Mismatched</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {[...new Set(responses.filter(questionResponse => questionResponse.result > 0)
                            .map(item => item.result)
                        )
                    ].sort((a, b) => b - a) // Sort the unique values in ascending order
                        .map(result => (
                            <div key={result} className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded mb-2'}>
                                <p className={'font-bold underline mb-2'}>{result} mismatches</p>
                                {responses.filter(questionResponse => questionResponse.result === result).map(student =>
                                    <div className={'p-1 bg-burnt_sienna-300 text-dutch_white-900 rounded mb-1'}>
                                        <p>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName}</p>
                                    </div>
                                )}
                            </div>
                        ))}

                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Prompt Mismatches:</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {question.matchSets.sort((a, b) => responses.filter(questionResponse => questionResponse.response !== 'pending')
                        .flatMap(studentData => studentData.response)
                        .filter(entry => entry.prompt.content === b.prompt.content && entry.response.content !== b.match.content)
                        .length - responses.filter(questionResponse => questionResponse.response !== 'pending')
                        .flatMap(studentData => studentData.response)
                        .filter(entry => entry.prompt.content === a.prompt.content && entry.response.content !== a.match.content)
                        .length).map(item =>
                        <div onMouseEnter={() => setHoveredItem({item: item, names: studentData.filter(student => (responses.find(item => item.studentId === student.studentId).response !== 'pending' && responses.find(item => item.studentId === student.studentId).response.find(entry => entry.prompt.content === item.prompt.content).response.content !== item.match.content)).map(student => `${student.firstName} ${student.lastName}`)})}
                             onMouseLeave={() => setHoveredItem(null)}
                             onMouseMove={handleMouseMove}
                             className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2'}>
                            {(item.prompt.type === 'text') ?
                                <div className={'my-auto'} dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/> :
                                <img className={'max-h-[100px]'} src={item.prompt.content}/>}
                                <div className={'ml-auto my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>
                                    {responses.filter(questionResponse => questionResponse.response !== 'pending')
                                .flatMap(studentData => studentData.response)
                                .filter(entry => entry.prompt.content === item.prompt.content && entry.response.content !== item.match.content)
                                .length}
                            </div>
                        </div>)}
                    {(hoveredItem) &&
                        <div
                            className="absolute bg-white p-2 shadow rounded opacity-95 z-10"
                            style={{left: mousePosition.x, top: mousePosition.y - 10, // Adjust the y-coordinate to avoid overlapping the mouse
                                transform: 'translateY(-100%)'}}>
                            <RecallHoverItemTooltip data={hoveredItem}/>
                        </div>}
                </div>
            </div>
        </div>
    );
}

export default MatchingAnswerDistribution;