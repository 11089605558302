module.exports = `<!DOCTYPE html>
    <html>
    <head>
    <meta content="text/html; charset=UTF-8"
    http-equiv="content-type">
        <style type="text/css">@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

        .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1
    }

.lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

.lst-kix_list_e-7 > li {
        counter-increment: lst-ctn-kix_list_e-7
    }

    ol.lst-kix_list_d-1.start {
        counter-reset: lst-ctn-kix_list_d-1 0
    }

    ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0
    }

    ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
    }

.lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3
    }

    ol.lst-kix_list_e-4.start {
        counter-reset: lst-ctn-kix_list_e-4 0
    }

.lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

.lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

.lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0
    }

.lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4
    }

    ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
    }

    ol.lst-kix_list_d-6.start {
        counter-reset: lst-ctn-kix_list_d-6 0
    }

.lst-kix_list_d-6 > li {
        counter-increment: lst-ctn-kix_list_d-6
    }

.lst-kix_list_d-6 > li:before {
        content: "" counter(lst-ctn-kix_list_d-6, decimal) ". "
    }

.lst-kix_list_d-8 > li:before {
        content: "" counter(lst-ctn-kix_list_d-8, lower-roman) ". "
    }

.lst-kix_list_d-7 > li:before {
        content: "" counter(lst-ctn-kix_list_d-7, lower-latin) ". "
    }

.lst-kix_list_10-3 > li {
        counter-increment: lst-ctn-kix_list_10-3
    }

.lst-kix_list_d-2 > li:before {
        content: "" counter(lst-ctn-kix_list_d-2, lower-roman) ". "
    }

    ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
    }

.lst-kix_list_d-1 > li:before {
        content: "" counter(lst-ctn-kix_list_d-1, lower-latin) ". "
    }

.lst-kix_list_d-5 > li:before {
        content: "" counter(lst-ctn-kix_list_d-5, lower-roman) ". "
    }

.lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

.lst-kix_list_d-4 > li:before {
        content: "" counter(lst-ctn-kix_list_d-4, lower-latin) ". "
    }

.lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6
    }

.lst-kix_list_d-3 > li:before {
        content: "" counter(lst-ctn-kix_list_d-3, decimal) ". "
    }

    ol.lst-kix_list_d-0.start {
        counter-reset: lst-ctn-kix_list_d-0 0
    }

    ol.lst-kix_list_e-5.start {
        counter-reset: lst-ctn-kix_list_e-5 0
    }

.lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5
    }

.lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    ol.lst-kix_list_d-7.start {
        counter-reset: lst-ctn-kix_list_d-7 0
    }

.lst-kix_list_d-0 > li:before {
        content: "" counter(lst-ctn-kix_list_d-0, decimal) ". "
    }

    ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0
    }

    ol.lst-kix_list_1-3 {
        list-style-type: none
    }

    ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0
    }

    ol.lst-kix_list_1-4 {
        list-style-type: none
    }

.lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
    }

.lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7
    }

    ol.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_1-0 {
        list-style-type: none
    }

.lst-kix_list_2-5 > li:before {
        content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "
    }

    ol.lst-kix_list_1-1 {
        list-style-type: none
    }

.lst-kix_list_d-2 > li {
        counter-increment: lst-ctn-kix_list_d-2
    }

    ol.lst-kix_list_1-2 {
        list-style-type: none
    }

    ol.lst-kix_list_d-8.start {
        counter-reset: lst-ctn-kix_list_d-8 0
    }

    ol.lst-kix_list_e-3.start {
        counter-reset: lst-ctn-kix_list_e-3 0
    }

    ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

.lst-kix_list_d-3 > li {
        counter-increment: lst-ctn-kix_list_d-3
    }

.lst-kix_list_10-1 > li:before {
        content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". "
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    ol.lst-kix_list_1-7 {
        list-style-type: none
    }

.lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    ol.lst-kix_list_1-8 {
        list-style-type: none
    }

.lst-kix_list_10-7 > li:before {
        content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". "
    }

    ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0
    }

.lst-kix_list_10-5 > li:before {
        content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". "
    }

    li.li-bullet-1:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

.lst-kix_list_10-3 > li:before {
        content: "" counter(lst-ctn-kix_list_10-3, decimal) ". "
    }

.lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6
    }

.lst-kix_list_4-1 > li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
    }

.lst-kix_list_d-1 > li {
        counter-increment: lst-ctn-kix_list_d-1
    }

.lst-kix_list_4-3 > li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". "
    }

.lst-kix_list_4-5 > li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". "
    }

.lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8
    }

    ol.lst-kix_list_e-0.start {
        counter-reset: lst-ctn-kix_list_e-0 0
    }

.lst-kix_list_10-5 > li {
        counter-increment: lst-ctn-kix_list_10-5
    }

    ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
    }

.lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
    }

.lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

.lst-kix_list_e-5 > li {
        counter-increment: lst-ctn-kix_list_e-5
    }

    ol.lst-kix_list_10-7 {
        list-style-type: none
    }

    ol.lst-kix_list_10-8 {
        list-style-type: none
    }

    ol.lst-kix_list_10-3 {
        list-style-type: none
    }

    ol.lst-kix_list_10-4 {
        list-style-type: none
    }

    ol.lst-kix_list_10-5 {
        list-style-type: none
    }

    ol.lst-kix_list_10-6 {
        list-style-type: none
    }

    ol.lst-kix_list_10-0 {
        list-style-type: none
    }

    ol.lst-kix_list_10-1 {
        list-style-type: none
    }

    ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
    }

    ol.lst-kix_list_10-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
    }

.lst-kix_list_1-1 > li:before {
        content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". "
    }

    ol.lst-kix_list_e-1.start {
        counter-reset: lst-ctn-kix_list_e-1 0
    }

.lst-kix_list_e-0 > li {
        counter-increment: lst-ctn-kix_list_e-0
    }

    ol.lst-kix_list_e-2.start {
        counter-reset: lst-ctn-kix_list_e-2 0
    }

.lst-kix_list_1-3 > li:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
    }

.lst-kix_list_10-4 > li {
        counter-increment: lst-ctn-kix_list_10-4
    }

    ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0
    }

.lst-kix_list_e-6 > li {
        counter-increment: lst-ctn-kix_list_e-6
    }

.lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8
    }

.lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "
    }

    ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0
    }

.lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3
    }

.lst-kix_list_1-5 > li:before {
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "
    }

.lst-kix_list_2-1 > li:before {
        content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "
    }

.lst-kix_list_2-3 > li:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "
    }

.lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2
    }

.lst-kix_list_d-8 > li {
        counter-increment: lst-ctn-kix_list_d-8
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

.lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    ol.lst-kix_list_d-8 {
        list-style-type: none
    }

.lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1
    }

    ol.lst-kix_list_d-5 {
        list-style-type: none
    }

    ol.lst-kix_list_d-4 {
        list-style-type: none
    }

    ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0
    }

.lst-kix_list_3-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-0, lower-roman) ") "
    }

    ol.lst-kix_list_d-7 {
        list-style-type: none
    }

    ol.lst-kix_list_d-6 {
        list-style-type: none
    }

.lst-kix_list_3-1 > li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". "
    }

.lst-kix_list_3-2 > li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". "
    }

.lst-kix_list_d-7 > li {
        counter-increment: lst-ctn-kix_list_d-7
    }

    ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
    }

.lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0
    }

.lst-kix_list_3-5 > li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". "
    }

.lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0
    }

.lst-kix_list_3-4 > li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". "
    }

.lst-kix_list_3-3 > li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

.lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
    }

    ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0
    }

.lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0
    }

    ol.lst-kix_list_e-7.start {
        counter-reset: lst-ctn-kix_list_e-7 0
    }

.lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
    }

.lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
    }

    ol.lst-kix_list_d-1 {
        list-style-type: none
    }

    ol.lst-kix_list_d-0 {
        list-style-type: none
    }

    ol.lst-kix_list_d-3 {
        list-style-type: none
    }

    ol.lst-kix_list_d-2 {
        list-style-type: none
    }

.lst-kix_list_10-2 > li {
        counter-increment: lst-ctn-kix_list_10-2
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

.lst-kix_list_e-8 > li {
        counter-increment: lst-ctn-kix_list_e-8
    }

.lst-kix_list_e-5 > li:before {
        content: "" counter(lst-ctn-kix_list_e-5, lower-roman) ". "
    }

    ol.lst-kix_list_2-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-4 {
        list-style-type: none
    }

    ol.lst-kix_list_2-5 {
        list-style-type: none
    }

.lst-kix_list_e-3 > li:before {
        content: "" counter(lst-ctn-kix_list_e-3, decimal) ". "
    }

.lst-kix_list_e-7 > li:before {
        content: "" counter(lst-ctn-kix_list_e-7, lower-latin) ". "
    }

    ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0
    }

.lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4
    }

.lst-kix_list_e-2 > li:before {
        content: "" counter(lst-ctn-kix_list_e-2, lower-roman) ". "
    }

.lst-kix_list_e-6 > li:before {
        content: "" counter(lst-ctn-kix_list_e-6, decimal) ". "
    }

    ol.lst-kix_list_2-0 {
        list-style-type: none
    }

    ol.lst-kix_list_2-1 {
        list-style-type: none
    }

.lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
    }

.lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
    }

.lst-kix_list_e-4 > li:before {
        content: "" counter(lst-ctn-kix_list_e-4, lower-latin) ". "
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

.lst-kix_list_e-1 > li:before {
        content: "" counter(lst-ctn-kix_list_e-1, lower-latin) ". "
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0
    }

    ol.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7 {
        list-style-type: none
    }

.lst-kix_list_e-0 > li:before {
        content: "" counter(lst-ctn-kix_list_e-0, upper-latin) ". "
    }

    ol.lst-kix_list_2-8 {
        list-style-type: none
    }

.lst-kix_list_d-0 > li {
        counter-increment: lst-ctn-kix_list_d-0
    }

.lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3
    }

.lst-kix_list_e-1 > li {
        counter-increment: lst-ctn-kix_list_e-1
    }

.lst-kix_list_e-4 > li {
        counter-increment: lst-ctn-kix_list_e-4
    }

    ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0
    }

.lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

.lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
    }

.lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7
    }

.lst-kix_list_2-4 > li:before {
        content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "
    }

.lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
    }

.lst-kix_list_e-2 > li {
        counter-increment: lst-ctn-kix_list_e-2
    }

.lst-kix_list_10-0 > li:before {
        content: "" counter(lst-ctn-kix_list_10-0, upper-latin) ". "
    }

.lst-kix_list_e-3 > li {
        counter-increment: lst-ctn-kix_list_e-3
    }

.lst-kix_list_10-6 > li {
        counter-increment: lst-ctn-kix_list_10-6
    }

    ol.lst-kix_list_e-6.start {
        counter-reset: lst-ctn-kix_list_e-6 0
    }

.lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0
    }

.lst-kix_list_10-4 > li:before {
        content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". "
    }

.lst-kix_list_10-8 > li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". "
    }

.lst-kix_list_4-0 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, upper-latin) ". "
    }

.lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8
    }

.lst-kix_list_10-2 > li:before {
        content: "" counter(lst-ctn-kix_list_10-2, decimal) ". "
    }

.lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6
    }

.lst-kix_list_d-4 > li {
        counter-increment: lst-ctn-kix_list_d-4
    }

    ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
    }

.lst-kix_list_4-4 > li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
    }

    ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0
    }

.lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5
    }

.lst-kix_list_4-2 > li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". "
    }

.lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
    }

    ol.lst-kix_list_d-5.start {
        counter-reset: lst-ctn-kix_list_d-5 0
    }

    ol.lst-kix_list_d-2.start {
        counter-reset: lst-ctn-kix_list_d-2 0
    }

.lst-kix_list_10-8 > li {
        counter-increment: lst-ctn-kix_list_10-8
    }

.lst-kix_list_e-8 > li:before {
        content: "" counter(lst-ctn-kix_list_e-8, lower-roman) ". "
    }

.lst-kix_list_10-6 > li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". "
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

.lst-kix_list_d-5 > li {
        counter-increment: lst-ctn-kix_list_d-5
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

.lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_d-3.start {
        counter-reset: lst-ctn-kix_list_d-3 0
    }

    ol.lst-kix_list_e-8 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    ol.lst-kix_list_e-7 {
        list-style-type: none
    }

    ol.lst-kix_list_e-4 {
        list-style-type: none
    }

    ol.lst-kix_list_e-3 {
        list-style-type: none
    }

    ol.lst-kix_list_e-6 {
        list-style-type: none
    }

    ol.lst-kix_list_e-8.start {
        counter-reset: lst-ctn-kix_list_e-8 0
    }

    ol.lst-kix_list_e-5 {
        list-style-type: none
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

.lst-kix_list_1-0 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

.lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "
    }

    ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

.lst-kix_list_1-4 > li:before {
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

.lst-kix_list_10-1 > li {
        counter-increment: lst-ctn-kix_list_10-1
    }

.lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0
    }

.lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6
    }

.lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    ol.lst-kix_list_d-4.start {
        counter-reset: lst-ctn-kix_list_d-4 0
    }

.lst-kix_list_10-7 > li {
        counter-increment: lst-ctn-kix_list_10-7
    }

    ol.lst-kix_list_e-0 {
        list-style-type: none
    }

    ol.lst-kix_list_e-2 {
        list-style-type: none
    }

    ol.lst-kix_list_e-1 {
        list-style-type: none
    }

.lst-kix_list_2-0 > li:before {
        content: "" counter(lst-ctn-kix_list_2-0, upper-latin) ". "
    }

    ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0
    }

.lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5
    }

.lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "
    }

.lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

.c26 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c22 {
        -webkit-text-decoration-skip: none;
        color: #0563c1;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c8 {
        margin-left: 54pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c5 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt
    }

.c2 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

.c7 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c14 {
        margin-left: 36pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c15 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c9 {
        margin-left: 90pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c0 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c4 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c3 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center
    }

.c34 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal
    }

.c35 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal
    }

.c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal
    }

.c6 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

.c10 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c29 {
        padding-top: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c17 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c18 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c23 {
        padding-top: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c16 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: center
    }

.c31 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: left
    }

.c28 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

.c25 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left
    }

.c24 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt
    }

.c30 {
        margin-left: 54pt;
        orphans: 2;
        widows: 2
    }

.c13 {
        padding: 0;
        margin: 0
    }

.c19 {
        color: inherit;
        text-decoration: inherit
    }

.c20 {
        margin-left: 72pt;
        padding-left: 18pt
    }

.c32 {
        border: 1px solid black;
        margin: 5px
    }

.c33 {
        orphans: 2;
        widows: 2
    }

.c12 {
        margin-left: 18pt
    }

.c21 {
        margin-left: 36pt
    }

.c36 {
        margin-left: 58.5pt
    }

.c11 {
        height: 11pt
    }

.c27 {
        padding-left: 0pt
    }

.title {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 16pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: center
    }

.subtitle {
        padding-top: 0pt;
        color: #000000;
        font-size: 11pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 6pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 6pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 3pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: left
    }</style>
</head>
    <body class="c24 doc-content">
        <p class="c11 c31"><span class="c1"></span></p>
        <p class="c3"><span class="c4">Privacy Policy</span></p>
        <p class="c3 c11"><span class="c4"></span></p>
        <p class="c17 c12"><span class="c1">This Privacy Policy (&ldquo;Privacy Policy&rdquo;) applies to the Brainmeter.org online platform (&ldquo;Platform&rdquo;), owned and operated by Brainmeter LLC (&ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;Brainmeter&rdquo;). We have created this Privacy Policy to tell you what information the Platform collects, how we use that information, and who we will share that information with, if at all. This Privacy Policy does not address the privacy practices of any third parties that we do not own, control, or are affiliated with. Capitalized terms not defined in this Privacy Policy will have the meaning set forth in our Terms and Conditions of Use and End User License Agreement (&ldquo;Terms&rdquo;). By visiting and/or using the Platform, you are agreeing to the terms of this Privacy Policy and the accompanying Terms. We encourage you to read the Privacy Policy, and to use the information it contains to help you make informed decisions.</span>
        </p>
        <p class="c6 c12"><span class="c1"></span></p>
        <ol class="c13 lst-kix_list_d-0 start" start="1">
            <li class="c7 li-bullet-0"><span class="c4">Information we Collect or Receive.</span></li>
        </ol>
        <p class="c6 c21"><span class="c4"></span></p>
        <p class="c0"><span class="c1">In the course of operating the Platform, we will collect and receive the following types of information (collectively, &ldquo;Collected Information&rdquo;). You authorize us to collect and receive such information.</span>
        </p>
        <p class="c0 c11"><span class="c1"></span></p>
        <ol class="c13 lst-kix_list_e-0 start" start="1">
            <li class="c10 c27 li-bullet-0"><span class="c26">Personally Identifiable Information.</span><span class="c1">&nbsp;We only receive or collect information that identifies you personally if you choose to provide such personally identifiable information (&ldquo;PII&rdquo;) to us via email or other means. When you sign up to become a user or contact us, your school will &nbsp;provide us with personal information about yourself, such as your full name, e-mail address, </span><span
                class="c1">class schedule information, grade levels, teachers instructional data, school name, and/or other similar informatio</span><span
                class="c1">n</span><span class="c1">. We do not collect any PII from you when you use the Platform unless you provide us with the PII voluntarily.</span>
            </li>
            <li class="c10 c27 li-bullet-0"><span class="c26">Other Information.</span><span class="c1">&nbsp;In addition to the PII, Payment Information, we may automatically collect or receive additional information regarding you and your use of the Platform; your interactions with us, (collectively, &ldquo;Other Information&rdquo;). Such Other Information may include additional information about yourself that you voluntarily provide to us, such as your gender; your IP address; browser type and language; date and time; details regarding your activity on the app; usage data; user inputted data, advertising identifiers; operating systems and versions; network type; and cookies. </span>
            </li>
        </ol>
        <p class="c6"><span class="c1"></span></p>
        <p class="c12 c11 c18"><span class="c1"></span></p>
        <ol class="c13 lst-kix_list_d-0" start="2">
            <li class="c14 li-bullet-0"><span class="c4">How Information is Used and Shared.</span></li>
        </ol>
        <ol class="c13 lst-kix_list_10-0 start" start="1">
            <li class="c8 li-bullet-0"><span class="c1">You authorize us to use the Collected Information to:</span></li>
        </ol>
        <ol class="c13 lst-kix_list_10-1 start" start="1">
            <li class="c9 li-bullet-0"><span class="c1">Provide and improve the Services to you and others;</span></li>
            <li class="c9 li-bullet-0"><span class="c1">Provide and improve the Platform to you and others;</span></li>
            <li class="c9 li-bullet-0"><span class="c1">Administer our related promotional programs;</span></li>
            <li class="c9 li-bullet-0"><span class="c1">Solicit your feedback;</span></li>
            <li class="c9 li-bullet-0"><span class="c1">Provide you notifications related to the Services and the app; </span>
            </li>
            <li class="c9 li-bullet-0"><span class="c1">Provide information to teacher, students coordinators, and school administrators ; and </span>
            </li>
            <li class="c9 li-bullet-0"><span class="c1">Inform you about our products and services.</span></li>
        </ol>
        <ol class="c13 lst-kix_list_10-0" start="2">
            <li class="c8 li-bullet-0"><span class="c1">In order to provide our services and administer our promotional programs, we may share the Collected Information with Third-Party Partners, marketing partners, and coordinator users.</span>
            </li>
            <li class="c8 li-bullet-0"><span class="c1">We engage Third-Party Partners and individuals to perform functions on our behalf. Examples may include providing technical assistance, customer service, marketing assistance, and administration of promotional programs. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law. </span>
            </li>
            <li class="c8 li-bullet-0"><span class="c1">In an effort to provide the Services, improve the Services, create new Services, and to better understand our users and the Platform, we may analyze certain Collected Information in anonymized and aggregate form to operate, maintain, manage, and improve the Platform and such products and services (&ldquo;Aggregate Information&rdquo; or &ldquo;Anonymized Information&rdquo;). The Anonymized Information does not identify you personally and is no longer PII. We may share or license the Aggregate Information to our affiliates, agents, business partners, promotional partners, and other third parties. We may disclose aggregated user statistics to describe the Platform and these products and services to third parties for lawful purposes.</span>
            </li>
            <li class="c8 li-bullet-0"><span class="c1">As we develop Brainmeter, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, the Collected Information may be part of the transferred assets.</span>
            </li>
            <li class="c8 li-bullet-0"><span class="c1">To the extent permitted by law, we may also disclose the Collected Information:</span>
            </li>
        </ol>
        <ol class="c13 lst-kix_list_10-1 start" start="1">
            <li class="c9 li-bullet-0"><span class="c1">When required by law, court order, or other government or law enforcement authority or regulatory agency; or</span>
            </li>
            <li class="c9 li-bullet-0"><span class="c1">Whenever we believe that disclosing such Collected Information is necessary or advisable, for example, to protect the rights, property, or safety of us or others, including you.</span>
            </li>
            <li class="c9 li-bullet-0"><span class="c1">As required by FERPA.</span></li>
        </ol>
        <ol class="c13 lst-kix_list_d-0" start="3">
            <li class="c14 li-bullet-0"><span
                class="c4">Accessing and Modifying Information and Communication Preferences.</span></li>
        </ol>
        <p class="c18 c12 c11"><span class="c4"></span></p>
        <p class="c28 c21"><span class="c1">If you have provided us PII, you may access, remove, review, or make changes to the same by contacting or by adjusting the information in the App. You may manage your receipt of marketing and non-transactional communications by clicking on the &ldquo;unsubscribe&rdquo; link located on the bottom of any marketing emails. We use commercially reasonable efforts to process such requests in a timely manner. You should be aware that it is not always possible to completely remove or modify information in our subscription databases and you cannot remove Anonymized Information. You cannot opt out of receiving transactional emails related to the App, such as those required for technical support.</span>
        </p>
        <p class="c21 c11 c28"><span class="c1"></span></p>
        <p class="c28 c21"><span class="c1">We may deliver notifications to your mobile device. You can disable these notifications by changing the settings on your mobile device.</span>
        </p>
        <p class="c18 c12 c11"><span class="c1"></span></p>
        <ol class="c13 lst-kix_list_d-0" start="4">
            <li class="c14 li-bullet-0"><span
                class="c4">How we Protext Your Information.</span></li>
        </ol>
        <p class="c23 c21"><span class="c1">Information is stored in a MongoDB &nbsp;Database which is accessed through Amazon Webservices. Their data protection terms are located at https://www.mongodb.com/legal/privacy. We take commercially reasonable steps to protect the Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases or the databases of the third parties with which we may share such Information, nor can we guarantee that the Information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</span>
        </p>
        <ol class="c13 lst-kix_list_d-0" start="5">
            <li class="c14 li-bullet-0"><span
                class="c4">Changes to the Privacy Policy.</span></li>
        </ol>
        <p class="c21 c23"><span class="c1">This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time. Any such changes will be posted on the App. By accessing the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.</span>
        </p>
        <p class="c18 c12 c11"><span class="c1"></span></p>
        <p class="c6 c12"><span class="c1"></span></p>
        <p class="c6"><span class="c1"></span></p>
        <div><p class="c25 c33"><span class="c35">Last Updated June 2024</span></p></div>
    </body>
</html>`