import React from 'react';
import DOMPurify from "dompurify";

function SortingHoverItemTooltip({data}) {

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={"bg-columbia_blue-500 p-1 rounded text-xs"}>
            <div className={'bg-dutch_white-300 max-w-[400px] p-1 rounded'}>
                {(data.item.type === 'text') ? <div className={'text-dutch_white-900 text-xs'} dangerouslySetInnerHTML={renderHtml(data.item.content)} /> : <img className={'h-[100px]'} src={data.item.content} />}
            </div>
            <div className={'grid grid-cols-3 gap-2 w-full overflow-x-auto mt-1'}>
                {data.names.map(item =>
                    <div className={'p-1 bg-burnt_sienna-300 rounded'}>
                        <p>{item}</p>
                    </div>)}
            </div>

        </div>
    );
}

export default SortingHoverItemTooltip;