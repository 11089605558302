import React, {useState} from 'react';
import postWithAuth from "../../context/postWithAuth";
import ViewReteachItem from "./viewReteachItem";

function ReteachModal({roster, reteachModal, prep, setReteachModal, user, studentIds, setStudentData}) {
    const [isLoading, setIsLoading] = useState(null);

    const onClose = () => {
        setReteachModal(null)
    }
    const handleAssignClick = (assignment) => {
        if (window.confirm('Send currently selected question to selected rosters? Assignments will be immediately available and cannot be taken back from the database.')) {
            let body = {
                question: assignment,
                questionId: (typeof assignment._id) === 'string' ? assignment._id : assignment._id.$oid,
                students: roster.students,
                rosterIds: [roster._id.$oid],
                prep: prep,
                allStudentIds: studentIds
            }
            setIsLoading(true);
            postWithAuth('/planning/postAssignment', user.userAttributes['custom:school'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body})
                .then(async (response) => {
                    if (response.ok) {
                        return await response.json()
                    } else {
                        console.log(response.error)
                        setIsLoading(false);
                    }
                })
                .then(async (data) => {
                    await setStudentData(data)
                    window.alert('Assignment successfully posted!')
                    setReteachModal(null)
                })
        } else {
            return null;
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper_reteach" ) onClose();
    }

    return (<>
                <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper_reteach" onClick={handleClose}>
                    <div className={"relative bg-platinum-500 p-2 m-4 rounded max-h-[80%]"}>
                        <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                            </svg>
                        </button>
                        <div className={'p-2 bg-columbia_blue-800 flex flex-col h-full overflow-auto'}>
                            <div className={'grid grid-cols-2 gap-2'}>
                                {reteachModal.assessmentItems.map(item => <ViewReteachItem key={item._id.$oid} user={user} setReteachModal={setReteachModal} reteachModal={reteachModal} assignReteachItem={handleAssignClick} assessmentItem={item}/>)}
                            </div>
                        </div>
                    </div>
                </div>

                    {isLoading && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10">
                            {/* Loader */}
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                        </div>
                    )}
        </>
    );
}

export default ReteachModal;