import React, {useState} from 'react';
import EditStandardSetModal from "./editStandardSetModal";

function ReviewAndEditStandardSetsModal({setReviewAndEditStandardSetsModal, standardSets}) {

    const [editStandardSetModal, setEditStandardSetModal] = useState(false);

    const onClose = () => {
        setReviewAndEditStandardSetsModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper3" ) onClose();
    }
    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper3" onClick={handleClose}>
            <div className={"relative bg-dutch_white-200 m-4 rounded p-2 max-h-[92%] min-w-[70%]"}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-300 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={"relative text-black mb-2 cursor-black max-h-[100%] overflow-y-auto grid grid-cols-2 gap-2"}>
                    {standardSets.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())).map(item =>
                        <div className={'bg-columbia_blue-700 p-2 w-full rounded shadow flex'}>
                            <div className={'grow'}/>
                            <p className={'text-dutch_white-400'}>{item.prepTitle}</p>
                            <div className={'grow'}/>
                            <div onClick={() => setEditStandardSetModal(item)} className={'text-xs text-dutch_white-400 my-auto p-1 bg-burnt_sienna rounded select-none hover:bg-burnt_sienna-600 cursor-pointer'}>
                                <p>Edit</p>
                            </div>
                        </div>)}
                </div>
            </div>
            {(editStandardSetModal) && <EditStandardSetModal setReviewAndEditStandardSetsModal={setReviewAndEditStandardSetsModal} standardSet={editStandardSetModal}/>}
        </div>
    );
}

export default ReviewAndEditStandardSetsModal;