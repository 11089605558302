import {fetchAuthSession} from 'aws-amplify/auth';
import pako from 'pako';

const postWithAuth = async (endpoint, schoolId, options = {}) => {
    try {
        const session = await fetchAuthSession();
        const url = new URL(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}${endpoint}`);
        url.searchParams.append('schoolId', schoolId);

        // Add Authorization header to options
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${session.tokens.idToken}`,
            'Content-Encoding': 'gzip', // Inform the server that the content is gzipped
            'Content-Type': 'application/json' // Ensure content type is set
        };

        // Compress the body if it exists
        if (options.body) {
            options.body = pako.gzip(JSON.stringify(options.body));
        }

        const response = await fetch(url.toString(), options);

        // Handle uncompressed response
        return response
    } catch (error) {
        console.error('Error during putWithAuth:', error);
        return null;
    }
};

export default postWithAuth;